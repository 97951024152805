import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
// import Datepicker from "react-datepicker";

// import Modal from "react-responsive-modal";
import {
  Button,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import Datetime from "react-datetime";

// Toggle Button
import Switch from "react-switch";

// koneksi
import {
  Get,
  ID_DESA,
  GetMarketplace,
  PostMarketplace
} from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import DatePicker from "reactdatepicker";
// const id_keldes = ID_DESA;

//json file
// var data = require('../assets/json/company');

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",

      data_layanan: null,
			list_layanan_desa_web: [],
			list_layanan_kiosk: [],
			list_cetak_layanan_kiosk: [],
			konfig_umum: "none",
			// Loading
			loading: true,
      data_cek: '',
    };
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeCetak = this.handleChangeCetak.bind(this);
  }

  // ================== Button Toggle ==================
  
  handleChange(checked, jenis_surat) {
		//console.log("CHANGE JENIS SURAT")
		//console.log(jenis_surat)
		switch (jenis_surat) {
			// LAYANAN DESA KiosK
			case "tidak_mampu":
				if(this.state.list_layanan_kiosk.tidak_mampu === true){
					this.state.list_layanan_kiosk.tidak_mampu = checked
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
				else{
					this.state.list_layanan_kiosk.tidak_mampu = checked
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
			  break;
			case "domisili":
				if(this.state.list_layanan_kiosk.domisili === true){
					this.state.list_layanan_kiosk.domisili = checked
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
				else{
					this.state.list_layanan_kiosk.domisili = checked
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
			  break;
			case "kelahiran":
				if(this.state.list_layanan_kiosk.kelahiran === true){
					this.state.list_layanan_kiosk.kelahiran = checked
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
				else{
					this.state.list_layanan_kiosk.kelahiran = checked
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
			  break;
			case "belum_memiliki_rumah":
				if(this.state.list_layanan_kiosk.belum_memiliki_rumah === true){
					this.state.list_layanan_kiosk.belum_memiliki_rumah = checked
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
				else{
					this.state.list_layanan_kiosk.belum_memiliki_rumah = checked
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
			  break;
			case "jalan":
				if(this.state.list_layanan_kiosk.jalan === true){
					this.state.list_layanan_kiosk.jalan = checked
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
				else{
					this.state.list_layanan_kiosk.jalan = checked
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
			  break;
			case "kematian":
				if(this.state.list_layanan_kiosk.kematian === true){
					this.state.list_layanan_kiosk.kematian = checked
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
				else{
					this.state.list_layanan_kiosk.kematian = checked
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
			  break;
			case "kepemilikan_rumah":
				if(this.state.list_layanan_kiosk.kepemilikan_rumah === true){
					this.state.list_layanan_kiosk.kepemilikan_rumah = checked
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
				else{
					this.state.list_layanan_kiosk.kepemilikan_rumah = checked
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
			break;
			case "penghasilan":
				if(this.state.list_layanan_kiosk.penghasilan === true){
					this.state.list_layanan_kiosk.penghasilan = checked
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
				else{
					this.state.list_layanan_kiosk.penghasilan = checked
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
			break;
			case "skck":
				if(this.state.list_layanan_kiosk.skck === true){
					this.state.list_layanan_kiosk.skck = checked
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
				else{
					this.state.list_layanan_kiosk.skck = checked
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
			break;
			case "kehilangan":
				if(this.state.list_layanan_kiosk.kehilangan === true){
					this.state.list_layanan_kiosk.kehilangan = checked
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
				else{
					this.state.list_layanan_kiosk.kehilangan = checked
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
			break;
			case "usaha":
				if(this.state.list_layanan_kiosk.usaha === true){
					this.state.list_layanan_kiosk.usaha = checked
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
				else{
					this.state.list_layanan_kiosk.usaha = checked
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
			break;
			case "skgr":
				if(this.state.list_layanan_kiosk.skgr === true){
					this.state.list_layanan_kiosk.skgr = checked
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
				else{
					this.state.list_layanan_kiosk.skgr = checked
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
			break;
			case "belum_menikah":
				if(this.state.list_layanan_kiosk.belum_menikah === true){
					this.state.list_layanan_kiosk.belum_menikah = checked
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.belum_menikah = checked
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
			break;
			case "tidak_menikah":
				if(this.state.list_layanan_kiosk.tidak_menikah === true){
					this.state.list_layanan_kiosk.tidak_menikah = checked
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.tidak_menikah = checked
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
			break;
			case "pindah_menikah":
				if(this.state.list_layanan_kiosk.pindah_menikah === true){
					this.state.list_layanan_kiosk.pindah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.pindah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
			break;
			case "pernah_menikah":
				if(this.state.list_layanan_kiosk.pernah_menikah === true){
					this.state.list_layanan_kiosk.pernah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
				else{
					this.state.list_layanan_kiosk.pernah_menikah = checked
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
			break;
			case "keramaian":
				if(this.state.list_layanan_kiosk.keramaian === true){
					this.state.list_layanan_kiosk.keramaian = checked
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
				else{
					this.state.list_layanan_kiosk.keramaian = checked
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
			break;
			case "ahli_waris":
				if(this.state.list_layanan_kiosk.ahli_waris === true){
					this.state.list_layanan_kiosk.ahli_waris = checked
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
				else{
					this.state.list_layanan_kiosk.ahli_waris = checked
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
			break;
			default:
				// no default
				break;

		  }

		this.forceUpdate();
		this.setState({ checked });
		// this.simpanLayanan(checked);
	  }
  
  handleChangeCetak(checked, jenis_surat) {
		//console.log("CHANGE JENIS SURAT")
		//console.log(jenis_surat)
		switch (jenis_surat) {
			// LAYANAN DESA KiosK
			case "tidak_mampu":
				if(this.state.list_cetak_layanan_kiosk.tidak_mampu === true){
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.tidak_mampu = checked
				}
			  break;
			case "domisili":
				if(this.state.list_cetak_layanan_kiosk.domisili === true){
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.domisili = checked
				}
			  break;
			case "kelahiran":
				if(this.state.list_cetak_layanan_kiosk.kelahiran === true){
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kelahiran = checked
				}
			  break;
			case "belum_memiliki_rumah":
				if(this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah === true){
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah = checked
				}
			  break;
			case "jalan":
				if(this.state.list_cetak_layanan_kiosk.jalan === true){
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.jalan = checked
				}
			  break;
			case "kematian":
				if(this.state.list_cetak_layanan_kiosk.kematian === true){
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kematian = checked
				}
			  break;
			case "kepemilikan_rumah":
				if(this.state.list_cetak_layanan_kiosk.kepemilikan_rumah === true){
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kepemilikan_rumah = checked
				}
			break;
			case "penghasilan":
				if(this.state.list_cetak_layanan_kiosk.penghasilan === true){
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.penghasilan = checked
				}
			break;
			case "skck":
				if(this.state.list_cetak_layanan_kiosk.skck === true){
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.skck = checked
				}
			break;
			case "kehilangan":
				if(this.state.list_cetak_layanan_kiosk.kehilangan === true){
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.kehilangan = checked
				}
			break;
			case "usaha":
				if(this.state.list_cetak_layanan_kiosk.usaha === true){
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.usaha = checked
				}
			break;
			case "skgr":
				if(this.state.list_cetak_layanan_kiosk.skgr === true){
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.skgr = checked
				}
			break;
			case "belum_menikah":
				if(this.state.list_cetak_layanan_kiosk.belum_menikah === true){
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.belum_menikah = checked
				}
			break;
			case "tidak_menikah":
				if(this.state.list_cetak_layanan_kiosk.tidak_menikah === true){
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.tidak_menikah = checked
				}
			break;
			case "pindah_menikah":
				if(this.state.list_cetak_layanan_kiosk.pindah_menikah === true){
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.pindah_menikah = checked
				}
			break;
			case "pernah_menikah":
				if(this.state.list_cetak_layanan_kiosk.pernah_menikah === true){
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.pernah_menikah = checked
				}
			break;
			case "keramaian":
				if(this.state.list_cetak_layanan_kiosk.keramaian === true){
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.keramaian = checked
				}
			break;
			case "ahli_waris":
				if(this.state.list_cetak_layanan_kiosk.ahli_waris === true){
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
				else{
					this.state.list_cetak_layanan_kiosk.ahli_waris = checked
				}
			break;
			default:
				// no default
				break;

		  }

		this.forceUpdate();
		this.setState({ checked });
		// this.simpanLayanan(checked);
	  }


  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("perangkat" + link, null, dtkat => {
      this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  // ========================================================================

 
  componentDidMount() {
    // let e = {
    //   value: ID_DESA
    // };

    this.fetch({ page: null, where: [] });
    

    // DATA LAYANAN DESA
    GetMarketplace('list-layanan-desa/' + ID_DESA, null, (data) => {
      //console.log("Data Layanan Desa abc")
      //console.log(data.result)
      let data_layanan = []
      let layananDesaWebnya = []
      let layananKiosk = []
      let layananCetakKiosk = []
      let data_cek = ""

      if(data.result){
        data_layanan = data.result
        layananDesaWebnya = JSON.parse(data.result.list_layanan_desa_web)
        if(layananDesaWebnya.layanan_kiosk){
          layananKiosk = layananDesaWebnya.layanan_kiosk
          data_cek = "Tidak Kosong"

          if(layananDesaWebnya.cetak_layanan_kiosk){
            layananCetakKiosk = layananDesaWebnya.cetak_layanan_kiosk
          }
          else{
            layananCetakKiosk = {
              tidak_mampu: layananDesaWebnya.layanan_kiosk.tidak_mampu === true? true: false,
              domisili: layananDesaWebnya.layanan_kiosk.domisili === true? true: false,
              kelahiran: layananDesaWebnya.layanan_kiosk.kelahiran === true? true: false,
              belum_memiliki_rumah: layananDesaWebnya.layanan_kiosk.belum_memiliki_rumah === true? true: false,
              jalan: layananDesaWebnya.layanan_kiosk.jalan === true? true: false,
              kematian: layananDesaWebnya.layanan_kiosk.kematian === true? true: false,
              kepemilikan_rumah: layananDesaWebnya.layanan_kiosk.kepemilikan_rumah === true? true: false,
              penghasilan: layananDesaWebnya.layanan_kiosk.penghasilan === true? true: false,
              skck: layananDesaWebnya.layanan_kiosk.skck === true? true: false,
              kehilangan: layananDesaWebnya.layanan_kiosk.kehilangan === true? true: false,
              usaha: layananDesaWebnya.layanan_kiosk.usaha === true? true: false,
              skgr: layananDesaWebnya.layanan_kiosk.skgr === true? true: false,
              belum_menikah: layananDesaWebnya.layanan_kiosk.belum_menikah === true? true: false,
              tidak_menikah: layananDesaWebnya.layanan_kiosk.tidak_menikah === true? true: false,
              pindah_menikah: layananDesaWebnya.layanan_kiosk.pindah_menikah === true? true: false,
              pernah_menikah: layananDesaWebnya.layanan_kiosk.pernah_menikah === true? true: false,
              keramaian: layananDesaWebnya.layanan_kiosk.keramaian === true? true: false,
              ahli_waris: layananDesaWebnya.layanan_kiosk.ahli_waris === true? true: false,
            }
          }
        }
        else{
          data_cek = "Kosong"
          layananKiosk = {
						tidak_mampu: true,
						domisili: true,
						kelahiran: true,
						belum_memiliki_rumah: true,
						jalan: true,
						kematian: true,
						kepemilikan_rumah: true,
						penghasilan: true,
						skck: true,
						kehilangan: true,
						usaha: true,
						skgr: true,
						belum_menikah: true,
						tidak_menikah: true,
						pindah_menikah: true,
						pernah_menikah: true,
						keramaian: true,
						ahli_waris: true,
					}
          layananCetakKiosk = {
						tidak_mampu: true,
						domisili: true,
						kelahiran: true,
						belum_memiliki_rumah: true,
						jalan: true,
						kematian: true,
						kepemilikan_rumah: true,
						penghasilan: true,
						skck: true,
						kehilangan: true,
						usaha: true,
						skgr: true,
						belum_menikah: true,
						tidak_menikah: true,
						pindah_menikah: true,
						pernah_menikah: true,
						keramaian: true,
						ahli_waris: true,
					}
        }
      }

      this.setState({
        data_layanan: data_layanan,
        list_layanan_desa_web: layananDesaWebnya,
        list_layanan_kiosk: layananKiosk,
        list_cetak_layanan_kiosk: layananCetakKiosk,
        loading: false,
				data_cek: data_cek,
      });

      this.forceUpdate();
    });
  }

  
    // ======================= SIMPAN Layanan =====================
	simpanLayanan = e => {
		// e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		let cekLayanan = this.state.data_layanan
		let layanan_web = this.state.list_layanan_desa_web
		let layanan_kiosk = this.state.list_layanan_kiosk
		let cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk
    
    layanan_web.layanan_kiosk = layanan_kiosk
    layanan_web.cetak_layanan_kiosk = cetak_layanan_kiosk
    cekLayanan.list_layanan_desa_web = layanan_web

    let psn = "Ubah";
    let resstat = 200;
    let metode = "update";

		PostMarketplace('list-layanan-desa/' + metode, cekLayanan.uuid, cekLayanan, (res) => {
			//console.log("Berhasil Simpan Bosku")
			//console.log(res)
			this.forceUpdate();
			if (res.status === resstat) {
				if(this.state.konfig_umum === "inherit"){
					this.setState({ show: true, basicType: 'success', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
					this.state.konfig_umum = "none"
				}
				if(this.state.konfig_web === "inherit"){
					this.setState({ show: true, basicType: 'success', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
					this.state.konfig_web = "none"
				}
			}
			else {
				this.setState({ show: true, basicType: 'danger', basicTitle: 'Konfigurasi Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
			}
			this.componentDidMount();
		});
	}

	// ======================= END SIMPAN Layanan =====================

  
	// ============================== Ubah Konfig ===============================

	ubahKonfig = (e) => {
		e.preventDefault();
		this.setState({ konfig_umum: 'inherit'});
	};
	// ========================================================================


  render() {
    //console.log("Hasil Render")
    //console.log(this.state.data_layanan)
    //console.log(this.state.list_layanan_desa_web)
    //console.log(this.state.list_layanan_kiosk)
    //console.log(this.state.list_cetak_layanan_kiosk)
    //console.log(this.state.data_cek)
    //console.log(this.state.konfig_umum)
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    // var data = this.state.data;

    // const frmDef = {
    //   uuid: "",
    //   id_jabatan_perangkat: "",
    //   nik: 0,
    //   nip: "",
    //   status_pegawai: "",
    //   tanggal_mulai: null,
    //   tanggal_selesai: null,
    //   status: 0
    // };
    // const { SearchBar } = Search;
    // const columns = [
    //   // {
    //   //     dataField: 'uuid',
    //   //     text: 'ID UUD',
    //   //     sort: false
    //   // },

    //   {
    //     dataField: "nip",
    //     text: "NIP",
    //     sort: true
    //   },
    //   // {
    //   //   dataField: "nik",
    //   //   text: "NIK",
    //   //   sort: true,
    //   // },
    //   {
    //     dataField: "nama",
    //     text: "Nama",
    //     formatter: (cell, obj) => {
    //       if (obj.data_penduduk !== null) {
    //         let nama = obj.data_penduduk.nama;
    //         return nama;
    //       } else {
    //         return ''
    //       }
    //     },
    //     sort: true,
    //     isDummyField: true
    //   },
    //   {
    //     dataField: "data_jabatan",
    //     text: "Jabatan",
    //     formatter: (cell, obj) => {
    //       let jabatan = obj.data_jabatan.nama;
    //       let lokasiJabatan =
    //         obj.data_rt !== null
    //           ? obj.data_rt.nama
    //           : obj.data_rw !== null
    //             ? obj.data_rw.nama
    //             : obj.data_dusun !== null
    //               ? obj.data_dusun.nama
    //               : obj.data_keldes !== null
    //                 ? obj.data_keldes.nama
    //                 : "";

    //       return jabatan + " " + lokasiJabatan;
    //     },
    //     sort: true,
    //     isDummyField: true
    //   },
    //   {
    //     dataField: "status_pegawai",
    //     text: "Status Perangkat",

    //     sort: true
    //   },
    //   {
    //     dataField: "masa_bakti",
    //     text: "Masa Bakti",
    //     formatter: (cell, obj) => {
    //       let tgl = new Date(obj.tanggal_mulai).toLocaleDateString("id-ID", {
    //         year: "numeric",
    //         month: "long",
    //         day: "numeric"
    //       });
    //       let tglSelesai =
    //         obj.tanggal_selesai !== null
    //           ? new Date(obj.tanggal_selesai).toLocaleDateString("id-ID", {
    //             year: "numeric",
    //             month: "long",
    //             day: "numeric"
    //           })
    //           : "sekarang";

    //       return tgl + " s/d " + tglSelesai;
    //     },
    //     sort: true,
    //     isDummyField: true
    //   },

    //   {
    //     dataField: "status",
    //     text: "Status",
    //     formatter: (cell, obj) => {
    //       let status = obj.status === 0 ? "Aktif" : "Tidak Aktif";

    //       return status;
    //     },
    //     sort: true
    //   },
    //   // {
    //   //   dataField: "wilayah",
    //   //   text: "Wilayah",
    //   //   formatter: (cell, obj) => {
    //   //     let daerah =
    //   //       obj.data_jabatan.data_identitas_perangkat.nama +
    //   //       ", " +
    //   //       obj.data_jabatan.data_identitas_perangkat.data_kecamatan.nama +
    //   //       ", " +
    //   //       obj.data_jabatan.data_identitas_perangkat.data_kecamatan
    //   //         .data_kabkota.nama +
    //   //       ", " +
    //   //       obj.data_jabatan.data_identitas_perangkat.data_kecamatan
    //   //         .data_kabkota.data_provinsi.nama;
    //   //     return daerah;
    //   //   },
    //   //   sort: true,
    //   // },
    //   {
    //     dataField: "aksi",
    //     text: "Aksi",
    //     isDummyField: true,
    //     csvExport: false,
    //     formatter: this.tombolAksi
    //   }
    // ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama_role",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-success";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };

    return (
      <div>
        {/* <Breadcrumb title="Perangkat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                {this.state.loading ? (
                  <div className="row">
                    <div className="col-sm-12" align="center">
                      <img 
                      alt="loading"
                      src={require("../assets/images/loading-bos.gif")} 
                      style={{
                        // width: "100%",
                        borderRadius: "10px",
                        width: '70px'
                      }}/> 
                    </div>
                  </div>
                ) : 
                <>
                  { 
                    this.state.konfig_umum === 'none'
                    ?
                    <>
                      {
                        this.state.data_cek === "Kosong"
                        ?
                        <>
                        <div className="col-md-12 text-center">
                          <br/><br/><br/>
                          <h4>Layanan KiosK Belum Dikonfigurasi</h4>
                          <br/>
                          <Button  size="md" color="primary" className="btn-icon" onClick={this.ubahKonfig}><i className="fa fa-wrench"></i> Konfigurasi Sekarang</Button> 
                        </div>
                        <br/> <br/> <br/>
                        </>
                        :
                        <>
                          <div className="col-md-12 text-right">
                            <Button  size="md" color="primary" className="btn-icon" onClick={this.ubahKonfig}><i className="fa fa-pencil"></i> Ubah Konfigurasi</Button> 
                          </div>
                          <br/> <br/> <br/>


                          <div className="row">
                            <div className="col-sm-3" align="center">
                              &nbsp;
                            </div>
                            <div className="col-sm-3" align="center">
                              <h5>Layanan</h5>
                            </div>
                            <div className="col-sm-2" align="center">
                              <h5>Status Layanan</h5>
                            </div>
                            <div className="col-sm-2" align="center">
                              <h5>Status Cetak Layanan</h5>
                            </div>
                            <div className="col-sm-2" align="center">
                              &nbsp;
                            </div>
                          </div>

                          {
                            Object.keys(this.state.list_layanan_kiosk).map((val, index) => {
                              // let list_layanan_kiosk = this.state.list_layanan_kiosk
                              let jenis_layanan_desa_kiosk = 'Jenis Layanan Desa Mobile'
                              let value_jenis_layanan_kiosk = ''
                              let value_cetak_layanan_kiosk = ''
                              switch (val) {
                                case 'tidak_mampu':
                                  jenis_layanan_desa_kiosk = "Surat Tidak Mampu"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_mampu

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_mampu
                                  break;
                                case 'domisili':
                                  jenis_layanan_desa_kiosk = "Surat Domisili"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.domisili

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.domisili
                                  break;
                                case 'kelahiran':
                                  jenis_layanan_desa_kiosk = "Surat Kelahiran"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kelahiran

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kelahiran
                                  break;
                                case 'belum_memiliki_rumah':
                                  jenis_layanan_desa_kiosk = "Surat Belum Memiliki Rumah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_memiliki_rumah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah
                                  break;
                                case 'jalan':
                                  jenis_layanan_desa_kiosk = "Surat Jalan"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.jalan

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.jalan
                                  break;
                                case 'kematian':
                                  jenis_layanan_desa_kiosk = "Surat Kematian"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kematian

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kematian
                                  break;
                                case 'kepemilikan_rumah':
                                  jenis_layanan_desa_kiosk = "Surat Kepemilikan Rumah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kepemilikan_rumah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kepemilikan_rumah
                                  break;
                                case 'penghasilan':
                                  jenis_layanan_desa_kiosk = "Surat Penghasilan"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.penghasilan

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.penghasilan
                                  break;
                                case 'skck':
                                  jenis_layanan_desa_kiosk = "Surat SKCK"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skck

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skck
                                  break;
                                case 'kehilangan':
                                  jenis_layanan_desa_kiosk = "Surat Kehilangan"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kehilangan

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kehilangan
                                  break;
                                case 'usaha':
                                  jenis_layanan_desa_kiosk = "Surat Usaha"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.usaha

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.usaha
                                  break;
                                case 'skgr':
                                  jenis_layanan_desa_kiosk = "Surat SKGR"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skgr
                                  
                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skgr
                                  break;
                                case 'belum_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Belum Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_menikah
                                  break;
                                case 'tidak_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Tidak Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_menikah
                                  break;
                                case 'pindah_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Pindah Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pindah_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pindah_menikah
                                  break;
                                case 'pernah_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Pernah Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pernah_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pernah_menikah
                                  break;
                                case 'keramaian':
                                  jenis_layanan_desa_kiosk = "Surat Izin Keramaian"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.keramaian

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.keramaian
                                  break;
                                case 'ahli_waris':
                                  jenis_layanan_desa_kiosk = "Surat Keterangan Ahli Waris"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.ahli_waris

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.ahli_waris
                                  break;
                                default:
                                  // no default
                                  break;
                              }

                              return (
                                
                              <div className="row">
                                <div className="col-sm-3" align="center">
                                  &nbsp;
                                </div>
                                <div className="col-sm-3" align="center">
                                  {jenis_layanan_desa_kiosk}
                                </div>
                                <div className="col-sm-2" align="center">
                                  <Switch 
                                    onChange={(e) => {
                                    this.handleChange(e, val);
                                    }}
                                    checked={value_jenis_layanan_kiosk} 
                                    disabled="true"
                                  />
                                </div>
                                <div className="col-sm-2" align="center">
                                  <Switch 
                                    onChange={(e) => {
                                    this.handleChangeCetak(e, val);
                                    }}
                                    checked={value_cetak_layanan_kiosk} 
                                    disabled="true"
                                  />
                                </div>
                                <div className="col-sm-2" align="center">
                                  &nbsp;
                                </div>
                              </div>
                              )
                            })
                          }
                        </>
                      }
                    </>
                    :
                    <>
                      <div className="col-md-12 text-right">
                          <Button  size="md" color="success" className="btn-icon" onClick={() => this.simpanLayanan()}><i className="fa fa-save"></i> Simpan</Button> 
                      </div>
                      <br/> <br/> <br/>
                      

                      <div className="row">
                        <div className="col-sm-3" align="center">
                          &nbsp;
                        </div>
                        <div className="col-sm-3" align="center">
                          <h5>Layanan</h5>
                        </div>
                        <div className="col-sm-2" align="center">
                          <h5>Status Layanan</h5>
                        </div>
                        <div className="col-sm-2" align="center">
                          <h5>Status Cetak Layanan</h5>
                        </div>
                        <div className="col-sm-2" align="center">
                          &nbsp;
                        </div>
                      </div>
                      {
                        Object.keys(this.state.list_layanan_kiosk).map((val, index) => {
                          // let list_layanan_kiosk = this.state.list_layanan_kiosk
                          let jenis_layanan_desa_kiosk = 'Jenis Layanan Desa Mobile'
                          let value_jenis_layanan_kiosk = ''
                          let value_cetak_layanan_kiosk = ''
                          let stat_disable = ''
                              switch (val) {
                                case 'tidak_mampu':
                                  jenis_layanan_desa_kiosk = "Surat Tidak Mampu"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_mampu

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_mampu

                                  if(this.state.list_layanan_kiosk.tidak_mampu===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'domisili':
                                  jenis_layanan_desa_kiosk = "Surat Domisili"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.domisili

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.domisili

                                  if(this.state.list_layanan_kiosk.domisili===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'kelahiran':
                                  jenis_layanan_desa_kiosk = "Surat Kelahiran"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kelahiran

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kelahiran

                                  if(this.state.list_layanan_kiosk.kelahiran===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'belum_memiliki_rumah':
                                  jenis_layanan_desa_kiosk = "Surat Belum Memiliki Rumah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_memiliki_rumah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_memiliki_rumah

                                  if(this.state.list_layanan_kiosk.belum_memiliki_rumah===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'jalan':
                                  jenis_layanan_desa_kiosk = "Surat Jalan"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.jalan

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.jalan

                                  if(this.state.list_layanan_kiosk.jalan===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'kematian':
                                  jenis_layanan_desa_kiosk = "Surat Kematian"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kematian

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kematian

                                  if(this.state.list_layanan_kiosk.kematian===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'kepemilikan_rumah':
                                  jenis_layanan_desa_kiosk = "Surat Kepemilikan Rumah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kepemilikan_rumah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kepemilikan_rumah

                                  if(this.state.list_layanan_kiosk.kepemilikan_rumah===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'penghasilan':
                                  jenis_layanan_desa_kiosk = "Surat Penghasilan"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.penghasilan

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.penghasilan

                                  if(this.state.list_layanan_kiosk.penghasilan===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'skck':
                                  jenis_layanan_desa_kiosk = "Surat SKCK"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skck

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skck

                                  if(this.state.list_layanan_kiosk.skck===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'kehilangan':
                                  jenis_layanan_desa_kiosk = "Surat Kehilangan"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.kehilangan

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.kehilangan

                                  if(this.state.list_layanan_kiosk.kehilangan===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'usaha':
                                  jenis_layanan_desa_kiosk = "Surat Usaha"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.usaha

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.usaha

                                  if(this.state.list_layanan_kiosk.usaha===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'skgr':
                                  jenis_layanan_desa_kiosk = "Surat SKGR"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.skgr
                                  
                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.skgr

                                  if(this.state.list_layanan_kiosk.skgr===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'belum_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Belum Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.belum_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.belum_menikah

                                  if(this.state.list_layanan_kiosk.belum_menikah===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'tidak_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Tidak Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.tidak_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.tidak_menikah

                                  if(this.state.list_layanan_kiosk.tidak_menikah===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'pindah_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Pindah Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pindah_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pindah_menikah

                                  if(this.state.list_layanan_kiosk.pindah_menikah===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'pernah_menikah':
                                  jenis_layanan_desa_kiosk = "Surat Pernah Menikah"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.pernah_menikah

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.pernah_menikah

                                  if(this.state.list_layanan_kiosk.pernah_menikah===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'keramaian':
                                  jenis_layanan_desa_kiosk = "Surat Izin Keramaian"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.keramaian

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.keramaian

                                  if(this.state.list_layanan_kiosk.keramaian===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                case 'ahli_waris':
                                  jenis_layanan_desa_kiosk = "Surat Keterangan Ahli Waris"
                                  value_jenis_layanan_kiosk = this.state.list_layanan_kiosk.ahli_waris

                                  value_cetak_layanan_kiosk = this.state.list_cetak_layanan_kiosk.ahli_waris

                                  if(this.state.list_layanan_kiosk.ahli_waris===false){
                                    stat_disable = true
                                  }
                                  else{
                                    stat_disable = false
                                  }
                                  break;
                                default:
                                  // no default
                                  break;
                          }

                          return (
                            
                          <div className="row">
                            <div className="col-sm-3" align="center">
                              &nbsp;
                            </div>
                            <div className="col-sm-3" align="center">
                              {jenis_layanan_desa_kiosk}
                            </div>
                            <div className="col-sm-2" align="center">
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e, val);
                                }}
                                checked={value_jenis_layanan_kiosk} 
                              />
                            </div>
                            <div className="col-sm-2" align="center">
                              <Switch 
                                onChange={(e) => {
                                this.handleChangeCetak(e, val);
                                }}
                                checked={value_cetak_layanan_kiosk} 
                                disabled={stat_disable}
                              />
                            </div>
                            <div className="col-sm-2" align="center">
                              &nbsp;
                            </div>
                          </div>
                          )
                        })
                      }
                  </>
                  }
                </>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
       </div>
    );
  }
}

export default BasicTable;
import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { DataToken } from "../function/Format";

// koneksi
import { PostStatus, Get, Delete, cekLogin, JENIS_DESA } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');
const frmDef = {
  uuid: "",
  id_desa: 0,
  nama: "",
  id_ukuran_kertas: "",
  nama_table: ""
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        disKode: "inherit"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKel: [],
      kelStat: [],
      frmUkuran: [],
      ukkStat: [],
      akun: DataToken(sessionStorage.getItem("access_token"))
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("jenis-surat" + link, null, dtkat => {
      this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addRole = {
        nama_role: e.target.value
      };
      cekLogin("jenis-surat", addRole, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    //console.log(e);
    if (this.state.dt.id_desa > 0 && this.state.dt.id_ukuran_kertas !== "") {
      this.state.status.btnForm = true;
      this.forceUpdate();
      let add = {
        uuid: this.state.dt.uuid,
        id_desa: this.state.dt.id_desa,
        nama: document.getElementById("nama").value,
        id_ukuran_kertas: this.state.dt.id_ukuran_kertas,
        kategori: this.state.dt.kategori,
        nama_table: document.getElementById("nama_table").value,
        kode_surat: document.getElementById("kode_surat").value
      };
      //console.log(add);
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      PostStatus("jenis-surat/" + metode, add.uuid, add, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Jenis Surat",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Jenis Surat",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Jenis Surat",
        pesanAlert: "Ukuran kertas atau kelurahan " + JENIS_DESA + " tidak bolek kosong"
      });
    }
  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = id => {
    //console.log(id);
    this.state.status.btnAksi = true;
    this.forceUpdate();
    Get("jenis-surat/find", id, data => {
      //console.log(data);
      this.ubahPilihan("keldes", data.results.data_keldes);

      this.state.dt.uuid = data.results.uuid;
      this.state.dt.id_desa = data.results.id_desa;
      this.state.dt.id_ukuran_kertas = data.results.id_ukuran_kertas;
      this.state.dt.nama = data.results.nama;
      this.state.dt.nama_table = data.results.nama_table;
      this.state.dt.kode_surat = data.results.kode_surat;
      this.state.ukkStat = {
        value: data.results.data_ukuran_kertas.uuid,
        label: data.results.data_ukuran_kertas.nama
      };
      this.state.ktrStat = {
        value: data.results.kategori,
        label: data.results.kategori
      };
      this.state.status.btnAksi = false;
      this.state.judul = "Ubah Jenis Surat";
      this.forceUpdate();
    });
  };
  ubahPilihan = (table, data) => {
    //console.log("data");

    //console.log(data);
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "kecamatan":
        this.getChild(
          {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          { value: data.data_kabkota.id, label: data.data_kabkota.nama },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.setState({
          prvStat: {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kabkota.id,
            label: data.data_kabkota.nama
          },
          kecStat: { value: data.id, label: data.nama }
        });
        break;
      case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );

        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama
          },
          kelStat: { value: data.id, label: data.nama }
        });
        this.bukaForm();
        break;
        default:
          // no default
          break;
    }
    // });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("jenis-surat/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Jenis Surat",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Jenis Surat",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.state.dt.uuid = "";
    this.state.dt.id_desa = "";
    this.state.dt.id_ukuran_kertas = "";
    this.state.dt.nama = "";
    this.state.dt.nama_table = "";
    this.state.dt.kode_surat = "";
    this.state.ukkStat = {
      value: "",
      label: ""
    };
    this.state.ktrStat = {
      value: "",
      label: ""
    };
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        <Link to={{ pathname: "/admin/alur-disposisi/"+row.uuid, state: row }}>
          <Button
            size="xs"
            color="success"
            className="btn-icon"
          >
            <i className="fa fa-tasks"></i>
          </Button>{" "}
        </Link>
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          style={{ display: this.state.status.disKode }}
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
      </>
    );
  };

  getChild = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.forEach(dt => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKec: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKel: frm });
            break;
          default:
            // no default
            break;
        }
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: e });
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [] });
          break;
        case "kab-kota":
          //console.log(e);
          this.setState({ kbktStat: e });
          this.setState({ kecStat: [] });
          break;
        case "kecamatan":
          this.setState({ kecStat: e });
          this.setState({ kelStat: [] });

          break;
        case "keldes":
          this.state.dt.id_desa = e.value;
          this.setState({ kelStat: e });
          break;

        case "jenis_surat":
          this.state.dt.kategori = e.value;
          this.setState({ ktrStat: e });
          break;
        case "ukuran_kertas":
          this.state.dt.id_ukuran_kertas = e.value;
          this.setState({ ukkStat: e });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: [] });
          this.setState({ kbktStat: [], frmKbkt: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.setState({ kelStat: [], frmKel: [] });
          break;
        case "kab-kota":
          this.setState({ kbktStat: [] });

          this.setState({ kecStat: [], frmKec: [] });
          this.setState({ kelStat: [], frmKel: [] });

          break;
        case "kecamatan":
          this.setState({ kecStat: [] });
          this.setState({ kelStat: [], frmKel: [] });

          break;
        case "keldes":
          this.state.dt.id_keldes = 0;
          this.setState({ kelStat: [] });
          break;
        case "jenis_surat":
          this.state.dt.kategori = e.value;
          this.setState({ ktrStat: [] });
          break;
        case "ukuran_kertas":
          this.state.dt.id_ukuran_kertas = 0;
          this.setState({ ukkStat: [] });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  componentDidMount() {
    this.fetch({ page: null, where: [] });
    Get("provinsi/list", null, data => {
      let frmPrv = [];
      data.results.forEach(dt => {
        frmPrv.push({ value: dt.id, label: dt.nama });
      });
      this.setState({ frmPrv });
    });
    Get("ukuran-kertas/list", null, data => {
      let frmUkuran = [];
      data.results.forEach(dt => {
        frmUkuran.push({ value: dt.uuid, label: dt.nama });
      });
      this.setState({ frmUkuran });
    });

    Get("select-enum/jenis_surat/kategori", null, data => {
      let frmKategori = [];
      //console.log(data);
      data.forEach(dt => {
        frmKategori.push({ value: dt, label: dt });
      });
      this.setState({ frmKategori });
    });
  }

  render() {
    const { akun } = this.state;
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      id_desa: 0,
      nama: "",
      id_ukuran_kertas: "",
      nama_table: ""
    };
    const level = akun.sub.data_role.nama_role === "Admin" ? 2 : 1;
    if (level > 1) {
      this.state.status.disKode = "none";
    }
    // const { SearchBar } = Search;
    const columns = [
      // {
      //     dataField: 'uuid',
      //     text: 'ID UUD',
      //     sort: false
      // },
      {
        dataField: "data_keldes",
        text: JENIS_DESA,
        formatter: (cell, obj) => {
          let daerah =
            obj.data_keldes.nama +
            ", " +
            obj.data_keldes.data_kecamatan.nama +
            ", " +
            obj.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " +
            obj.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;

          return daerah;
        },
        sort: true
      },
      {
        dataField: "nama",
        text: "Nama",
        sort: true
      },
      {
        dataField: "kategori",
        text: "Kategori",
        sort: true
      },
      // {
      //   dataField: "nama_table",
      //   text: "Table",
      //   sort: true,
      // },
      {
        dataField: "kode_surat",
        text: "Kode surat",
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama_role",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-success";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };

    return (
      <div>
        {/* <Breadcrumb title="Data Jenis Surat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Jenis Surat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        style={{ display: this.state.status.disKode }}
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Jenis Surat",
                            dt: frmDef,
                            prvStat: [],
                            kbktStat: [],
                            kecStat: [],
                            kelStat: [],
                            ukkStat: []
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />
              <div style={{ display: this.state.status.disKode }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Provinsi
                  </Label>
                  <Col sm="9">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getChild(e, "provinsi", "prov", "kab-kota");
                      }}
                      defaultValue={this.state.prvStat}
                      value={this.state.prvStat}
                      name="provinsi"
                      options={this.state.frmPrv}
                      placeholder="Pilih Provinsi"
                      isClearable
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kabupaten / Kota
                  </Label>
                  <Col sm="9">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getChild(e, "kab-kota", "kab", "kecamatan");
                      }}
                      defaultValue={this.state.kbktStat}
                      value={this.state.kbktStat}
                      name="kabkota"
                      options={this.state.frmKbkt}
                      placeholder="Pilih Kabupaten/Kota"
                      isClearable
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kecamatan
                  </Label>
                  <Col sm="9">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getChild(e, "kecamatan", "kec", "keldes");
                      }}
                      defaultValue={this.state.kecStat}
                      value={this.state.kecStat}
                      name="kecamatan"
                      options={this.state.frmKec}
                      placeholder="Pilih Kecamatan"
                      isClearable
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kelurahan / {JENIS_DESA}
                  </Label>
                  <Col sm="9">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.changeSelectValue(e, "keldes");
                      }}
                      defaultValue={this.state.kelStat}
                      value={this.state.kelStat}
                      name="keldes"
                      options={this.state.frmKel}
                      placeholder={"Pilih Kelurahan/" + JENIS_DESA}
                      isClearable
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Ukuran kertas
                  </Label>
                  <Col sm="9">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.changeSelectValue(e, "ukuran_kertas");
                      }}
                      defaultValue={this.state.ukkStat}
                      value={this.state.ukkStat}
                      name="ukuran_kertas"
                      options={this.state.frmUkuran}
                      placeholder="Pilih Ukuran kertas"
                      isClearable
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Surat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama"
                      type="text"
                      placeholder="masukan nama surat"
                      required
                      defaultValue={this.state.dt.nama}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Surat
                  </Label>
                  <Col sm="9">
                    <Select
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.changeSelectValue(e, "jenis_surat");
                      }}
                      defaultValue={this.state.ktrStat}
                      value={this.state.ktrStat}
                      name="Jenis Surat"
                      options={this.state.frmKategori}
                      placeholder="Pilih Jenis Surat"
                      isClearable
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama table
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_table"
                      type="text"
                      placeholder="masukan nama table"
                      required
                      defaultValue={this.state.dt.nama_table}
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kode Surat
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kode_surat"
                    type="text"
                    placeholder="kode_surat"
                    required
                    defaultValue={this.state.dt.kode_surat}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;

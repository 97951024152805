import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Form } from 'reactstrap';
import { JENIS_DUSUN, Post, Get } from '../function/Koneksi';
import Select from "react-select";
// import Keyboard from 'react-simple-keyboard';

let jmlAcara = 0;
const frmDef = {
	uuid: '',
	id_jenis: '',
	id_pengaju: '',
	tanggal_surat: new Date(),
	nik: '',
	nama_pemohon: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	pekerjaan: '',
	agama: '',
	status_perkawinan: '',
	alamat: '',
	kewarganegaraan: 'Indonesia',
	tujuan_pembuatan: '',
	status_ttd: '',
	jabatan_ttd: '',
	jenis_kelamin: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	acara: [ { nama: '' } ],
	nip_ttd: '',
	lokasi: ''
};

export default class SuratKehilangan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Basic: false,
			dt: frmDef,
			// keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'block',
			focus: false,
			hasFocus: false,
			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			// input: inputObj
			input: updatedInputObj
		});
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		//console.log(inputVal);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onKeyPress = (button) => {
		// //console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: 'block'
			});
		}
		this.forceUpdate();
	};

	onOpenModal = (key) => {
		//console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ 
			[key]: false, 
			dt: frmDef, 
			input: '', 
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
		document.getElementById('lokasi').value = '';
	};

	handleClick = (event) => {
		alert(event);
	};

	tambahAcara = (e) => {
		jmlAcara++;
		let acara = [];
		for (let i = 1; i < jmlAcara; i++) {
			let nama_acara = document.getElementById('acara' + i).value;
			acara.push({ nama: nama_acara });
		}
		acara.push({ nama: '' });
		this.setState({
			dt:{
				...this.state.dt,
				acara: acara,
			}
		})
		this.forceUpdate();
	};

	simpan = (e) => {
		e.preventDefault();

		
		if (this.state.nikStatTerlapor) {
			let kehilangan = [];
			for (let i = 1; i <= jmlAcara; i++) {
				let nama_kehilangan = document.getElementById('acara' + i).value;
				kehilangan.push(nama_kehilangan);
			}

			// let uuid = document.getElementById('uuid').value;
			// let alamat = '';
			// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			// 	alamat =
			// 		' RT. ' +
			// 		this.props.data.results.data_kk.data_rt.nama +
			// 		'/RW. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			// 		', Kec. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			// } else {
			// 	// alamat =
			// 	// 	' RT. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.nama +
			// 	// 	'/RW. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	// 	', ' +
			// 	// 	JENIS_DUSUN +
			// 	// 	' ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	// 	', Kec. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

			// 		if (this.props.data.results.data_kk.id_rt === '0') {
			// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
			// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			// 		} else {
			// 			alamat =
			// 			" RT. " + 
			// 			this.props.data.results.data_kk.data_rt.nama +
			// 			"/RW. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 			", " + JENIS_DUSUN + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 			", " + JENIS_DESA + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 			", Kec. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.nama +
			// 			", " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.data_kabkota.nama; 
			// 		}
			// }

			let addSkck = {
				id_jenis: this.props.id_jenis,
				nik_pelapor: this.props.data.results.id,
				nik: this.state.dt_keluarga.nik,
				kk: this.state.dt_keluarga.kk,
				nama_pemohon: this.state.dt_keluarga.nama,
				tempat_lahir: this.state.dt_keluarga.tempat_lahir,
				tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
				pekerjaan: this.state.dt_keluarga.pekerjaan,
				agama: this.state.dt_keluarga.agama,
				status_perkawinan: this.state.dt_keluarga.status_perkawinan,
				alamat: this.state.dt_keluarga.alamat,
				jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
				pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,

				kewarganegaraan: 'Indonesia',
				lokasi: document.getElementById('lokasi').value,
				tujuan_pembuatan: kehilangan.toString()
			};

			// let psn = '';
			let resstat = 201;
			let metode = 'create';

			Post('surat-keterangan/grup/' + metode, null, addSkck, (res) => {
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};

				if (res.status === resstat) {
					Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
						//console.log("Hasil Update Status")
						//console.log(res2)
						if (res2.code === 201) {
							//console.log("Berhasil Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Pernyataan Kehilangan', 'berhasil', false, dataAntrian);
						}
						else{
							//console.log("Gagal Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Pernyataan Kehilangan', 'gagal');
						}
					});
					// this.props.getAntrian('Surat Pernyataan Kehilangan', 'berhasil', false, dataAntrian);
				} else {
					this.props.getAntrian('Surat Pernyataan Kehilangan', 'gagal');
				}
				// this.componentDidMount();
				// reset cache
				document.getElementById('lokasi').value = '';
				jmlAcara = [];
				this.setState({
					dt: frmDef,
					input: {},
				})
				this.forceUpdate();
			});
		} else {
			this.props.getAntrian('Surat Pernyataan Kehilangan', 'gagal', true);
		}
	};

	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });
	}

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
		}
	  };
	
	getPenduduk = (e, sel) => {
		if (e) {
		  this.changeSelectValue(e, sel);
		  Get("penduduk/find", e.value, data => {
			let alamat = '';
			if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
			  alamat =
			  " RT. " +
			  data.results.data_kk.data_rt.nama +
			  "/RW. " +
			  data.results.data_kk.data_rt.data_rw.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			  ", Kec. " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.data_kabkota.nama; 
			} else {
			  if (data.results.data_kk.id_rt === '0') {
				alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
				", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			  } else {
				alamat =
				" RT. " + 
				data.results.data_kk.data_rt.nama +
				"/RW. " +
				data.results.data_kk.data_rt.data_rw.nama +
				", " + JENIS_DUSUN + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				", " + localStorage.getItem('JENIS_DESA') + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				", Kec. " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.nama +
				", " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.data_kabkota.nama; 
			  }
			}
	
			
			if (sel === "nikTerlapor") {
				this.setState({
					dt_keluarga:{
						nik: e.value,
						kk: data.results.id_kk,
						nama: data.results.nama,
						agama: data.results.data_agama.nama,
						alamat: alamat,
						jenis_kelamin: data.results.jk,
						pekerjaan: data.results.data_pekerjaan.nama,
						status_perkawinan: data.results.status_perkawinan,
						tanggal_lahir: data.results.tanggal_lahir,
						tempat_lahir: data.results.tempat_lahir,
						pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
					},
					dataKeluarga: 'block',
				})
			}
			this.forceUpdate();
		  });
		} else {
			this.setState({
				dt_keluarga:{
					nik: '',
					kk: '',
					nama: '',
					agama: '',
					alamat: '',
					jenis_kelamin: '',
					pekerjaan: '',
					status_perkawinan: '',
					tanggal_lahir: '',
					tempat_lahir: '',
					pendidikan_terakhir: '',
				},
				dataKeluarga: 'none',
				nikStatTerlapor:null,
			});
		  	this.forceUpdate();
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		} else {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		}
	
		this.forceUpdate();
	};

	render() {
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		jmlAcara = 0;
		const acara = this.state.dt.acara.map((dt, i) => {
			let no = i + 1;
			jmlAcara++;
			return (
				<input
					onFocus={(e) => {
						this.setState({ display: 'block' });
						//console.log(e.target);
						this.setActiveInput('acara' + no);
					}}
					value={this.state.input['acara' + no] || ''}
					onChange={(e) => this.onChangeInput(e)}
					className="form-control mb-2"
					key={i}
					id={'acara' + no}
					type="text"
					required
					placeholder={'Contoh : kartu atm, stnk, kartu sim'}
					defaultValue={dt.nama}
				/>
			);
		});

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display == 'block' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Pernyataan Kehilangan
								</h5>
							</div>

							<div className="modal-body">
								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup> */}

								
								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>

								<div style={{ display: 'none' }}>
									<input
										className="form-control"
										id="jenis_kelamin"
										readOnly
										type="hidden"
										placeholder="jenis_kelamin"
										value={this.props.data.results.jk}
									/>

									<input
										className="form-control"
										readOnly
										id="tempat_lahir"
										type="text"
										placeholder="Tempat lahir"
										value={this.props.data.results.tempat_lahir}
									/>

									<input
										className="form-control"
										id="tanggal_lahir"
										readOnly
										type="text"
										placeholder="Tanggal Lahir"
										value={this.props.data.results.tanggal_lahir}
									/>

									<input
										className="form-control"
										readOnly
										id="pekerjaan"
										type="hidden"
										placeholder="Pekerjaan"
										value={this.props.data.results.pekerjaan}
									/>

									<input
										className="form-control"
										id="pendidikan_terakhir"
										readOnly
										type="hidden"
										placeholder="Pendidikan Terakhir"
										value={this.props.data.results.pendidikan_terakhir}
									/>

									<input
										className="form-control"
										id="agama"
										readOnly
										type="hidden"
										placeholder="Agama"
										value={this.props.data.results.agama}
									/>

									<input
										className="form-control"
										id="status_perkawinan"
										type="hidden"
										readOnly
										placeholder="Status Perkawinan"
										value={this.props.data.results.status_perkawinan}
									/>

									<input
										className="form-control"
										id="alamat"
										type="hidden"
										readOnly
										placeholder="Alamat"
										value={this.props.data.results.alamat}
									/>

									<input
										className="form-control"
										id="kewarganegaraan"
										type="hidden"
										readOnly={true}
										placeholder="Kewarganegaraan"
										value="Indonesia"
									/>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kehilangan
									</Label>
									<Col sm="9">
										{acara}
										{/* <button type="button" className="btn btn-success" onClick={this.tambahAcara}>
										Tambah Kehilangan
									</button> */}
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Lokasi
									</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												//console.log(e.target);
												this.setActiveInput('lokasi');
											}}
											value={this.state.input['lokasi'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="lokasi"
											type="text"
											required
											placeholder="lokasi"
											defaultValue={this.state.dt.lokasi}
										/>
									</Col>
								</FormGroup>
							</div>

							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* <div
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							width: '100%',
							zIndex: 4,
							display: this.state.display
						}}
					>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/kehilangan.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Pernyataan Kehilangan
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Form, Button } from 'reactstrap';
import Select from 'react-select';
import { Tanggal } from '../function/Format';
import { Get, Post } from '../function/Koneksi';

// import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

let aw = {
	nik: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jenis_kelamin: '',
	pekerjaan: ''
};

export default class SuratTidakMampu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false,
			display: 'none',
			layoutName: 'default',
			inputName: 'input1',
			input: {},
			inputPattern: /^\d+$/,
			Basic: false,
			detail: [],
			dt: {
				uuid: '',
				id_jenis: '',
				nomor: '',
				tanggal_surat: new Date(),

				nik: '',
				kk: '',
				nama: '',
				tempat_lahir: '',
				tanggal_lahir: '',
				jenis_kelamin: '',
				pekerjaan: '',
				pendidikan_terakhir: '',
				kewarganegaraan: '',
				agama: '',
				alamat: '',
				status_perkawinan: '',
				tujuan_pembuatan: '',

				status_ttd: '',
				jabatan_ttd: '',
				jabatan_pengganti_ttd: '',
				nama_ttd: '',
				nip_ttd: '',
				status_ttd2: '',
				jabatan_ttd2: '',
				jabatan_pengganti_ttd2: '',
				nama_ttd2: '',
				nip_ttd2: ''
			},
			// Stat
			frmJam: [],
			jamStat: [],
			frmPkr: [],
			pkrStat: [],
			frmAgm: [],
			agmStat: [],
			// NIK
			frmNik: [],
			nikStat: null,
			frmNik2: [],
			nik2Stat: null,
			frmNik3: [],
			nik3Stat: null,
			// Alert
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false,

			//shdk
			shdk_aw: null,
			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
	}

	onOpenModal = (key) => {
		//console.log(key);
		this.setState({ [key]: true, input: '' });
	};

	onCloseModal = (key) => {
		this.setState({ 
			[key]: false,
			nikStat: null,
			nik2Stat: null,
			nik3Stat: null,
			shdk1: null,
			shdk_aw: null,
			meninggal2: 'none',
			detail: [],
			dt: {
				...this.state.dt,
				nama: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				agama: '',
				pekerjaan: '',
				kewarganegaraan: '',
				alamat: '',
				jenis_kelamin: '',
				status_perkawinan: '',
				tempat_meninggal: '',
				sebab_meninggal: '',
				nama2: '',
				tanggal_lahir2: '',
				tempat_lahir2: '',
				agama2: '',
				pekerjaan2: '',
				kewarganegaraan2: '',
				alamat2: '',
				jenis_kelamin2: '',
				status_perkawinan2: '',
				tempat_meninggal2: '',
				sebab_meninggal2: '',
			},
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
		// this.state.nikStat = null;
		// this.state.dt.nama = '';
		// this.state.dt.tanggal_lahir = '';
		// this.state.dt.tempat_lahir = '';
		// this.state.dt.agama = '';
		// this.state.dt.pekerjaan = '';
		// this.state.dt.kewarganegaraan = '';
		// this.state.dt.alamat = '';
		// this.state.dt.jenis_kelamin = '';
		// this.state.dt.status_perkawinan = '';
		// this.state.dt.tempat_meninggal = '';
		// this.state.dt.sebab_meninggal = '';

		// this.state.nik2Stat = null;
		// this.state.nik3Stat = null;
		// this.state.shdk1 = null;
		// this.state.shdk_aw = null;
		// this.state.dt.nama2 = '';
		// this.state.dt.tanggal_lahir2 = '';
		// this.state.dt.tempat_lahir2 = '';
		// this.state.dt.agama2 = '';
		// this.state.dt.pekerjaan2 = '';
		// this.state.dt.kewarganegaraan2 = '';
		// this.state.dt.alamat2 = '';
		// this.state.dt.jenis_kelamin2 = '';
		// this.state.dt.status_perkawinan2 = '';
		// this.state.dt.tempat_meninggal2 = '';
		// this.state.dt.sebab_meninggal2 = '';
		// this.state.meninggal2 = 'none';
		// this.state.detail = [];

		this.forceUpdate();
	};

	handleClick = (event) => {
		alert(event);
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
			else if (fld === 'nik') {
				let nikStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.forEach((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik, nikStat });
				});
			} else {
				// let nik3Stat = { value: e, label: e };
				// let param = {
				// 	nik: e
				// };

				// let keyword = e;
				// let datanya = this.state.frmNik3;

				// let filtered = datanya.filter((entry) =>
				// 	Object.values(entry).some((val) => typeof val === 'string' && val.includes(keyword))
				// );
				// let nik3Stat = { value: e, label: e };
				// let param = {
				// 	nik: e
				// };
				// Post('penduduk', null, param, (datanik) => {
				// 	let frmNik3 = [];
				// 	datanik.data.results.data.map((dt) => {
				// 		frmNik3.push({ value: dt.id, label: dt.id });
				// 	});
				// 	this.setState({ frmNik3, nik3Stat });
				// });
			}
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				case 'nik3':
					// this.state.dt.nik3 = e.value;
					this.setState({ 
						dt:{
							...this.state.dt,
							nik3: e.value,
						},
						nik3Stat: e 
					});
					break;
				case 'shdk_aw':
					// this.state.shdk_aw = e.value;
					this.setState({ shdk_aw: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				case 'nik3':
					// this.state.dt.nik3 = 0;
					this.setState({ 
						dt:{
							...this.state.dt,
							nik3: 0,
						},
						nik3Stat: e 
					});
					break;
				case 'shdk_aw':
					// this.state.shdk_aw = 0;
					this.setState({ shdk_aw: 0 });
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

	getPenduduk = (e, sel) => {
		// this.state.status.btnForm = true;
		// this.forceUpdate();
		if (e) {
			this.changeSelectValue(e, sel);
			Get('penduduk/find', e.value, (data) => {
				// const data_rt = data.results.data_kk.data_rt;
				// const data_rw = data_rt.data_rw;
				// const data_dusun = data_rw.data_dusun;
				// const data_desa = data_dusun.data_keldes;
				// const data_kecamatan = data_desa.data_kecamatan;
				// const data_kabkota = data_kecamatan.data_kabkota;

				let alamat =
					' RT. ' +
					data.results.data_kk.data_rt.nama +
					'/RW. ' +
					data.results.data_kk.data_rt.data_rw.nama +
					', Dusun ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					', ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					', Kec. ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
					', ' +
					data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

				if (sel === "nikTerlapor") {
					this.setState({
						dt_keluarga:{
							nik: e.value,
							kk: data.results.id_kk,
							nama: data.results.nama,
							agama: data.results.data_agama.nama,
							alamat: alamat,
							jenis_kelamin: data.results.jk,
							pekerjaan: data.results.data_pekerjaan.nama,
							status_perkawinan: data.results.status_perkawinan,
							tanggal_lahir: data.results.tanggal_lahir,
							tempat_lahir: data.results.tempat_lahir,
							pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
						},
						dataKeluarga: 'block',
					})
				}
				else if (sel === 'nik3') {
					// aw.nik = e.value;
					// aw.nama = data.results.nama;
					// aw.jenis_kelamin = data.results.jenis_kelamin;
					// aw.tanggal_lahir = data.results.tanggal_lahir;
					// aw.tempat_lahir = data.results.tempat_lahir;
					// aw.pekerjaan = data.results.data_pekerjaan.nama;
				} else {
					Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
						let pctt = 0;
						let catatan = '';
						if (dpddk.results.data) {
							dpddk.results.data.forEach((dtp) => {
								if (dtp.status === 0 && dtp.penalti === 1) {
									pctt = 1;
									catatan = dtp.catatan;
								}
							});
						}
						if (pctt === 0) {
							Get('penduduk/kk/' + data.results.id_kk, null, (dtkk) => {
								let frmNik3 = [];
								dtkk.results.forEach((dt) => {
									frmNik3.push({ value: dt.id, label: dt.id });
								});

								this.setState({
									tanggal_lahir: data.results.tanggal_lahir,	
									frmNik3: frmNik3,
									status:{
										...this.state.status,
										btnForm: false,
										dataPenduduk: 'block',
									},
									dt:{
										...this.state.dt,
										kk: data.results.id_kk,
										nama: data.results.nama,
										tanggal_lahir: new Date(data.results.tanggal_lahir).toLocaleDateString(
											'id-ID'
										),
										tempat_lahir: data.results.tempat_lahir,
										jenis_kelamin: data.results.jk,
										status_perkawinan: data.results.status_perkawinan,
										agama: data.results.data_agama.nama,
										pekerjaan: data.results.data_pekerjaan.nama,
										pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
										kewarganegaraan: 'Indonesia',
										alamat: alamat,
									}
								})
								this.forceUpdate();
								// this.setState({ frmNik3 });
							});
						} else {
							this.setState({
								nikStat: null,
								frmNik: [],
								dt:{
									...this.state.dt,
									nama: '',
									tanggal_lahir: '',
									tempat_lahir: '',
									agama: '',
									pekerjaan: '',
									pendidikan_terakhir: '',
									kewarganegaraan: '',
									alamat: '',
									jenis_kelamin: '',
									status_perkawinan: '',
								},
								status:{
									...this.state.status,
									dataPenduduk: 'none',
									btnForm: true,
								},
								show: true,
								basicType: 'danger',
								basicTitle: 'Peringatan Catatan Warga',
								pesanAlert:
									'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
									catatan,
							})
							this.forceUpdate();
						}
					});
				}

				this.forceUpdate();
			});
		} else {
			if (sel === "nikTerlapor") {
				this.setState({
					dt_keluarga:{
						nik: '',
						kk: '',
						nama: '',
						agama: '',
						alamat: '',
						jenis_kelamin: '',
						pekerjaan: '',
						status_perkawinan: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						pendidikan_terakhir: '',
					},
					dataKeluarga: 'none',
					nikStatTerlapor:null,
				});
			}
			else if (sel === 'nik2') {
				this.setState({
					nik2Stat: null,
					status:{
						...this.state.status,
						dataPenduduk2: 'none',
					},
					dt:{
						...this.state.dt,
						nama2: '',
						tanggal_lahir2: '',
						tempat_lahir2: '',
						agama2: '',
						pekerjaan2: '',
						kewarganegaraan2: '',
						alamat2: '',
						jenis_kelamin2: '',
						status_perkawinan2: '',
						tempat_meninggal2: '',
						sebab_meninggal2: '',
					}
				})
			} else {
				this.setState({
					nikStat: null,
					status:{
						...this.state.status,
						dataPenduduk: 'none',
					},
					dt:{
						...this.state.dt,
						nama: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						agama: '',
						pekerjaan: '',
						pendidikan_terakhir: '',
						kewarganegaraan: '',
						alamat: '',
						jenis_kelamin: '',
						status_perkawinan: '',
					}
				})
			}
			this.forceUpdate();
		}
	};

	tambahAw = (e) => {
		if (this.state.nik3Stat && this.state.shdk_aw) {
			let detail = this.state.detail;
			if (this.props.data.results.data_kk === null) {
				Get('temp/penduduk/find', this.state.nik3Stat.value, (data) => {
					if (data.results) {
						detail.push({
							nik: this.state.nik3Stat.value,
							nama: data.results.nama,
							jenis_kelamin: data.results.jk,
							tanggal_lahir: data.results.tanggal_lahir,
							tempat_lahir: data.results.tempat_lahir,
							pekerjaan: data.results.data_pekerjaan.nama,
							pendidikan: data.results.data_pendidikan_terakhir.nama,
							shdk: this.state.shdk_aw.value,
							keterangan: ''
						});
						// this.setState({ detail, nik3Stat: null });
						this.setState({ nik3Stat: null, shdk_aw: null });
						aw.shdk = '';
						// this.state.shdk_aw = null;
						document.getElementById('shdk').value = '';
					}
				});
			} else {
				Get('penduduk/find', this.state.nik3Stat.value, (data) => {
					if (data.results) {
						detail.push({
							nik: this.state.nik3Stat.value,
							nama: data.results.nama,
							jenis_kelamin: data.results.jk,
							tanggal_lahir: data.results.tanggal_lahir,
							tempat_lahir: data.results.tempat_lahir,
							pekerjaan: data.results.data_pekerjaan.nama,
							pendidikan: data.results.data_pendidikan_terakhir.nama,
							shdk: this.state.shdk_aw.value,
							keterangan: ''
						});
						// this.setState({ detail, nik3Stat: null });
						this.setState({ nik3Stat: null, shdk_aw: null });
						aw.shdk = '';
						document.getElementById('shdk').value = '';
					}
				});
			}
		} else {
			this.props.getAntrian('Surat Keterangan Tidak Mampu', 'cekDataPengikut');
		}

		// let detail = this.state.detail;
		// let shdknya = this.state.shdk_aw.value;

		// // shdk belum ditambahkan
		// if (typeof shdknya == 'undefined' || typeof shdknya == null) {
		// 	this.props.isComplete(false);
		// } else if (shdknya == '') {
		// 	this.props.isComplete(false);
		// } else if (shdknya == null) {
		// 	this.props.isComplete(false);
		// } else if (this.state.nik3Stat != null) {
		// 	// nik pengikut sudah dipilih
		// 	if (this.props.data.results.data_kk == null) {
		// 		Get('temp/penduduk/find', this.state.nik3Stat.value, (data) => {
		// 			if (data.results) {
		// 				detail.push({
		// 					nik: this.state.nik3Stat.value,
		// 					nama: data.results.nama,
		// 					jenis_kelamin: data.results.jk,
		// 					tanggal_lahir: data.results.tanggal_lahir,
		// 					tempat_lahir: data.results.tempat_lahir,
		// 					pekerjaan: data.results.data_pekerjaan.nama,
		// 					pendidikan: data.results.data_pendidikan_terakhir.nama,
		// 					shdk: this.state.shdk_aw.value,
		// 					keterangan: ''
		// 				});
		// 				// this.setState({ detail, nik3Stat: null });
		// 				this.setState({ nik3Stat: null });
		// 				aw.shdk = '';
		// 				this.state.shdk_aw = null;
		// 				document.getElementById('shdk').value = '';
		// 			}
		// 		});
		// 	} else {
		// 		Get('penduduk/find', this.state.nik3Stat.value, (data) => {
		// 			if (data.results) {
		// 				detail.push({
		// 					nik: this.state.nik3Stat.value,
		// 					nama: data.results.nama,
		// 					jenis_kelamin: data.results.jk,
		// 					tanggal_lahir: data.results.tanggal_lahir,
		// 					tempat_lahir: data.results.tempat_lahir,
		// 					pekerjaan: data.results.data_pekerjaan.nama,
		// 					pendidikan: data.results.data_pendidikan_terakhir.nama,
		// 					shdk: this.state.shdk_aw.value,
		// 					keterangan: ''
		// 				});
		// 				// this.setState({ detail, nik3Stat: null });
		// 				this.setState({ nik3Stat: null });
		// 				aw.shdk = '';
		// 				this.state.shdk_aw = null;
		// 				document.getElementById('shdk').value = '';
		// 			}
		// 		});
		// 	}
		// } else {
		// 	this.props.isComplete(false);
		// }
	};

	simpan = (e) => {
		e.preventDefault();
		// //console.log(this.props.data.results);

		if (this.state.nikStatTerlapor) {
			// let alamat = '';
			// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			// 	alamat =
			// 		' RT. ' +
			// 		this.props.data.results.data_kk.data_rt.nama +
			// 		'/RW. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			// 		', Kec. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			// } else {
			// 	// alamat =
			// 	// 	' RT. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.nama +
			// 	// 	'/RW. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	// 	', ' +
			// 	// 	JENIS_DUSUN +
			// 	// 	' ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	// 	', Kec. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

			// 		if (this.props.data.results.data_kk.id_rt === '0') {
			// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
			// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			// 		} else {
			// 			alamat =
			// 			" RT. " + 
			// 			this.props.data.results.data_kk.data_rt.nama +
			// 			"/RW. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 			", " + JENIS_DUSUN + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 			", " + JENIS_DESA + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 			", Kec. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.nama +
			// 			", " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.data_kabkota.nama; 
			// 		}
			// }

			let addSuratKeteranganTidakMampu = {
				id_jenis: this.props.id_jenis,
				nik_pelapor: this.props.data.results.id,
				nik: this.state.dt_keluarga.nik,
				kk: this.state.dt_keluarga.kk,
				nama_pemohon: this.state.dt_keluarga.nama,
				tempat_lahir: this.state.dt_keluarga.tempat_lahir,
				tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
				pekerjaan: this.state.dt_keluarga.pekerjaan,
				agama: this.state.dt_keluarga.agama,
				status_perkawinan: this.state.dt_keluarga.status_perkawinan,
				alamat: this.state.dt_keluarga.alamat,
				jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
				pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,

				kewarganegaraan: 'Indonesia',
				tujuan_pembuatan: '-',
				detail: this.state.detail
			};

			// let psn = '';
			let resstat = 201;
			let metode = 'create';
			//console.log(addSuratKeteranganTidakMampu)

			Post('surat-keterangan/tidak-mampu/' + metode, null, addSuratKeteranganTidakMampu, (res) => {
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};

				if (res.status === resstat) {
					Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
						//console.log("Hasil Update Status")
						//console.log(res2)
						if (res2.code === 201) {
							//console.log("Berhasil Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Keterangan Tidak Mampu', 'berhasil', false, dataAntrian);
						}
						else{
							//console.log("Gagal Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Keterangan Tidak Mampu', 'gagal');
						}
					});
					// this.props.getAntrian('Surat Keterangan Tidak Mampu', 'berhasil', false, dataAntrian);
				} else {
					this.props.getAntrian('Surat Keterangan Tidak Mampu', 'gagal');
				}
				// reset cache
				document.getElementById('shdk').value = '';
				this.setState({ detail: [], nik3Stat: null });
			});
		} else {
			this.props.getAntrian('Surat Keterangan Tidak Mampu', 'gagal', true);
		}
	};

	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });

		if (this.props.data.results.data_kk == null) {
			let frmNik3 = [];
			this.props.data.results.data_kk_pend_temp.data_keluarga.forEach((dt) => {
				frmNik3.push({ value: dt.id, label: dt.id });
			});
			this.setState({
				frmNik3: frmNik3,
			})
			this.forceUpdate();
			// //console.log("KOSONG BOS")
			// //console.log(this.state.frmNik3)
		} else {
			// Get list anggota keluarga from parent
			if (this.props.anggotaKeluarga) {
				this.setState({ frmNik3: this.props.anggotaKeluarga })
			}
			// Get('penduduk/kk/' + this.props.data.results.id_kk, null, (data) => {
			// 	let frmNik3 = [];
				
			// 	if(data.results){
			// 		data.results.forEach((dt) => {
			// 			frmNik3.push({ value: dt.id, label: dt.id });
			// 		});

			// 		this.setState({
			// 			frmNik3: frmNik3,
			// 		})
			// 		this.forceUpdate();
			// 	}
			// });
		}
	}

	// ====================keyboard=============================

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}

		this.forceUpdate();
	};

	onChange = (input) => {
		this.setState({ input });
		//console.log('Input changed', input);
	};

	onKeyPress = (button) => {
		//console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	onInputChanged = (data) => {
		this.setState({ input: data });
	};

	onInputSubmitted = (data) => {
		//console.log('Input submitted:', data);
	};

	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		//console.log('Input changed', inputObj);
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	//====================end keyboard========================

	render() {
		let no = 0;
		const tidak_mampu = this.state.detail.map((dt, i) => {
			no++;
			let jk = 'L';
			if (dt.jenis_kelamin === 'Perempuan') {
				jk = 'P';
			}
			return (
				<tr key={i}>
					<td>{no}</td>
					<td>{dt.nama}</td>
					<td>{jk}</td>
					<td>
						{dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
					</td>
					<td>{dt.pekerjaan}</td>
					<td>{dt.pendidikan}</td>
					<td>{dt.shdk}</td>
					<td>
						<Button
							size="xs"
							onClick={() => {
								this.state.detail.splice(i, 1);
								this.forceUpdate();
							}}
							color="danger"
							className="btn-icon"
						>
							<i className="fa fa-trash" />
						</Button>
					</td>
				</tr>
			);
		});

		const shdk = [
			{ value: 'Suami', label: 'Suami' },
			{ value: 'Isteri', label: 'Isteri' },
			{ value: 'Orang Tua', label: 'Orang Tua' },
			{ value: 'Anak', label: 'Anak' },
			{ value: 'Kakak', label: 'Kakak' },
			{ value: 'Adik', label: 'Adik' },
			{ value: 'Mertua', label: 'Mertua' },
			{ value: 'Menantu', label: 'Menantu' },
			{ value: 'Cucu', label: 'Cucu' },
			{ value: 'Pembantu', label: 'Pembantu' },
			{ value: 'Famili Lain', label: 'Famili Lain' }
		];

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title" align="center">
								Surat Keterangan Tidak Mampu
							</h5>
						</div>

						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder=""
								// defaultValue={this.state.dt.uuid}
							/>

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">No Antrian Anda</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_antrian_anda"
										value="12"
										disabled
										type="text"
										placeholder="No Antrian Anda"
										//   value={this.state.dt.nama_pemohon}
									/>
								</Col>
							</FormGroup> */}

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">NIK</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nik"
										disabled
										type="text"
										placeholder="NIK"
										value={this.props.data.results.id}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">Nama Lengkap</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										disabled
										type="text"
										placeholder=" Nama Lengkap"
										value={this.props.data.results.nama}
									/>
								</Col>
							</FormGroup> */}
							<FormGroup className="row">
								<Label
								className="col-sm-3 col-form-label"
								htmlFor="nik"
								>
								NIK Terlapor
								</Label>
								<Col sm="9">
								<div data-testid="selectNIKTerlapor">
									<Select
									inputId="nikTerlapor"
									classNamePrefix="select"
									onChange={e => {
										this.getPenduduk(e, "nikTerlapor");
									}}
									defaultValue={this.state.nikStatTerlapor}
									value={this.state.nikStatTerlapor}
									name="NIK"
									options={this.state.frmNikKeluarga}
									placeholder="Pilih NIK Terlapor"
									onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
									isClearable
									/>
								</div>
								</Col>
							</FormGroup>

							<div style={{ display: this.state.dataKeluarga }}>
								<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Nama Terlapor
								</Label>
								<Col sm="9">
									<input
									className="form-control"
									id="nama"
									type="text"
									placeholder="Nama Terlapor"
									value={this.state.dt_keluarga.nama}
									readOnly

									style={{ backgroundColor:"#c9d1d8" }}
									disabled
									/>
								</Col>
								</FormGroup>
								<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
									className="form-control"
									id="tempat_lahir"
									type="text"
									placeholder="Tempat Lahir"
									value={this.state.dt_keluarga.tempat_lahir}
									readOnly

									style={{ backgroundColor:"#c9d1d8" }}
									disabled
									/>
								</Col>
								</FormGroup>
								<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
									className="form-control"
									id="tanggal_lahir"
									type="text"
									placeholder="Tanggal Lahir"
									value={this.state.dt_keluarga.tanggal_lahir}
									readOnly

									style={{ backgroundColor:"#c9d1d8" }}
									disabled
									/>
								</Col>
								</FormGroup>
							</div>

							<div style={{ display: 'none' }}>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_lahir"
											type="text"
											placeholder="Tempat Lahir"
											value={this.props.data.results.tempat_lahir}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tanggal_lahir"
											type="text"
											placeholder="Tanggal Lahir"
											value={this.props.data.results.tanggal_lahir}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										KK
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="kk"
											type="text"
											placeholder="Nomor KK"
											value={this.props.data.results.id_kk}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Jenis Kelamin
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="jenis_kelamin"
											type="text"
											placeholder="Jenis Kelamin"
											value={this.props.data.results.jk}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pekerjaan
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="pekerjaan"
											type="text"
											placeholder="Pekerjaan"
											value={this.props.data.results.pekerjaan}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pendidikan Terakhir
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="pendidikan_terakhir"
											type="text"
											placeholder="Pendidikan Terakhir"
											value={this.props.data.results.pendidikan_terakhir}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kewarganegaraan
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="kewarganegaraan"
											type="text"
											placeholder="Pekerjaan"
											value="Indonesia"
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Status Perkawinan
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="status_perkawinan"
											type="text"
											placeholder="Status Perkawinan"
											value={this.props.data.results.status_perkawinan}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Agama
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="agama"
											type="text"
											placeholder="Agama"
											value={this.props.data.results.agama}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Alamat
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="alamat"
											type="text"
											placeholder="Alamat"
											value={this.props.data.results.alamat}
											readOnly
										/>
									</Col>
								</FormGroup>
							</div>

							<FormGroup className="row">
								<Label className="col-sm-12 col-form-label">Daftar Keluarga Yang Ikut</Label>
								<Col sm="5">
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'nik3');
										}}
										// defaultValue={this.state.nik3Stat}

										value={this.state.nik3Stat}
										name="NIK3"
										options={this.state.frmNik3}
										placeholder="Pilih NIK Tidak Mampu"
										onInputChange={(e) => this.handleInputChangeNIK(e, 'nik3')}
										isClearable
									/>
								</Col>
								<Col sm="4">
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'shdk_aw');
										}}
										value={this.state.shdk_aw}
										id="shdk"
										options={shdk}
										placeholder="Hubungan Keluarga"
									/>
								</Col>
								<Col sm="3">
									<button type="button" className="btn btn-primary" onClick={this.tambahAw}>
										Tambah
									</button>
								</Col>
								<Col sm="12">
									<table className="table table-bordered table-stripes table-hovered">
										<thead>
											<tr>
												<th>No</th>
												<th>Nama</th>
												<th>JK</th>
												<th>Tempat/Tanggal Lahir</th>
												<th>Pekerjaan</th>
												<th>Pendidikan</th>
												<th>Hubungan Keluarga</th>
												<th>
													<i className="fa fa-trash" />
												</th>
											</tr>
										</thead>
										<tbody>{tidak_mampu}</tbody>
									</table>
								</Col>
							</FormGroup>
						</div>

						<div
							className="modal-footer"
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
								// marginBottom: '350px'
							}}
						>
							<button
								type="button"
								className="btn btn-warning"
								onClick={() => this.onCloseModal('Basic')}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-primary">
								Buat Surat
							</button>
						</div>
					</Form>

					{/* <div style={{ position: 'fixed', left: 0, bottom: 0, width: '100%', zIndex: 4 }}>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/tidak-mampu.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Tidak Mampu
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import {
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';

function EditKeramaian({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				styles={{ modal: { width: '80%' } }}
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<Form
					className="theme-form"
					onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}
				>
					<div className="modal-header">
						<h5 className="modal-title">Ubah {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
						<input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>

						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								NIK{' '}
							</Label>
							<Col sm="9">
								<Select
									isDisabled={detailSuratProps.status.select}
									classNamePrefix="select"
									onChange={(e) => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'nik'
										);
									}}
									defaultValue={detailSuratProps.detailSurat.pendStat}
									value={detailSuratProps.detailSurat.pendStat}
									name="penduduk"
									options={detailSuratProps.dataNik.frmNik}
									placeholder="Pilih NIK"
									onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
									isClearable
								/>
							</Col>
						</FormGroup>

						<div style={{ display: detailSuratProps.status.showBio === false ? 'none' : '' }}>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="nik"
								type="hidden"
								readOnly
								placeholder="NIK"
								value={detailSuratProps.detailSurat.nik}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    KK
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="kk"
								type="hidden"
								readOnly
								placeholder="kk"
								value={detailSuratProps.detailSurat.kk}
							/>
							{/* </Col>
                </FormGroup> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Pemohon
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										readOnly
										type="text"
										placeholder="nama_pemohon"
										value={detailSuratProps.detailSurat.nama_pemohon}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										readOnly
										id="tempat_lahir"
										type="text"
										placeholder="Tempat lahir"
										value={detailSuratProps.detailSurat.tempat_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir"
										readOnly
										type="text"
										placeholder="Tanggal Lahir"
										value={detailSuratProps.detailSurat.tanggal_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="jenis_kelamin"
								readOnly
								type="hidden"
								placeholder="jenis_kelamin"
								value={detailSuratProps.detailSurat.jenis_kelamin}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								readOnly
								id="pekerjaan"
								type="hidden"
								placeholder="Pekerjaan"
								value={detailSuratProps.detailSurat.pekerjaan}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="pendidikan_terakhir"
								readOnly
								type="hidden"
								placeholder="Pendidikan Terakhir"
								value={detailSuratProps.detailSurat.pendidikan_terakhir}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="agama"
								readOnly
								type="hidden"
								placeholder="Agama"
								value={detailSuratProps.detailSurat.agama}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="status_perkawinan"
								type="hidden"
								readOnly
								placeholder="Status Perkawinan"
								value={detailSuratProps.detailSurat.status_perkawinan}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="alamat"
								type="hidden"
								readOnly
								placeholder="Alamat"
								value={detailSuratProps.detailSurat.alamat}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="kewarganegaraan"
								type="hidden"
								readOnly={true}
								placeholder="Kewarganegaraan"
								value={detailSuratProps.detailSurat.kewarganegaraan}
							/>
							{/* </Col>
                </FormGroup> */}
						</div>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tujuan pembuatan
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tujuan_pembuatan"
									type="text"
									required
									placeholder="Tujuan pembuatan"
									defaultValue={detailSuratProps.detailSurat.tujuan_pembuatan}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Waktu Acara
							</Label>
							<Col sm="4">
								{/* <Datepicker
                  id="tgl_order"
                  todayButton={"Today"}
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  selected={detailSuratProps.detailSurat.tanggal_mulai}
                  onChange={e => detailSuratProps.gantiTgl(e, 'tanggal_mulai')}
                  className="form-control"
                /> */}
								<Datetime
									locale="id-ID"
									timeFormat="HH:mm"
									dateFormat="DD/MM/yyyy"
									input={true}
									closeOnTab={true}
									initialValue={new Date(detailSuratProps.detailSurat.tanggal_mulai)}
									onChange={(e) => {
										detailSuratProps.gantiTgl(e, 'tanggal_mulai');
									}}
									inputProps={{
										required: true,
										placeholder: 'Waktu Mulai'
									}}
								/>
							</Col>
							<Label className="col-sm-1 col-form-label text-center" htmlFor="inputEmail3">
								s/d
							</Label>
							<Col sm="4">
								{/* <Datepicker
                  id="tgl_order"
                  todayButton={"Today"}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy HH:mm"
                  selected={detailSuratProps.detailSurat.tanggal_selesai}
                  onChange={e => detailSuratProps.gantiTgl(e, 'tanggal_selesai')}
                  className="form-control"
                /> */}
								<Datetime
									locale="id-ID"
									dateFormat="DD/MM/yyyy"
									timeFormat="HH:mm"
									input={true}
									closeOnTab={true}
									initialValue={
										detailSuratProps.detailSurat.tanggal_selesai != null ? (
											new Date(detailSuratProps.detailSurat.tanggal_selesai)
										) : (
											''
										)
									}
									onChange={(e) => {
										detailSuratProps.gantiTgl(e, 'tanggal_selesai');
									}}
									inputProps={{
										required: true,
										placeholder: 'Waktu Selesai'
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tempat
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat"
									type="text"
									required
									placeholder="Tempat"
									defaultValue={detailSuratProps.detailSurat.tempat}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Hiburan
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="hiburan"
									type="text"
									required
									placeholder="Hiburan"
									defaultValue={detailSuratProps.detailSurat.hiburan}
								/>
							</Col>
						</FormGroup>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-warning"
							// disabled={detailSuratProps.status.btnForm}
							onClick={detailSuratProps.tutupForm}
						>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
							// disabled={detailSuratProps.status.btnForm}
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
		</div>
	);
}

export default EditKeramaian;

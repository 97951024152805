import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import {
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';

function EditKeteranganJalan({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				styles={{ modal: { width: '80%' } }}
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<Form
					className="theme-form"
					onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}
				>
					<div className="modal-header">
						<h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
						<input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>

						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								NIK{' '}
							</Label>
							<Col sm="9">
								<Select
									isDisabled={detailSuratProps.status.select}
									classNamePrefix="select"
									onChange={(e) => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'pemohon'
										);
									}}
									defaultValue={detailSuratProps.detailSurat.pendStat}
									value={detailSuratProps.detailSurat.pendStat}
									name="penduduk"
									options={detailSuratProps.dataNik.frmNik}
									placeholder="Pilih NIK"
									onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
									required={true}
									isClearable
								/>
							</Col>
						</FormGroup>

						<div style={{ display: detailSuratProps.status.showBio === false ? 'none' : '' }}>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="nik"
								type="hidden"
								readOnly
								placeholder="NIK"
								value={detailSuratProps.detailSurat.nik}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    KK
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="kk"
								type="hidden"
								readOnly
								placeholder="kk"
								value={detailSuratProps.detailSurat.kk}
							/>
							{/* </Col>
                </FormGroup> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Pemohon
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										readOnly
										type="text"
										placeholder="nama_pemohon"
										value={detailSuratProps.detailSurat.nama_pemohon}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										readOnly
										id="tempat_lahir"
										type="text"
										placeholder="Tempat lahir"
										value={detailSuratProps.detailSurat.tempat_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir"
										readOnly
										type="text"
										placeholder="Tanggal Lahir"
										value={detailSuratProps.detailSurat.tanggal_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="jenis_kelamin"
								readOnly
								type="hidden"
								placeholder="jenis_kelamin"
								value={detailSuratProps.detailSurat.jenis_kelamin}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								readOnly
								id="pekerjaan"
								type="hidden"
								placeholder="Pekerjaan"
								value={detailSuratProps.detailSurat.pekerjaan}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="pendidikan_terakhir"
								readOnly
								type="hidden"
								placeholder="Pendidikan Terakhir"
								value={detailSuratProps.detailSurat.pendidikan_terakhir}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="agama"
								readOnly
								type="hidden"
								placeholder="Agama"
								value={detailSuratProps.detailSurat.agama}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="status_perkawinan"
								type="hidden"
								readOnly
								placeholder="Status Perkawinan"
								value={detailSuratProps.detailSurat.status_perkawinan}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="alamat"
								type="hidden"
								readOnly
								placeholder="Alamat"
								value={detailSuratProps.detailSurat.alamat}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="kewarganegaraan"
								type="hidden"
								readOnly={true}
								placeholder="Kewarganegaraan"
								value={detailSuratProps.detailSurat.kewarganegaraan}
							/>
							{/* </Col>
                </FormGroup> */}
						</div>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Maksud dan Tujuan
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tujuan_pembuatan"
									type="text"
									required
									placeholder=" Maksud dan Tujuan"
									defaultValue={detailSuratProps.detailSurat.tujuan_pembuatan}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tujuan Perjalanan
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tujuan_perjalanan"
									type="text"
									required
									placeholder="Tujuan Perjalanan"
									defaultValue={detailSuratProps.detailSurat.tujuan_perjalanan}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Rencana Berangkat
							</Label>
							<Col sm="9">
								{/* <Datepicker
                  id="rencana_berangkat"
                  todayButton={"Today"}
                  dateFormat="dd/MM/yyyy"
                  selected={detailSuratProps.detailSurat.rencana_berangkat}
                  onChange={e => detailSuratProps.gantiTgl(e, "rencana_berangkat")}
                  className="form-control"
                /> */}
								<Datetime
									id="rencana_berangkat"
									locale="id-ID"
									dateFormat="DD/MM/yyyy"
									timeFormat={false}
									input={true}
									closeOnTab={true}
									closeOnSelect={true}
									initialValue={new Date(detailSuratProps.detailSurat.rencana_berangkat)}
									onChange={(e) => {
										detailSuratProps.gantiTgl(e, 'rencana_berangkat');
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tranportasi
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="transportasi"
									type="text"
									required
									placeholder="Tranportasi"
									defaultValue={detailSuratProps.detailSurat.transportasi}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								No.pol
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="no_pol"
									type="text"
									required
									placeholder="No.pol"
									defaultValue={detailSuratProps.detailSurat.no_pol}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Nama Sopir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_supir"
									type="text"
									required
									placeholder="Nama Supir"
									defaultValue={detailSuratProps.detailSurat.nama_supir}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tempat Lahir Supir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir_supir"
									type="text"
									required
									placeholder="Tempat Lahir Supir"
									defaultValue={detailSuratProps.detailSurat.tempat_lahir_supir}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tanggal Lahir Supir
							</Label>
							<Col sm="9">
								{/* <Datepicker
                  id="tanggal_lahir_supir"
                  todayButton={"Today"}
                  dateFormat="dd/MM/yyyy"
                  selected={detailSuratProps.detailSurat.tanggal_lahir_supir}
                  onChange={e => detailSuratProps.gantiTgl(e, "tanggal_lahir_supir")}
                  className="form-control"
                /> */}
								<Datetime
									id="tanggal_lahir_supir"
									locale="id-ID"
									timeFormat={false}
									dateFormat="DD/MM/yyyy"
									input={true}
									closeOnTab={true}
									closeOnSelect={true}
									initialValue={new Date(detailSuratProps.detailSurat.tanggal_lahir_supir)}
									onChange={(e) => {
										detailSuratProps.gantiTgl(e, 'tanggal_lahir_supir');
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Pengikut
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="pengikut"
									type="text"
									required
									placeholder="Pengikut"
									defaultValue={detailSuratProps.detailSurat.pengikut}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Barang bawaan
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="barang"
									type="text"
									required
									placeholder="Barang bawaaan"
									defaultValue={detailSuratProps.detailSurat.barang}
								/>
							</Col>
						</FormGroup>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-warning"
							// disabled={detailSuratProps.status.btnForm}
							onClick={detailSuratProps.tutupForm}
						>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
							// disabled={detailSuratProps.status.btnForm}
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
		</div>
	);
}

export default EditKeteranganJalan;

import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Form } from 'reactstrap';
import '../assets/css/kiosk.css';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import { Get, Post } from '../function/Koneksi';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import Datetime from 'react-datetime';
// import Keyboard from 'react-simple-keyboard';

const frmDef = {
	uuid: '',
	id_jenis: '',
	nomor: '',
	tanggal_surat: new Date(),
	nik: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jk: '',
	pekerjaan: '',
	agama: '',
	alamat: '',
	status_perkawinan: '',
	waktu_meninggal: new Date(),
	tempat_meninggal: '',
	sebab_meninggal: '',
	tempat_makam: '',
	nik_pelapor: '',
	nama_pelapor: '',
	tempat_lahir_pelapor: '',
	tanggal_lahir_pelapor: '',
	jk_pelapor: '',
	pekerjaan_pelapor: '',
	agama_pelapor: '',
	alamat_pelapor: '',
	hubungan_pelapor: '',
	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: '',
	status_ttd2: '',
	jabatan_ttd2: '',
	jabatan_pengganti_ttd2: '',
	nama_ttd2: '',
	nip_ttd2: ''
};

export default class SuratKematian extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				dataPenduduk: 'none',
				dataPenduduk2: 'none'
			},
			// keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'block',
			focus: false,
			hasFocus: false,

			dt: frmDef,
			Basic: false,
			frmJam: [],
			jamStat: [],
			frmPkr: [],
			pkrStat: [],
			frmAgm: [],
			agmStat: [],
			// Data almarhum
			dt_almarhum: {
				nik: '',
				nama: '',
				jk: '',
				tempat_lahir: '',
				tanggal_lahir: new Date(),
				status_perkawinan: '',
				pekerjaan: '',
				agama: '',
				alamat: ''
			},

			shdk1: null,
			// NIK
			frmNik: [],
			nikStat: null,
			frmNik2: [],
			nik2Stat: null,
			// Alert
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false,
			tanggal_surat: new Date(),
			tanggal_lahir: new Date(),
			waktu_meninggal: null,
			tempat_meninggal: '',
			sebab_meninggal: '',
			tempat_pemakaman: '',

			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			// input: inputObj
			input: updatedInputObj
		});
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		//console.log(inputVal);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard !== 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);

		//console.log(this.state.input);
	};

	onKeyPress = (button) => {
		// //console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		this.forceUpdate();
	};

	onOpenModal = (key) => {
		//console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ 
			[key]: false,
			shdk1: null,
			waktu_meninggal: null,
			nikStat: null,
			dt_almarhum:{
				...this.state.dt_almarhum,
				nama: '',
				jk: '',
			},
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
	};

	handleClick = (event) => {
		alert(event);
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_surat':
				this.setState({ tanggal_surat: date });
				break;
			case 'waktu_meninggal':
				this.setState({ waktu_meninggal: date });
				break;
			default:
				// no default
				break;
			// case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jam':
					this.setState({ 
						jamStat: e, 
						dt:{
							...this.state.dt,
							jam: e.value,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan: e.label,
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama: e.label,
						}
					});
					break;
				case 'nik':
					this.setState({ 
						nikStat: e, 
						dt:{
							...this.state.dt,
							nik: e.value,
						}
					});
					break;
				case 'nik2':
					this.setState({ 
						nik2Stat: e, 
						dt:{
							...this.state.dt,
							nik_pelapor: e.value,
						}
					});
					break;
				case 'shdk1':
					this.setState({ 
						shdk1: e, 
						dt:{
							...this.state.dt,
							shdk1: e.value,
						}
					});
					break;
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'jam':
					this.setState({ 
						jamStat: e, 
						dt:{
							...this.state.dt,
							jam: 0,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan: '',
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama: '',
						}
					});
					break;
				case 'nik':
					this.setState({ 
						nikStat: e, 
						dt:{
							...this.state.dt,
							nik: 0,
						}
					});
					break;
				case 'nik2':
					this.setState({ 
						nik2Stat: e,
						dt:{
							...this.state.dt,
							nik_pelapor: 0,
						}
					});
					break;
				case 'shdk1':
					this.setState({ 
						shdk1: e, 
						dt:{
							...this.state.dt,
							shdk1: 0,
						}
					});
					break;
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

	getPenduduk = (e, sel, jns) => {
		// this.forceUpdate();
		if (e) {
			this.changeSelectValue(e, sel);
			Get('penduduk/find', e.value, (data) => {
				if (typeof data.results !== 'undefined') {
					// const data_rt = data.results.data_kk.data_rt;
					// const data_rw = data_rt.data_rw;
					// const data_dusun = data_rw.data_dusun;
					// const data_desa = data_dusun.data_keldes;
					// const data_kecamatan = data_desa.data_kecamatan;
					// const data_kabkota = data_kecamatan.data_kabkota;
					let alamat =
						' RT. ' +
						data.results.data_kk.data_rt.nama +
						'/RW. ' +
						data.results.data_kk.data_rt.data_rw.nama +
						', Dusun ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						', Kec. ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

					if (jns === 'pelapor') {
						this.setState({
							tanggal_lahir_pelapor: data.results.tanggal_lahir,
							dt:{
								...this.state.dt,
								nama_pelapor: data.results.nama,
								tanggal_lahir_pelapor: new Date(data.results.tanggal_lahir).toLocaleDateString(
									'id-ID'
								),
								tempat_lahir_pelapor: data.results.tempat_lahir,
								jk_pelapor: data.results.jk,
								status_perkawinan_pelapor: data.results.status_perkawinan,
								agama_pelapor: data.results.data_agama.nama,
								pekerjaan_pelapor: data.results.data_pekerjaan.nama,
								alamat_pelapor: alamat,
							},
							status:{
								...this.state.status,
								dataPenduduk2: 'block',
							}
						})
					}
					else if (sel === "nikTerlapor") {
						this.setState({
							dt_keluarga:{
								nik: e.value,
								kk: data.results.id_kk,
								nama: data.results.nama,
								agama: data.results.data_agama.nama,
								alamat: alamat,
								jenis_kelamin: data.results.jk,
								pekerjaan: data.results.data_pekerjaan.nama,
								status_perkawinan: data.results.status_perkawinan,
								tanggal_lahir: data.results.tanggal_lahir,
								tempat_lahir: data.results.tempat_lahir,
								pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
							},
							dataKeluarga: 'block',
						})
					} 
					else {
						Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
							let pctt = 0;
							let catatan = '';
							if (dpddk.results.data) {
								dpddk.results.data.forEach((dtp) => {
									if (dtp.status === 0 && dtp.penalti === 1) {
										pctt = 1;
										catatan = dtp.catatan;
									}
								});
							}
							if (pctt === 0) {
								this.setState({
									tanggal_lahir: data.results.tanggal_lahir,
									dt_almarhum:{
										...this.state.dt_almarhum,
										nama: data.results.nama,
										jk: data.results.jk,
										tanggal_lahir: new Date(
											data.results.tanggal_lahir
										).toLocaleDateString('id-ID'),
										tempat_lahir: data.results.tempat_lahir,
										status_perkawinan: data.results.status_perkawinan,
										pekerjaan: data.results.data_pekerjaan.nama,
										agama: data.results.data_agama.nama,
										alamat: alamat,
									},
									dt:{
										...this.state.dt,
										nama: data.results.nama,
									}
								})

								// this.state.dt_almarhum.jk = data.results.jk;
								// this.state.status.dataPenduduk = 'block';
								this.forceUpdate();
							} else {
								this.setState({
									nikStat: null,
									frmNik: [],
									dt:{
										...this.state.dt,
										nama: '',
										tanggal_lahir: '',
										tempat_lahir: '',
										agama: '',
										pekerjaan: '',
										alamat: '',
										jk: '',
										status_perkawinan: '',
									},
									status:{
										...this.state.status,
										dataPenduduk: 'none',
										btnForm: true,
									},
									show: true,
									basicType: 'danger',
									basicTitle: 'Peringatan Catatan Warga',
									pesanAlert:
										'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
										catatan
								});

								this.forceUpdate();
							}
						});
					}
				}

				// if (this.state.nikStat !== null && this.state.nik2Stat !== null) {
				// 	this.state.status.btnForm = false;
				// }
				this.forceUpdate();
			});
		} else {
			if (jns === 'pelapor') {
				this.setState({
					nik2Stat: [],
					dt:{
						...this.state.dt,
						nama_pelapor: '',
						tanggal_lahir_pelapor: '',
						tempat_lahir_pelapor: '',
						agama_pelapor: '',
						pekerjaan_pelapor: '',
						alamat_pelapor: '',
						jk_pelapor: '',
						status_perkawinan_pelapor: '',
					},
					status:{
						...this.state.status,
						dataPenduduk2: 'none',
					}
				})
			} 
			else if(sel === "nikTerlapor"){
				this.setState({
					dt_keluarga:{
						nik: '',
						kk: '',
						nama: '',
						agama: '',
						alamat: '',
						jenis_kelamin: '',
						pekerjaan: '',
						status_perkawinan: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						pendidikan_terakhir: '',
					},
					dataKeluarga: 'none',
					nikStatTerlapor:null,
				});
			}
			else {
				this.setState({
					nikStat: null,
					dt_almarhum:{
						...this.state.dt_almarhum,
						nama: '',
						jk: '',
					},
					dt:{
						...this.state.dt,
						nama: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						agama: '',
						pekerjaan: '',
						alamat: '',
						jk: '',
						status_perkawinan: '',
					},
					status:{
						dataPenduduk: 'none',
					}
				})


			}
			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				}
			})

			this.forceUpdate();
		}
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === 'nik') {
				let nikStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.forEach((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik, nikStat });
				});
			} 
			else if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
			else {
				let nik2Stat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik2 = [];
					datanik.data.results.data.forEach((dt) => {
						frmNik2.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik2, nik2Stat });
				});
			}
		}
	};

	simpan = (e) => {
		e.preventDefault();

		
		if (this.state.nikStatTerlapor) {
			const nikAlmarhum = document.getElementById("nikStat").value;

			if (nikAlmarhum.trim() === '') {
				this.props.getAntrian('Surat Keterangan Kematian', 'gagal', true);
			} 
			else if (nikAlmarhum.length !== 16) {
				this.props.getAntrian('Surat Keterangan Kematian', 'cek digit nik');
			}
			else if (this.state.shdk1 && this.state.dt_almarhum.nik) {
				// let alamat = '';
				// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
				// 	alamat =
				// 		' RT. ' +
				// 		this.props.data.results.data_kk.data_rt.nama +
				// 		'/RW. ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 		', ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
				// 		', Kec. ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
				// 		', ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
				// } else {
				// 		if (this.props.data.results.data_kk.id_rt === '0') {
				// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
				// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
				// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
				// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				// 		} else {
				// 			alamat =
				// 			" RT. " + 
				// 			this.props.data.results.data_kk.data_rt.nama +
				// 			"/RW. " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 			", " + JENIS_DUSUN + " " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				// 			", " + JENIS_DESA + " " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				// 			", Kec. " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				// 				.data_kecamatan.nama +
				// 			", " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				// 				.data_kecamatan.data_kabkota.nama; 
				// 		}
				// }
				
				let hub_pelapor = this.state.shdk1.value;
				let addSuratKeteranganKematian = {
					id_jenis: this.props.id_jenis,

					nik: this.state.dt_almarhum.nik,
					nama: this.state.dt_almarhum.nama,
					tempat_lahir: this.state.dt_almarhum.tempat_lahir,
					tanggal_lahir: this.state.dt_almarhum.tanggal_lahir,
					jenis_kelamin: this.state.dt_almarhum.jk,
					status_perkawinan: this.state.dt_almarhum.status_perkawinan,
					pekerjaan: this.state.dt_almarhum.pekerjaan,
					agama: this.state.dt_almarhum.agama,
					alamat: this.state.dt_almarhum.alamat,

					waktu_meninggal:
						new Date(this.state.waktu_meninggal).toISOString().slice(0, 10) +
						' ' +
						new Date(this.state.waktu_meninggal).toLocaleTimeString('id-ID'),

					tempat_meninggal: document.getElementById('tempat_meninggal').value,
					sebab_meninggal: document.getElementById('sebab_meninggal').value,
					tempat_makam: document.getElementById('tempat_meninggal').value,

					nik_pelapor: this.state.dt_keluarga.nik,
					kk: this.state.dt_keluarga.kk,
					nama_pelapor: this.state.dt_keluarga.nama,
					tempat_lahir_pelapor: this.state.dt_keluarga.tempat_lahir,
					tanggal_lahir_pelapor: this.state.dt_keluarga.tanggal_lahir,
					pekerjaan_pelapor: this.state.dt_keluarga.pekerjaan,
					agama_pelapor: this.state.dt_keluarga.agama,
					status_perkawinan_pelapor: this.state.dt_keluarga.status_perkawinan,
					alamat_pelapor: this.state.dt_keluarga.alamat,
					jenis_kelamin_pelapor: this.state.dt_keluarga.jenis_kelamin,
					pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,
					hubungan_pelapor: hub_pelapor,


					// nik_pelapor: this.props.data.results.id,
					// nama_pelapor: this.props.data.results.nama,
					// tempat_lahir_pelapor: this.props.data.results.tempat_lahir,
					// tanggal_lahir_pelapor: this.props.data.results.tanggal_lahir,
					// jenis_kelamin_pelapor: this.props.data.results.jk,
					// status_perkawinan_pelapor: this.props.data.results.status_perkawinan,
					// pekerjaan_pelapor: this.props.data.results.data_pekerjaan.nama,
					// agama_pelapor: this.props.data.results.data_agama.nama,
					// alamat_pelapor: alamat
				};

				// let psn = '';
				let resstat = 201;
				let metode = 'create';

				Post('surat-keterangan/kematian/' + metode, null, addSuratKeteranganKematian, (res) => {
					this.onCloseModal('Basic');

					let dataAntrian = {
						nama: this.props.data.results.nama,
						nik: this.props.data.results.id,
						keterangan: '',
						uuid_surat:res.data.results,
					};
		
					if (res.status === resstat) {
						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
							//console.log("Hasil Update Status")
							//console.log(res2)
							if (res2.code === 201) {
								//console.log("Berhasil Ganti Status Menjadi Selesai")
		
								this.props.getAntrian('Surat Keterangan Kematian', 'berhasil', false, dataAntrian);
							}
							else{
								//console.log("Gagal Ganti Status Menjadi Selesai")
		
								this.props.getAntrian('Surat Keterangan Kematian', 'gagal');
							}
						});
						// this.props.getAntrian('Surat Keterangan Kematian', 'berhasil', false, dataAntrian);
					} else {
						this.props.getAntrian('Surat Keterangan Kematian', 'gagal');
					}
					// this.componentDidMount();
					// cache
					document.getElementById('tempat_meninggal').value = '';
					document.getElementById('sebab_meninggal').value = '';
					document.getElementById('tempat_meninggal').value = '';
					// document.getElementById('hubungan_pelapor').value = '';

					this.setState({
						nikStat: null,
						waktu_meninggal: null,
						dt_almarhum:{
							...this.state.dt_almarhum,
							nama: '',
							jk: '',
							tempat_lahir: '',
							tanggal_lahir: new Date(),
							status_perkawinan: '',
							pekerjaan: '',
							agama: '',
							alamat: ''
						},
						input: {},
					})

					this.forceUpdate();
				});
			} else {
				this.props.getAntrian('Surat Keterangan Kematian', 'gagal', true);
			}
		} else {
			this.props.getAntrian('Surat Keterangan Kematian', 'gagal', true);
		}

	};

	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });

		// LIST PENDUDUK DEFAULT SEDANG TIDAK DIPERLUKAN
		// Get('penduduk', null, (data) => {
		// 	let frmNik = [];
		// 	if(data.results){
		// 		data.results.data.forEach((dt) => {
		// 			frmNik.push({ value: dt.id, label: dt.id });
		// 		});
		// 		this.setState({ frmNik });
		// 	}
		// });
	}

	handleChange1 = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
		this.forceUpdate();
		//console.log('Yg diinput');
		//console.log(this.state.nikStat);
	};

	cariNIK = (e, sel) => {
		e.preventDefault();

		let id_cari = '';
		if (sel === 'cari_nik1') {
			// id_cari = this.state.nikStat;
			id_cari = this.state.input.nikStat;
		} else if (sel === 'cari_nik2') {
			id_cari = this.state.nik2Stat;
		} else if (sel === 'cari_nik3') {
			id_cari = this.state.nik3Stat;
		}
		
		console.log("ID CARINYA")
		console.log(id_cari)
		if (id_cari.length !== 16) {
			this.forceUpdate();
			//console.log('Panjang NIK adalah 16 digit');
			this.props.getAntrian('Surat Keterangan Kematian', 'cek digit nik');
		} else {
			Get('penduduk/find', id_cari, (data) => {
				if (data.results) {
					if (typeof data.results !== 'undefined') {
						// const data_rt = data.results.data_kk.data_rt;
						// const data_rw = data_rt.data_rw;
						// const data_dusun = data_rw.data_dusun;
						// const data_desa = data_dusun.data_keldes;
						// const data_kecamatan = data_desa.data_kecamatan;
						// const data_kabkota = data_kecamatan.data_kabkota;
						let alamat =
							' RT. ' +
							data.results.data_kk.data_rt.nama +
							'/RW. ' +
							data.results.data_kk.data_rt.data_rw.nama +
							', Dusun ' +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							', ' +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							', Kec. ' +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
							', ' +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
								.nama;

						Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
							let pctt = 0;
							let catatan = '';
							if (dpddk.results.data) {
								dpddk.results.data.forEach((dtp) => {
									if (dtp.status === 0 && dtp.penalti === 1) {
										pctt = 1;
										catatan = dtp.catatan;
									}
								});
							}
							if (pctt === 0) {
								this.setState({
									tanggal_lahir: data.results.tanggal_lahir,
									nikStat: id_cari,
									dt_almarhum:{
										...this.state.dt_almarhum,
										nik: data.results.id,
										nama: data.results.nama,
										jk: data.results.jk,
										tanggal_lahir: new Date(data.results.tanggal_lahir),
										tempat_lahir: data.results.tempat_lahir,
										status_perkawinan: data.results.status_perkawinan,
										pekerjaan: data.results.data_pekerjaan.nama,
										agama: data.results.data_agama.nama,
										alamat: alamat,
									},
									dt:{
										...this.state.dt,
										nama: data.results.nama,
									}
								})

								// this.state.dt_almarhum.jk = data.results.jk;
								// this.state.status.dataPenduduk = 'block';
								this.forceUpdate();
							} else {
								this.setState({
									nikStat: null,
									frmNik: [],
									dt_almarhum:{
										...this.state.dt_almarhum,
										nik: null,
										nama: '',
										jk: '',
										tanggal_lahir: '',
										tempat_lahir: '',
										status_perkawinan: '',
										pekerjaan: '',
										agama: '',
										alamat: '',
									},
									dt:{
										...this.state.dt,
										nama: '',
										tanggal_lahir: '',
										tempat_lahir: '',
										agama: '',
										pekerjaan: '',
										alamat: '',
										jk: '',
										status_perkawinan: '',
									},
									status:{
										...this.state.status,
										dataPenduduk: 'none',
										btnForm: true,
									},
									show: true,
									basicType: 'danger',
									basicTitle: 'Peringatan Catatan Warga',
									pesanAlert:
										'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
										catatan
								})

								this.forceUpdate();
							}
						});
					}
				} else {
					if (sel === 'cari_nik2') {
						this.setState({
							nik2Stat: null,
							frmNik2: [],
							dt:{
								...this.state.dt,
								nama2: '',
								tanggal_lahir2: '',
								tempat_lahir2: '',
								agama2: '',
								pekerjaan2: '',
								kewarganegaraan2: '',
								alamat2: '',
								jenis_kelamin2: '',
								status_perkawinan2: '',
								tempat_meninggal2: '',
								sebab_meninggal2: '',
							},
							status:{
								...this.state.status,
								dataPenduduk2: 'none',
								btnForm: true,
							}
						})
						this.forceUpdate();
						this.props.getAntrian('Surat Keterangan Ahli Waris', 'cari nik');
					} else if (sel === 'cari_nik1') {
						this.setState({
							dt_almarhum:{
								...this.state.dt_almarhum,
								nik: null,
								nama: '',
								jk: '',
								tanggal_lahir: '',
								tempat_lahir: '',
								status_perkawinan: '',
								pekerjaan: '',
								agama: '',
								alamat: '',
							},
							dt:{
								...this.state.dt,
								nama: '',
							},
							status:{
								...this.state.status,
								btnForm: true,
							}
						})

						this.forceUpdate();
						this.props.getAntrian('Surat Kematian', 'cari nik');
					}
				}
			});
		}
	};

	tutupForm = () => {
		this.setState({
			shdk1: null,
			input: {},
		})

		this.forceUpdate();
	};

	render() {
		const shdk = [
			{ value: 'Suami', label: 'Suami' },
			{ value: 'Isteri', label: 'Isteri' },
			{ value: 'Orang Tua', label: 'Orang Tua' },
			{ value: 'Anak', label: 'Anak' },
			{ value: 'Kakak', label: 'Kakak' },
			{ value: 'Adik', label: 'Adik' },
			{ value: 'Mertua', label: 'Mertua' },
			{ value: 'Menantu', label: 'Menantu' },
			{ value: 'Cucu', label: 'Cucu' },
			{ value: 'Pembantu', label: 'Pembantu' },
			{ value: 'Famili Lain', label: 'Famili Lain' }
		];

		const footerStyle = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		// const jk = [ { value: 'laki-laki', label: 'Laki-laki' }, { value: 'perempuan', label: 'perempuan' } ];

		const { Basic } = this.state;

		return (
			<div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="info"
					onCancel={this.closeAlert}
					showConfirm={false}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => {
						this.tutupForm();
						this.onCloseModal('Basic');
					}}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display === 'block' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">Surat Kematian</h5>
							</div>

							<div className="modal-body">
								
								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>

								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik_pelapor"
											type="text"
											value={this.props.data.results.id}
											disabled
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pelapor"
											type="text"
											value={this.props.data.results.nama}
											disabled
										/>
									</Col>
								</FormGroup> */}

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Hubungan Pelapor</Label>
									<Col sm="9">
										<Select
											onFocus={(e) => {
												this.setState({ display: 'none' });
											}}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk1');
											}}
											inputProps={{ required: true }}
											value={this.state.shdk1}
											id="shdk"
											options={shdk}
											required
											placeholder="HUBUNGAN KELUARGA"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK Almarhum
									</Label>
									<div className="col-sm-7">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												this.setActiveInput('nikStat');
											}}
											// value={this.state.nikStat}
											value={this.state.input['nikStat'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											id="nikStat"
											name="nikStat"
											// value={this.state.nikStat}
											// onChange={(e) => this.handleChange1(e)}
											style={{ marginBottom: '10px' }}
											className="form-control"
											placeholder="Masukkan NIK Almarhum"
											required
										/>
									</div>
									<div className="col-sm-2" style={{ textAlign: 'center' }}>
										<button
											type="button"
											className="btn btn-warning"
											onClick={(e) => this.cariNIK(e, 'cari_nik1')}
										>
											Cari
										</button>
									</div>
								</FormGroup>

								<FormGroup
									className="row"
									style={{ display: this.state.dt_almarhum.nama === '' ? 'none' : '' }}
								>
									<Label className="col-sm-3 col-form-label">Nama Almarhum</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_almarhum"
											placeholder="Nama Almarhum"
											type="text"
											defaultValue={this.state.dt_almarhum.nama}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup
									className="row"
									style={{ display: this.state.dt_almarhum.nama === '' ? 'none' : '' }}
								>
									<Label className="col-sm-3 col-form-label">Jenis Kelamin</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="jenis_kelamin_almarhum"
											placeholder="Jenis Kelamin Almarhum"
											defaultValue={this.state.dt_almarhum.jk}
											type="text"
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Waktu dan Tanggal Meninggal</Label>
									<Col sm="9">
										<Datetime
											locale="id-ID"
											input={true}
											closeOnTab={true}
											inputProps={{
												required: true,
												placeholder: 'Waktu Meninggal'
												// onFocus: (e) => {
												// 	this.setState({ display: 'none' });
												// }
											}}
											initialValue={this.state.waktu_meninggal}
											onChange={(e) => {
												this.gantiTgl(e, 'waktu_meninggal');
											}}
											timeFormat="HH:mm"
											dateFormat="DD/MM/yyyy"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tempat Meninggal</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												//console.log(e.target);
												this.setActiveInput('tempat_meninggal');
											}}
											value={this.state.input['tempat_meninggal'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tempat_meninggal"
											placeholder="Contoh : Rumah Sakit"
											type="text"
											required
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Sebab Meninggal</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												//console.log(e.target);
												this.setActiveInput('sebab_meninggal');
											}}
											value={this.state.input['sebab_meninggal'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="sebab_meninggal"
											placeholder="Contoh : Sakit"
											type="text"
											required
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Dimakamkan di</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												//console.log(e.target);
												this.setActiveInput('tempat_pemakaman');
											}}
											value={this.state.input['tempat_pemakaman'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tempat_pemakaman"
											placeholder="Contoh : TPU Kali Jaya"
											type="text"
											required
										/>
									</Col>
								</FormGroup>
							</div>

							<div className="modal-footer" style={footerStyle}>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => {
										this.tutupForm();
										this.onCloseModal('Basic');
									}}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* <div
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							width: '100%',
							zIndex: 4,
							display: this.state.display
						}}
					>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #8e8e8e, #3f3f40)'
						// background: 'linear-gradient(135deg, #339ce8, #4ec573)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/kematian.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Kematian
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

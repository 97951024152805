import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Form } from 'reactstrap';
import Datetime from 'react-datetime';
import Select from "react-select";
// import Datepicker from 'react-datepicker';
// import Keyboard from 'react-simple-keyboard';

import SweetAlert from 'react-bootstrap-sweetalert';
// koneksi
import { Post, Get, JENIS_DUSUN } from '../function/Koneksi';

const dateFormat = require('dateformat');

export default class SuratIzinKeramainan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: {
				form: false,
				btnForm: true,
				btnAksi: []
			},
			// keyboard
			layoutName: 'default',
			inputName: '',
			input: {},
			inputPattern: /^\d+$/,
			display: 'block',
			focus: false,
			hasFocus: false,

			Basic: false,
			longContent: false,
			Vertically: false,
			Tooltips: false,
			Grid: false,
			VaryingMdo: false,
			VaryingFat: false,
			VaryingBootstrap: false,
			sizeLarge: false,
			sizeSmall: false,

			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false,
			tanggal_mulai: null,
			tanggal_selesai: null,
			isDown: false,
			startY: null,
			scrollUp: null,

			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			// input: inputObj
			input: updatedInputObj
		});
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		//console.log(inputVal);
		//console.log(updatedInputObj);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onKeyPress = (button) => {
		// //console.log('Button pressed', button);

		if (button === '{shift}' || button === '{lock}') {
			this.handleShift();
		}
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		this.forceUpdate();
	};

	onOpenModal = (key) => {
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ 
			[key]: false, 
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
	};

	handleClick = (event) => {
		alert(event);
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_mulai':
				// this.setState({ tanggal_mulai: date.format('YYYY-MM-DD HH:mm:ss') });
				// this.setState({ tanggal_mulai: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ tanggal_mulai: new Date(tgl) });
				}
				break;
			case 'tanggal_selesai':
				// this.setState({ tanggal_selesai: date.format('YYYY-MM-DD HH:mm:ss') });
				// this.setState({ tanggal_selesai: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ tanggal_selesai: new Date(tgl) });
				}
				break;
			default:
				// no default
				break;
		}
	};

	simpan = (e) => {
		e.preventDefault();
		this.setState({
			status:{
				...this.state.status,
				btnForm: true,
			}
		})
		this.forceUpdate();

		if (this.state.nikStatTerlapor) {
			// let alamat = '';
			// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			// 	alamat =
			// 		' RT. ' +
			// 		this.props.data.results.data_kk.data_rt.nama +
			// 		'/RW. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			// 		', Kec. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			// } else {
			// 	// alamat =
			// 	// 	' RT. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.nama +
			// 	// 	'/RW. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	// 	', ' +
			// 	// 	JENIS_DUSUN +
			// 	// 	' ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	// 	', Kec. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

			// 		if (this.props.data.results.data_kk.id_rt === '0') {
			// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
			// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			// 		} else {
			// 			alamat =
			// 			" RT. " + 
			// 			this.props.data.results.data_kk.data_rt.nama +
			// 			"/RW. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 			", " + JENIS_DUSUN + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 			", " + JENIS_DESA + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 			", Kec. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.nama +
			// 			", " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.data_kabkota.nama; 
			// 		}
			// }

			let formDetail = [
				{
					nama_item: 'tgl_mulai',
					isi_item: dateFormat(this.state.tanggal_mulai, 'yyyy-mm-dd HH:MM:ss')
				},
				{
					nama_item: 'tgl_selesai',
					isi_item: dateFormat(this.state.tanggal_selesai, 'yyyy-mm-dd HH:MM:ss')
				},
				{
					nama_item: 'tempat',
					isi_item: document.getElementById('tempat').value
				},
				{
					nama_item: 'hiburan',
					isi_item: document.getElementById('hiburan').value
				}
			];

			let addData = {
				id_jenis: this.props.id_jenis,
				nik_pelapor: this.props.data.results.id,
				nik: this.state.dt_keluarga.nik,
				kk: this.state.dt_keluarga.kk,
				nama_pemohon: this.state.dt_keluarga.nama,
				tempat_lahir: this.state.dt_keluarga.tempat_lahir,
				tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
				pekerjaan: this.state.dt_keluarga.pekerjaan,
				agama: this.state.dt_keluarga.agama,
				status_perkawinan: this.state.dt_keluarga.status_perkawinan,
				alamat: this.state.dt_keluarga.alamat,
				jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
				pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,

				kewarganegaraan: 'Indonesia',
				tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
				detail: formDetail
			};

			// //console.log(addData);

			// let psn = '';
			let resstat = 201;
			let metode = 'create';

			Post('surat-keterangan/grup/' + metode + '/with-detail', null, addData, (res) => {
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};

				if (res.status === resstat) {
					Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
						//console.log("Hasil Update Status")
						//console.log(res2)
						if (res2.code === 201) {
							//console.log("Berhasil Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Izin Keramaian', 'berhasil', false, dataAntrian);
						}
						else{
							//console.log("Gagal Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Izin Keramaian', 'gagal');
						}
					});
					// this.props.getAntrian('Surat Izin Keramaian', 'berhasil', true, dataAntrian);
				} else {
					this.props.getAntrian('Surat Izin Keramaian', 'gagal');
				}
				// this.componentDidMount();
				// reset data
				this.setState({
					tanggal_mulai: null,
					tanggal_selesai: null,
					input: {},
				})
				this.forceUpdate();
				document.getElementById('tempat').value = '';
				document.getElementById('hiburan').value = '';
				document.getElementById('tujuan_pembuatan').value = '';
			});
		} else {
			this.props.getAntrian('Surat Izin Keramaian', 'gagal', true);
		}
	};

	
	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });
	}

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
		}
	  };
	
	getPenduduk = (e, sel) => {
		if (e) {
		  this.changeSelectValue(e, sel);
		  Get("penduduk/find", e.value, data => {
			let alamat = '';
			if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
			  alamat =
			  " RT. " +
			  data.results.data_kk.data_rt.nama +
			  "/RW. " +
			  data.results.data_kk.data_rt.data_rw.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			  ", Kec. " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.data_kabkota.nama; 
			} else {
			  if (data.results.data_kk.id_rt === '0') {
				alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
				", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			  } else {
				alamat =
				" RT. " + 
				data.results.data_kk.data_rt.nama +
				"/RW. " +
				data.results.data_kk.data_rt.data_rw.nama +
				", " + JENIS_DUSUN + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				", " + localStorage.getItem('JENIS_DESA') + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				", Kec. " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.nama +
				", " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.data_kabkota.nama; 
			  }
			}
	
			
			if (sel === "nikTerlapor") {
				this.setState({
					dt_keluarga:{
						nik: e.value,
						kk: data.results.id_kk,
						nama: data.results.nama,
						agama: data.results.data_agama.nama,
						alamat: alamat,
						jenis_kelamin: data.results.jk,
						pekerjaan: data.results.data_pekerjaan.nama,
						status_perkawinan: data.results.status_perkawinan,
						tanggal_lahir: data.results.tanggal_lahir,
						tempat_lahir: data.results.tempat_lahir,
						pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
					},
					dataKeluarga: 'block',
				})
			}
			this.forceUpdate();
		  });
		} else {
			this.setState({
				dt_keluarga:{
					nik: '',
					kk: '',
					nama: '',
					agama: '',
					alamat: '',
					jenis_kelamin: '',
					pekerjaan: '',
					status_perkawinan: '',
					tanggal_lahir: '',
					tempat_lahir: '',
					pendidikan_terakhir: '',
				},
				dataKeluarga: 'none',
				nikStatTerlapor:null,
			});
		  	this.forceUpdate();
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		} else {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		}
	
		this.forceUpdate();
	};


	render() {
		const { Basic } = this.state;

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		let frmDef = {
			uuid: '',
			id_jenis: '',
			id_pengaju: '',
			tanggal_surat: '',
			nik: '',
			nama_pemohon: '',
			tempat_lahir: '',
			tanggal_lahir: '',
			pekerjaan: '',
			agama: '',
			status_perkawinan: '',
			alamat: '',
			kewarganegaraan: 'Indonesia',
			tujuan_pembuatan: '',
			lokasi: '',
			kk: '',
			detail: [],
			jenis_kelamin: '',
			status_ttd: '',
			jabatan_ttd: '',
			jabatan_pengganti_ttd: '',
			nama_ttd: '',
			nip_ttd: '',
			//detail
			//detail
			tgl_mulai: '',
			tgl_selesai: '',
			waktu_mulai: '',
			waktu_selesai: '',
			tempat: '',
			hiburan: ''
		};

		return (
			<div>
				<SweetAlert
					show={this.state.show}
					// show={true}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="info"
					onCancel={this.closeAlert}
					showConfirm={false}
				/>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// className="card-scrollable"
							// style={{ marginBottom: this.state.display == 'block' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// //console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								// //console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">
									Surat Izin Keramaian
								</h5>
							</div>
							<div className="modal-body">
								<input
									className="form-control"
									id="jenis_kelamin"
									readOnly
									type="hidden"
									placeholder="jenis_kelamin"
									value={this.props.data.results.jk}
								/>

								<input
									className="form-control"
									readOnly
									id="pekerjaan"
									type="hidden"
									placeholder="Pekerjaan"
									value={this.props.data.results.pekerjaan}
								/>

								<input
									className="form-control"
									id="pendidikan_terakhir"
									readOnly
									type="hidden"
									placeholder="Pendidikan Terakhir"
									value={this.props.data.results.pendidikan_terakhir}
								/>

								<input
									className="form-control"
									id="agama"
									readOnly
									type="hidden"
									placeholder="Agama"
									value={this.props.data.results.agama}
								/>

								<input
									className="form-control"
									id="status_perkawinan"
									type="hidden"
									readOnly
									placeholder="Status Perkawinan"
									value={this.props.data.results.status_perkawinan}
								/>

								<input
									className="form-control"
									id="alamat"
									type="hidden"
									readOnly
									placeholder="Alamat"
									value={this.props.data.results.alamat}
								/>

								<input
									className="form-control"
									id="kewarganegaraan"
									type="hidden"
									readOnly={true}
									placeholder="Kewarganegaraan"
									value={this.props.data.results.kewarganegaraan}
								/>
								<input
									className="form-control"
									id="kk"
									type="hidden"
									placeholder="no kk"
									value={this.props.data.results.id_kk}
								/>

								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup> */}

								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>

								<input
									className="form-control"
									readOnly
									id="tempat_lahir"
									type="hidden"
									placeholder="Tempat lahir"
									value={this.props.data.results.tempat_lahir}
								/>

								<input
									className="form-control"
									id="tanggal_lahir"
									type="hidden"
									placeholder="Tanggal Lahir"
									value={this.props.data.results.tanggal_lahir}
								/>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tujuan pembuatan</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												// //console.log(e.target);
												this.setActiveInput('tujuan_pembuatan');
											}}
											value={this.state.input['tujuan_pembuatan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tujuan_pembuatan"
											type="text"
											required
											placeholder="Tujuan pembuatan"
											autoComplete="off"
											// autoFocus
											// defaultValue={this.state.dt.tujuan_pembuatan}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Waktu Acara</Label>
									<Col sm="4">
										<Datetime
											inputProps={{
												required: true,
												placeholder: 'Waktu Mulai',
												onFocus: (e) => {
													// this.setState({ display: 'none' });
												}
											}}
											initialValue={new Date(this.state.tanggal_mulai)}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_mulai');
											}}
											locale="id-ID"
											timeFormat="HH:mm"
											dateFormat="DD/MM/yyyy"
										/>
									</Col>
									<Label className="col-sm-1 col-form-label text-center">s/d</Label>
									<Col sm="4">
										<Datetime
											locale="id-ID"
											dateFormat="DD/MM/yyyy"
											timeFormat="HH:mm"
											inputProps={{
												required: true,
												placeholder: 'Waktu Selesai',
												onFocus: (e) => {
													// this.setState({ display: 'none' });
												}
											}}
											initialValue={this.state.tanggal_selesai}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_selesai');
											}}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tempat</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												// //console.log(e.target);
												this.setActiveInput('tempat');
											}}
											value={this.state.input['tempat'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tempat"
											type="text"
											required
											placeholder="Tempat"
											autoComplete="off"
											// defaultValue={this.state.dt.tempat}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Hiburan</Label>
									<Col sm="9">
										<input
											onFocus={() => {
												this.setState({ display: 'block' });
												this.setActiveInput('hiburan');
											}}
											value={this.state.input['hiburan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="hiburan"
											type="text"
											required
											placeholder="Hiburan"
											autoComplete="off"
											// defaultValue={this.state.dt.hiburan}
										/>
									</Col>
								</FormGroup>
							</div>
							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* <div
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							width: '100%',
							zIndex: 4,
							display: this.state.display
						}}
					>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>
				<div
					className="card kiosk-card"
					onClick={() => {
						this.setState({
							judul: 'Tambah Surat Keramaian',
							dt: frmDef,
							tanggal_mulai: new Date(),
							tanggal_selesai: null,
							pendStat: []
						});
						this.onOpenModal(`Basic`);
					}}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #339ce8, #4ec573)'
						// background: 'linear-gradient(135deg, #99ff12, #40b523)'
						// background: 'linear-gradient(135deg, #097df9, #2988ec)'
						// background: 'linear-gradient(135deg, #484848, #000000)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/keramaian.png')}
								alt="logo"
								// width="60"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p className="mb-0" style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
								Izin Keramaian
							</p>
						</div>
					</div>
				</div>

				{/* <div className="row" style={{ marginTop: '200px', position: 'absolute' }}>
					<div
						className="container-fluid"
						style={{ zIndex: 3, width: '100vw', position: 'fixed', bottom: 0 }}
					>
						<div className="row">
							<Keyboard
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
								inputPattern={this.state.inputPattern}
							/>
						</div>
					</div>
				</div> */}
			</div>
		);
	}
}

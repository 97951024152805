import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col,  } from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import { Tanggal, } from "../../function/Format";
// import { ID_DESA } from "../../function/Koneksi";


function EditTidakMampu({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  const dateFormat = require('dateformat');

  let no = 0;
  const tidak_mampu = detailSuratProps.detailSurat.detail.map((dt, i) => {
    no++;
    let jk = "L";
    if (dt.jk.toLowerCase() === "perempuan") {
      jk = "P";
    }
    return (
      <tr key={i}>
        <td>{no}</td>
        <td>{dt.nama}</td>
        <td>{jk}</td>
        <td>
          {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
        </td>
        <td>{dt.pekerjaan}</td>
        <td>{dt.pendidikan}</td>
        <td>{dt.shdk}</td>
        <td>
          <Button
            size="xs"
            onClick={() => detailSuratProps.hapusAw(i)}
            color="danger"
            className="btn-icon"
          >
            <i className="fa fa-trash"></i>
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <Modal
        open={detailSuratProps.status.formUpdateSurat}
        onClose={detailSuratProps.tutupForm}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <p>
              Data identitas akan terisi secara otomatis saat NIK dipilih.
              Jika ada ketidak sesuaian data, silakan ubah di data master
              penduduk.
              </p>
            <input
              className="form-control"
              id="uuid"
              type="hidden"
              placeholder=""
              defaultValue={detailSuratProps.detailSurat.uuid}
            />
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                NIK
                </Label>
              <Col sm="9">
                <Select
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'nik');
                  }}
                  defaultValue={detailSuratProps.dataNikStat.nikStat}
                  value={detailSuratProps.dataNikStat.nikStat}
                  name="NIK"
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilih NIK"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik")}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pemohon
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Nama Pemohon"
                    value={detailSuratProps.detailSurat.nama_pemohon}
                    readOnly
                    style={{ backgroundColor:"#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tempat_lahir"
                    type="text"
                    placeholder="Tempat Lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir}
                    readOnly
                    style={{ backgroundColor:"#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tanggal_lahir"
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir, 'dd/mm/yyyy')}
                    readOnly
                    style={{ backgroundColor:"#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  KK
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kk"
                    type="text"
                    placeholder="Nomor KK"
                    value={detailSuratProps.detailSurat.kk}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jenis Kelamin
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="jenis_kelamin"
                    type="text"
                    placeholder="Jenis Kelamin"
                    value={detailSuratProps.detailSurat.jenis_kelamin}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pekerjaan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="pekerjaan"
                    type="text"
                    placeholder="Pekerjaan"
                    value={detailSuratProps.detailSurat.pekerjaan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pendidikan Terakhir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="pendidikan_terakhir"
                    type="text"
                    placeholder="Pendidikan Terakhir"
                    value={detailSuratProps.detailSurat.pendidikan_terakhir}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kewarganegaraan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kewarganegaraan"
                    type="text"
                    placeholder="Pekerjaan"
                    value={detailSuratProps.detailSurat.kewarganegaraan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Perkawinan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="status_perkawinan"
                    type="text"
                    placeholder="Status Perkawinan"
                    value={detailSuratProps.detailSurat.status_perkawinan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Agama
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="agama"
                    type="text"
                    placeholder="Agama"
                    value={detailSuratProps.detailSurat.agama}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat"
                    type="text"
                    placeholder="Alamat"
                    value={detailSuratProps.detailSurat.alamat}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>

            
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Keperluan Untuk
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="tujuan_pembuatan"
                  type="text"
                  placeholder="Keperluan Untuk"
                  defaultValue={detailSuratProps.detailSurat.tujuan_pembuatan}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-12 col-form-label">
                Daftar Keluarga Yang Ikut
                </Label>
              <Col sm="5">
                <Select
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'nik3');
                  }}
                  defaultValue={detailSuratProps.dataNikStat.nik3Stat}
                  value={detailSuratProps.dataNikStat.nik3Stat}
                  name="NIK3"
                  options={detailSuratProps.dataNik.frmNik3}
                  placeholder="Pilih NIK Tidak Mampu"
                  // onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik3")}
                  isClearable
                  required={true}
                />
              </Col>
              <Col sm="4">
                {/* <input
                  className="form-control"
                  id="shdk"
                  type="text"
                  placeholder="SHDK"
                  value={detailSuratProps.detailSurat.shdk}
                  onChange={e => detailSuratProps.addShdk(e, "shdk")}
                /> */}
                <Select
                  classNamePrefix="select"
                  onChange={(e) => {
                    detailSuratProps.changeSelectValue2(e, 'shdk_aw');
                  }}
                  value={detailSuratProps.detailSurat.shdk}
                  id="shdk"
                  options={[
                    { value: 'Suami', label: 'Suami' },
                    { value: 'Isteri', label: 'Isteri' },
                    { value: 'Orang Tua', label: 'Orang Tua' },
                    { value: 'Anak', label: 'Anak' },
                    { value: 'Kakak', label: 'Kakak' },
                    { value: 'Adik', label: 'Adik' },
                    { value: 'Mertua', label: 'Mertua' },
                    { value: 'Menantu', label: 'Menantu' },
                    { value: 'Cucu', label: 'Cucu' },
                    { value: 'Pembantu', label: 'Pembantu' },
                    { value: 'Famili Lain', label: 'Famili Lain' }
                  ]}
                  placeholder="Hubungan Keluarga"
                />
              </Col>
              <Col sm="3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={detailSuratProps.tambahAw}
                >
                  Tambah
                  </button>
              </Col>
              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>JK</th>
                      <th>Tempat/Tanggal Lahir</th>
                      <th>Pekerjaan</th>
                      <th>Pendidikan</th>
                      <th>Hubungan Keluarga</th>
                      <th>
                        <i className="fa fa-trash"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tidak_mampu}</tbody>
                </table>
              </Col>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              onClick={detailSuratProps.tutupForm}
            >
              Tutup
              </button>
            <button
              type="submit"
              className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >
              Simpan
              </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditTidakMampu
import React, { Component } from "react";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

class ComingSoon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
  }
  
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <h2 align="center">Segera Hadir</h2>
                  <h6><p align="center">Wah, fitur yang kamu inginkan akan segera hadir ! <br/> Tunggu update selanjutnya ya !</p></h6>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default ComingSoon;
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Col, FormGroup, Label, Form,} from 'reactstrap';
// import { Tanggal, HariTanggal } from '../function/Format';
// import Datepicker from 'react-datepicker';
import { Link, Redirect } from 'react-router-dom';
import { Post, Get, } from '../function/Koneksi';

// import Keyboard from 'react-simple-keyboard';

import 'react-simple-keyboard/build/css/index.css';

import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

const frmDef = {
	uuid: '',
	id_jenis: '',
	nomor: '',
	tanggal_surat: new Date(),

	nik_anak: '',
	nama_anak: '',
	tempat_lahir_anak: '',
	tanggal_lahir_anak: null,
	jk_anak: '',

	nik_ayah: '',
	nama_ayah: '',
	tempat_lahir_ayah: '',
	tanggal_lahir_ayah: '',
	alamat_ayah: '',
	pekerjaan_ayah: '',
	
	nik_ibu: '',
	nama_ibu: '',
	tempat_lahir_ibu: '',
	tanggal_lahir_ibu: '',
	alamat_ibu: '',
	pekerjaan_ibu: '',

	nik_pelapor: '',
	nama_pelapor: '',
	tempat_lahir_pelapor: '',
	tanggal_lahir_pelapor: '',
	pekerjaan_pelapor: '',
	agama_pelapor: '',
	alamat_pelapor: '',
	hubungan_pelapor: '',

	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: ''
};

const dateFormat = require('dateformat');

export default class SuratKelahiran extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//keyboard
			focus: false,
			display: 'none',
			layoutName: 'default',
			inputName: 'input1',
			input: {},
			inputPattern: /^\d+$/,
			//end keyboard
			data: [],
			Basic: false,
			status: {
				form: false,
				btnForm: false,
				btnAksi: [],
				dataPenduduk: 'none',
				dataPenduduk2: 'none',
				dataPenduduk3: 'none'
			},

			// ============ Select 2 ==============
			frmJam: [],
			jamStat: [],
			frmPkr: [],
			pkrStat: [],
			frm2Pkr: [],
			pkr2Stat: [],
			frmAgm: [],
			agmStat: [],
			frmNik: [],
			nikStat: null,
			frmNik2: [],
			nik2Stat: null,
			frmNik3: [],
			nik3Stat: null,
			

			formIbu: 'none',
			formAyah: 'none',
			dt: frmDef,
			shdk1: null,
			tanggal_lahir_anak : null,

			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
	}

	// ====================keyboard=============================

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}

		this.forceUpdate();
	};

	onChange = (input) => {
		this.setState({ input });
		//console.log('Input changed', input);
	};

	onKeyPress = (button) => {
		//console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeInput = (event) => {
		//console.log('===onchange===');
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			},
			() => {
				if (typeof this.keyboard !== 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onInputChanged = (data) => {
		this.setState({ input: data });
	};

	onInputSubmitted = (data) => {
		//console.log('Input submitted:', data);
	};

	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		//console.log('Input changed', inputObj);
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};
	
	// end keyboard
	
	onOpenModal = (key) => {
		//console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ 
			[key]: false, 
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
			display: 'none', 
			input: '', 
			shdk1: null,
			nikStat: null,
			pkrStat: [],
			nik2Stat: null,
			nik3Stat: null,
			pkr2Stat: [],
			tanggal_lahir_anak: new Date(),
			formAyah: 'none',
			formIbu: 'none',
			accStat: null,
			tableAcc: 'none',
			formDisposisi: false,
			formAcc: false,
			dt:{
				...this.state.dt,
				nama_ayah: '',
				tanggal_lahir_ayah: '',
				tempat_lahir_ayah: '',
				pekerjaan_ayah: '',
				alamat_ayah: '',
				nama_pelapor: '',
				tanggal_lahir_pelapor: '',
				tempat_lahir_pelapor: '',
				agama_pelapor: '',
				pekerjaan_pelapor: '',
				alamat_pelapor: '',
				jk_pelapor: '',
				status_perkawinan_pelapor: '',
				nama_ibu: '',
				tanggal_lahir_ibu: '',
				tempat_lahir_ibu: '',
				pekerjaan_ibu: '',
				alamat_ibu: '',
				tempat_lahir_anak: '',
				tanggal_lahir_anak: new Date(),
				nama_anak: '',
				jk_anak: '',
				nik_ayah: '',
				nik_ibu: '',
			},
			status:{
				...this.state.status,
				dataPenduduk: 'none',
				dataPenduduk2: 'none',
				dataPenduduk3: 'none',
				form: false,
			}
		});
		
		

		

		this.forceUpdate();
	};

	handleClick = (event) => {
		alert(event);
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_surat':
				this.setState({ tanggal_surat: date });
				break;
			case 'tanggal_menikah':
				this.setState({ tanggal_menikah: date });
				break;
			case 'tanggal_lahir_anak':
				if (typeof date._isValid !== 'undefined') {
					this.setState({ tanggal_lahir_anak: date.format("YYYY-MM-DD HH:mm:ss") });
				}
				break;
			case 'tanggal_lahir_ayah':
				this.setState({
					dt:{
						...this.state.dt,
						tanggal_lahir_ayah: date.format('YYYY-MM-DD'),
					}
				})
				this.forceUpdate();
				break;
			case 'tanggal_lahir_ibu':
				this.setState({
					dt:{
						...this.state.dt,
						tanggal_lahir_ibu: date.format('YYYY-MM-DD'),
					}
				})
				this.forceUpdate();
				break;
			default:
				// no default
				break;
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'shdk1':
					this.setState({ 
						shdk1: e, 
						dt:{
							...this.state.dt,
							shdk1: e.value,
						}
					});
					break;
				case 'nik':
					this.setState({ 
						nikStat: e, 
						dt:{
							...this.state.dt,
							nik_ayah: e.value,
						}
					});
					break;
				case 'nik2':
					this.setState({ 
						nik2Stat: e, 
						dt:{
							...this.state.dt,
							nik_pelapor: e.value,
						}
					});
					break;
				case 'nik3':
					this.setState({ 
						nik3Stat: e, 
						dt:{
							...this.state.dt,
							nik_ibu: e.value,
						}
					});
					break;
				case 'pekerjaan_ayah':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan_ayah: e.label,
						}
					});
					break;
				case 'pekerjaan_ibu':
					this.setState({ 
						pkr2Stat: e, 
						dt:{
							...this.state.dt,
							pekerjaan_ibu: e.label,
						}
					});
					break;
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'shdk1':
					this.setState({ 
						shdk1: e, 
						dt:{
							...this.state.dt,
							shdk1: 0,
						}
					});
					break;
				case 'nik':
					this.setState({ 
						nikStat: e, 
						dt:{
							...this.state.dt,
							nik_ayah: 0,
						}
					});
					break;
				case 'nik2':
					this.setState({ 
						nik2Stat: e, 
						dt:{
							...this.state.dt,
							nik_pelapor: 0,
						}
					});
					break;
				case 'nik3':
					this.setState({ 
						nik3Stat: e, 
						dt:{
							...this.state.dt,
							nik_ibu: 0,
						}
					});
					break;
				case 'pekerjaan_ayah':
					this.setState({ 
						pkrStat: null, 
						dt:{
							...this.state.dt,
							pekerjaan_ayah: 0,
						}
					});
					break;
				case 'pekerjaan_ibu':
					this.setState({ 
						pkr2Stat: null, 
						dt:{
							...this.state.dt,
							pekerjaan_ibu: 0,
						}
					});
					break;
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

	///data ibu
	dataIbu = () => {
		//console.log(this.props.data);
		this.setState({
			formIbu: this.state.formIbu === '' ? 'none' : '',
			nik3Stat: null,
			dt:{
				...this.state.dt,
				nik_ibu: '',
				nama_ibu: '',
				tempat_lahir_ibu: '',
				tanggal_lahir_ibu: '',
				pekerjaan_ibu: '',
				alamat_ibu: '',
			},
			status:{
				...this.state.status,
				dataPenduduk3: this.state.status.dataPenduduk3 === '' ? 'none' : '',
			}
		})
		this.forceUpdate();
	};
	// data ayah
	dataAyah = () => {
		//console.log(this.props.data);
		this.setState({
			formAyah: this.state.formAyah === '' ? 'none' : '',
			nikStat: null,
			dt:{
				...this.state.dt,
				nik_ayah: '',
				nama_ayah: '',
				tempat_lahir_ayah: '',
				tanggal_lahir_ayah: '',
				pekerjaan_ayah: '',
				alamat_ayah: '',
			},
			status:{
				...this.state.status,
				dataPenduduk: this.state.status.dataPenduduk === '' ? 'none' : '',
			}
		})
		this.forceUpdate();
	};

	componentDidMount() {
		if (this.props.data) {
			let frmJam = [];
			let jamnya = '';
			for (let i = 1; i <= 24; i++) {
				jamnya = i;
				if (i < 10) {
					jamnya = '0' + i;
				}
				jamnya += ':00';
				frmJam.push({ value: jamnya, label: jamnya });
			}
			this.setState({ frmJam });
			// Get list pekerjaan from parent
			if (this.props.listPekerjaan) {
				this.setState({ frmPkr: this.props.listPekerjaan, frm2Pkr: this.props.listPekerjaan })
			}
			// Get('pekerjaan/list', null, (data) => {
			// 	let frmPkr = [];
			// 	if(data.results){
			// 		data.results.forEach((dt) => {
			// 			frmPkr.push({ value: dt.uuid, label: dt.nama });
			// 		});
			// 		this.setState({ frmPkr });
			// 	}
			// });
			// Get('pekerjaan/list', null, (data) => {
			// 	let frm2Pkr = [];
			// 	if(data.results){
			// 		data.results.forEach((dt) => {
			// 			frm2Pkr.push({ value: dt.uuid, label: dt.nama });
			// 		});
			// 		this.setState({ frm2Pkr });
			// 	}
			// });
			// Get list agama from parent
			if (this.props.listAgama) {
				this.setState({ frmAgm: this.props.listAgama })
			}
			// Get('agama/list', null, (data) => {
			// 	let frmAgm = [];
			// 	if(data.results){
			// 		data.results.forEach((dt) => {
			// 			frmAgm.push({ value: dt.uuid, label: dt.nama });
			// 		});
			// 		this.setState({ frmAgm });
			// 	}
			// });

			// LIST PENDUDUK DEFAULT SEDANG TIDAK DIPERLUKAN
			// Get('penduduk', null, (data) => {
			// 	let frmNik = [];
			// 	let frmNik2 = [];
			// 	let frmNik3 = [];
			// 	if(data.results){
			// 		data.results.data.forEach((dt) => {
			// 			frmNik.push({ value: dt.id, label: dt.id });
			// 			frmNik2.push({ value: dt.id, label: dt.id });
			// 			frmNik3.push({ value: dt.id, label: dt.id });
			// 		});
			// 		this.setState({ frmNik, frmNik2, frmNik3 });
			// 	}
			// });

			// Get list anggota keluarga from parent
			if (this.props.anggotaKeluarga) {
				this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
			}
			// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
			// 	console.log("Data Keluarga")
			// 	console.log(data)
			// 	if(data.results){
			// 	  let frmNikKeluarga = [];
			// 	  data.results.forEach(dt => {
			// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
			// 	  });
			// 	  this.setState({ frmNikKeluarga });
				  
			// 	  // For testing
			// 	  if (this.props.getListNIKTerlapor) {
			// 		this.props.getListNIKTerlapor(frmNikKeluarga)
			// 	  }
			// 	}
			// });
		} else {
			this.setState({ redirect: true });
		}
	}

	// cek redirect jiksa state kosong
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/" />;
		}
	};

	ubahJk = (e) => {
		this.setState({
			dt:{
				...this.state.dt,
				jk_anak: document.getElementById('jk_anak').value,
			}
		})
		this.forceUpdate();
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === 'nik') {
				let nikStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.forEach((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik, nikStat });
				});
			} else {
				if (fld === "nikTerlapor") {
					let newArray = this.state.frmNikKeluarga;
		
					newArray = newArray.filter(day => day !== e);
	
					this.setState({
						frmNikKeluarga:newArray,
					})
				}
				else if (fld === 'nik2') {
					let nik2Stat = { value: e, label: e };
					let param = {
						nik: e
					};
					Post('penduduk', null, param, (datanik) => {
						let frmNik2 = [];
						datanik.data.results.data.forEach((dt) => {
							frmNik2.push({ value: dt.id, label: dt.id });
						});
						this.setState({ frmNik2, nik2Stat });
					});
				} else {
					let nik3Stat = { value: e, label: e };
					let param = {
						nik: e
					};
					Post('penduduk', null, param, (datanik) => {
						let frmNik3 = [];
						datanik.data.results.data.forEach((dt) => {
							frmNik3.push({ value: dt.id, label: dt.id });
						});
						this.setState({ frmNik3, nik3Stat });
					});
				}
			}
		}
	};

	getPenduduk = (e, sel, jns) => {
		// this.state.status.btnForm = true;
		this.forceUpdate();
		if (e) {
			this.changeSelectValue(e, sel);
			Get('penduduk/find', e.value, (data) => {
				// const data_rt = data.results.data_kk.data_rt;
				// const data_rw = data_rt.data_rw;
				// const data_dusun = data_rw.data_dusun;
				// const data_desa = data_dusun.data_keldes;
				// const data_kecamatan = data_desa.data_kecamatan;
				// const data_kabkota = data_kecamatan.data_kabkota;
				let alamat = data.results.alamat;

				if (jns === 'pelapor') {
					this.setState({
						tanggal_lahir_pelapor: data.results.tanggal_lahir,
						dt:{
							...this.state.dt,
							nama_pelapor: data.results.nama,
							tanggal_lahir_pelapor: new Date(data.results.tanggal_lahir).toLocaleDateString(
								'id-ID'
							),
							tempat_lahir_pelapor: data.results.tempat_lahir,
							jk_pelapor: data.results.jk,
							status_perkawinan_pelapor: data.results.status_perkawinan,
							agama_pelapor: data.results.data_agama.nama,
							pekerjaan_pelapor: data.results.data_pekerjaan.nama,
							alamat_pelapor: alamat,
						},
						status:{
							...this.state.status,
							dataPenduduk2: 'block',
						}
					})
				} else {
					if (sel === "nikTerlapor") {
						this.setState({
							dt_keluarga:{
								nik: e.value,
								kk: data.results.id_kk,
								nama: data.results.nama,
								agama: data.results.data_agama.nama,
								alamat: alamat,
								jenis_kelamin: data.results.jk,
								pekerjaan: data.results.data_pekerjaan.nama,
								status_perkawinan: data.results.status_perkawinan,
								tanggal_lahir: data.results.tanggal_lahir,
								tempat_lahir: data.results.tempat_lahir,
								pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
							},
							dataKeluarga: 'block',
						})
					}
					else if (jns === 'ibu') {
						this.setState({
							tanggal_lahir_ibu: data.results.tanggal_lahir,
							dt:{
								...this.state.dt,
								nama_ibu: data.results.nama,
								nik_ibu: data.results.id,
								tanggal_lahir_ibu: new Date(data.results.tanggal_lahir).toLocaleDateString(
									'id-ID'
								),
								tempat_lahir_ibu: data.results.tempat_lahir,
								pekerjaan_ibu: data.results.data_pekerjaan.nama,
								alamat_ibu: alamat,
							},
							status:{
								...this.state.status,
								dataPenduduk3: 'block',
							}
						})
					} else {
						Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
							let pctt = 0;
							let catatan = '';
							if (dpddk.results.data) {
								dpddk.results.data.forEach((dtp) => {
									if (dtp.status === 0 && dtp.penalti === 1) {
										pctt = 1;
										catatan = dtp.catatan;
									}
								});
							}
							if (pctt === 0) {
								this.setState({
									tanggal_lahir_ayah: data.results.tanggal_lahir,
									dt:{
										...this.state.dt,
										nama_ayah: data.results.nama,
										nik_ayah: data.results.id,
										tanggal_lahir_ayah: new Date(
											data.results.tanggal_lahir
										).toLocaleDateString('id-ID'),
										tempat_lahir_ayah: data.results.tempat_lahir,
										pekerjaan_ayah: data.results.data_pekerjaan.nama,
										alamat_ayah: alamat,
									},
									status:{
										...this.state.status,
										dataPenduduk: 'block',
									}
								})
								// this.state.status.btnForm = false;
								this.forceUpdate();
							} else {
								this.setState({
									dt:{
										...this.state.dt,
										nama_ayah: '',
										tanggal_lahir_ayah: '',
										tempat_lahir_ayah: '',
										pekerjaan_ayah: '',
										alamat_ayah: '',
									},
									status:{
										...this.state.status,
										dataPenduduk: 'none',
									},
									nikStat: null,
									frmNik: [],
									show: true,
									basicType: 'danger',
									basicTitle: 'Peringatan Catatan Warga',
									pesanAlert:
										'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
										catatan
								});
								// this.state.status.btnForm = true;
								this.forceUpdate();
							}
						});
					}
				}
				// this.state.status.btnForm = true;

				if (this.state.nikStat !== null && this.state.nik2Stat !== null && this.state.nik3Stat !== null) {
					this.setState({
						status:{
							...this.state.status,
							btnForm: false,
						}
					})
				}
				this.forceUpdate();
			});
		} else {
			if(sel === "nikTerlapor"){
				this.setState({
					dt_keluarga:{
						nik: '',
						kk: '',
						nama: '',
						agama: '',
						alamat: '',
						jenis_kelamin: '',
						pekerjaan: '',
						status_perkawinan: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						pendidikan_terakhir: '',
					},
					dataKeluarga: 'none',
					nikStatTerlapor:null,
				});
			}
			else if (jns === 'pelapor') {
				this.setState({
					nik2Stat: null,
					dt:{
						...this.state.dt,
						nama_pelapor: '',
						tanggal_lahir_pelapor: '',
						tempat_lahir_pelapor: '',
						agama_pelapor: '',
						pekerjaan_pelapor: '',
						alamat_pelapor: '',
						jk_pelapor: '',
						status_perkawinan_pelapor: '',
					},
					status:{
						...this.state.status,
						dataPenduduk2: 'none',
					}
				})
			} else {
				if (jns === 'ibu') {
					this.setState({
						nik3Stat: null,
						dt:{
							...this.state.dt,
							nama_ibu: '',
							tanggal_lahir_ibu: '',
							tempat_lahir_ibu: '',
							pekerjaan_ibu: '',
							alamat_ibu: '',
						},
						status:{
							...this.state.status,
							dataPenduduk3: 'none',
						}
					})
				} else {
					this.setState({
						nikStat: null,
						dt:{
							...this.state.dt,
							nama_ayah: '',
							tanggal_lahir_ayah: '',
							tempat_lahir_ayah: '',
							pekerjaan_ayah: '',
							alamat_ayah: '',
						},
						status:{
							...this.state.status,
							dataPenduduk: 'none',
						}
					})
				}
			}
			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				}
			})

			this.forceUpdate();
		}
	};

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		// //console.log('jenis kelamin : ', this.state.dt.jk_anak);
		// //console.log('nik ibu : ', this.state.input['cari_nik2']);
		if (this.state.nikStatTerlapor) {
			const nikAyah = document.getElementById("nik_ayah").value
			const nikIbu = document.getElementById("nik_ibu").value

			// //console.log(nikAyah)
			// //console.log(nikIbu)

			
			if (nikAyah.trim() === '' || nikIbu.trim() === '') {
				this.props.getAntrian('Surat Keterangan Kelahiran', 'gagal', true);
			}
			else if (nikAyah.length !== 16 || nikIbu.length !== 16) {
				this.props.getAntrian('Surat Keterangan Kelahiran', 'cek digit nik');
			} 
			else if (this.state.dt.jk_anak !== '' && this.state.shdk1 && this.state.status.dataPenduduk !== "none" && this.state.status.dataPenduduk3 !== "none" && this.state.dt.pekerjaan_ayah!=='' && this.state.dt.pekerjaan_ibu!=='') {
				
				let tanggal_lahir_ibu;
				if (this.state.formIbu !== '') {
					// //console.log('masuk ib');
					let tgl_lhr_ibu = this.state.dt.tanggal_lahir_ibu.split('/');
					
					tanggal_lahir_ibu = tgl_lhr_ibu[2] + '-' + tgl_lhr_ibu[1] + '-' + tgl_lhr_ibu[0];
				} else {
					// tanggal_lahir_ibu = this.state.dt.tanggal_lahir_ibu;
					tanggal_lahir_ibu = dateFormat(this.state.dt.tanggal_lahir_ibu, 'yyyy-mm-dd');
				}

				let tanggal_lahir_ayah;
				if (this.state.formAyah !== '') {
					//console.log('form ayah aktif');
					let tgl_lhr_ayah = this.state.dt.tanggal_lahir_ayah.split('/');
					tanggal_lahir_ayah = tgl_lhr_ayah[2] + '-' + tgl_lhr_ayah[1] + '-' + tgl_lhr_ayah[0];
				} else {
					//console.log('form ayah nonaktif');
					// tanggal_lahir_ayah = this.state.dt.tanggal_lahir_ayah;
					tanggal_lahir_ayah = dateFormat(this.state.dt.tanggal_lahir_ayah, 'yyyy-mm-dd');
				}

				// let alamat = '';
				// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
				// 	alamat =
				// 		' RT. ' +
				// 		this.props.data.results.data_kk.data_rt.nama +
				// 		'/RW. ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 		', ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
				// 		', Kec. ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
				// 		', ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
				// } else {
				// 		if (this.props.data.results.data_kk.id_rt === '0') {
				// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
				// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
				// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
				// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				// 		} else {
				// 			alamat =
				// 			" RT. " + 
				// 			this.props.data.results.data_kk.data_rt.nama +
				// 			"/RW. " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 			", " + JENIS_DUSUN + " " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				// 			", " + JENIS_DESA + " " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				// 			", Kec. " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				// 				.data_kecamatan.nama +
				// 			", " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				// 				.data_kecamatan.data_kabkota.nama; 
				// 		}
				// }

				let addData = {
					id_jenis: this.props.id_jenis,
					// nomor : document.getElementById("nomor").value,

					nama_anak: document.getElementById('nama_anak').value,
					jk_anak: this.state.dt.jk_anak,
					tempat_lahir_anak: document.getElementById('tempat_lahir_anak').value,
					// tanggal_lahir_anak: new Date(this.state.tanggal_lahir_anak),
					tanggal_lahir_anak: dateFormat(this.state.tanggal_lahir_anak, 'yyyy-mm-dd HH:MM:ss'),

					nama_ayah: document.getElementById('nama_ayah').value,
					tempat_lahir_ayah: document.getElementById('tempat_lahir_ayah').value,
					tanggal_lahir_ayah: tanggal_lahir_ayah,
					nik_ayah: document.getElementById('nik_ayah').value,
					// pekerjaan_ayah: document.getElementById('pekerjaan_ayah').value,
					pekerjaan_ayah: this.state.dt.pekerjaan_ayah,
					alamat_ayah: document.getElementById('alamat_ayah').value,

					nik_ibu: document.getElementById('nik_ibu').value,
					nama_ibu: document.getElementById('nama_ibu').value,
					tempat_lahir_ibu: document.getElementById('tempat_lahir_ibu').value,
					tanggal_lahir_ibu: tanggal_lahir_ibu,
					pekerjaan_ibu: this.state.dt.pekerjaan_ibu,
					// pekerjaan_ibu: document.getElementById('pekerjaan_ibu').value,
					alamat_ibu: document.getElementById('alamat_ibu').value,

					nik_pelapor: this.props.data.results.id,
					nik: this.state.dt_keluarga.nik,
					kk: this.state.dt_keluarga.kk,
					nama_pelapor: this.state.dt_keluarga.nama,
					tempat_lahir_pelapor: this.state.dt_keluarga.tempat_lahir,
					tanggal_lahir_pelapor: this.state.dt_keluarga.tanggal_lahir,
					pekerjaan_pelapor: this.state.dt_keluarga.pekerjaan,
					agama_pelapor: this.state.dt_keluarga.agama,
					status_perkawinan: this.state.dt_keluarga.status_perkawinan,
					alamat_pelapor: this.state.dt_keluarga.alamat,
					jenis_kelamin_pelapor: this.state.dt_keluarga.jenis_kelamin,
					pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,
					hubungan_pelapor: this.state.dt.shdk1,

					// nik_pelapor: this.props.data.results.id,
					// nama_pelapor: this.props.data.results.nama,
					// tempat_lahir_pelapor: this.props.data.results.tempat_lahir,
					// tanggal_lahir_pelapor: this.props.data.results.tanggal_lahir,
					// jenis_kelamin_pelapor: this.props.data.results.jk,
					// pekerjaan_pelapor: this.props.data.results.data_pekerjaan.nama,
					// agama_pelapor: this.props.data.results.data_agama.nama,
					// alamat_pelapor: alamat,
					// hubungan_pelapor: this.state.dt.shdk1,

					status_ttd: null
				};
				// let psn = '';
				let resstat = 201;
				let metode = 'create';

				//console.log("Data Simpannya")
				//console.log(addData)

				Post('surat-keterangan/kelahiran/' + metode, null, addData, (res) => {
					this.onCloseModal('Basic');

					let dataAntrian = {
						nama: this.props.data.results.nama,
						nik: this.props.data.results.id,
						keterangan: '',
						uuid_surat:res.data.results,
					};
		
					if (res.status === resstat) {
						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
							//console.log("Hasil Update Status")
							//console.log(res2)
							if (res2.code === 201) {
								//console.log("Berhasil Ganti Status Menjadi Selesai")
		
								this.props.getAntrian('Surat Keterangan Kelahiran', 'berhasil', false, dataAntrian);
							}
							else{
								//console.log("Gagal Ganti Status Menjadi Selesai")
		
								this.props.getAntrian('Surat Keterangan Kelahiran', 'gagal');
							}
						});
						// this.props.getAntrian('Surat Keterangan Kelahiran', 'berhasil', false, dataAntrian);
					} else {
						this.props.getAntrian('Surat Keterangan Kelahiran', 'gagal');
					}
					this.componentDidMount();
					// reset cache
					document.getElementById('nama_anak').value = '';
					document.getElementById('tempat_lahir_anak').value = '';
					this.setState({
						dt: frmDef,
						formAyah: 'none',
						formIbu: 'none',
						tanggal_lahir_anak: null,
					})
					this.forceUpdate();
				});
				
			} else {
				this.forceUpdate();
				this.props.getAntrian('Surat Keterangan Kelahiran', 'gagal', true);
			}
		} else {
			this.props.getAntrian('Surat Keterangan Kelahiran', 'gagal', true);
		}
	};
	// ========================================================================

	// ================== change input text ==================
	changeInputText = (e, fld) => {
		switch (fld) {
			case 'tempat_lahir_anak':
				this.setState({
					dt:{
						...this.state.dt,
						tempat_lahir_anak: e.target.value,
					}
				})
				//console.log(e.target.value);
				this.forceUpdate();
				break;
			case 'nik_ayah':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nik_ayah: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'nama_ayah':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nama_ayah: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'tempat_lahir_ayah':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						tempat_lahir_ayah: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'tanggal_lahir_ayah':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						tanggal_lahir_ayah: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'pekerjaan_ayah':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						pekerjaan_ayah: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'alamat_ayah':
				this.setState({
					dt:{
						...this.state.dt,
						alamat_ayah: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'nik_ibu':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nik_ibu: e.target.value,
					}
				})
				this.forceUpdate();
				break;

			case 'nama_ibu':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nama_ibu: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'tanggal_lahir_ibu':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						tanggal_lahir_ibu: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'tempat_lahir_ibu':
				this.setState({
					dt:{
						...this.state.dt,
						tempat_lahir_ibu: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'pekerjaan_ibu':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						pekerjaan_ibu: e.target.value,
					}
				})
				this.forceUpdate();
				break;

			case 'alamat_ibu':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						alamat_ibu: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			default:
				// no default
				break;
		}
	};

	// Cari NIK
	handleChange = (e, cek) => {
		switch (cek) {
			case 'cari_nik1':
				this.setState({
					nikStat: e.target.value,
					// nikStat: {value: e.target.value, label: e.target.value},
					input: e.target.value,
					dt:{
						...this.state.dt,
						nik_ayah: e.target.value,
					}
				});
				this.forceUpdate();
				break;
			case 'cari_nik2':
				this.setState({
					nik3Stat: e.target.value,
					dt:{
						...this.state.dt,
						nik_ibu: e.target.value,
					}
				});
				this.forceUpdate();
				break;
			default:
				// no default
				break;
		}
	};

	cariNIK = (e, jns) => {
		e.preventDefault();

		let id_cari = '';
		if (jns === 'cari_nik1') {
			id_cari = this.state.nikStat;
			// id_cari = this.state.input['cari_nik1'];
		} else if (jns === 'cari_nik2') {
			id_cari = this.state.nik3Stat;
			// id_cari = this.state.input['cari_nik2'];
		}

		if (id_cari.length !== 16) {
			this.forceUpdate();
			// //console.log('Panjang NIK adalah 16 digit');
			this.props.getAntrian('Surat Keterangan Kelahiran', 'cek digit nik');
		} else {
			// //console.log('Mantap bos');

			Get('penduduk/find', id_cari, (data) => {
				if (data.results) {
					// //console.log('Berhasil Tampilkan Penduduk');
					//console.log(data.results);

					// const data_rt = data.results.data_kk.data_rt;
					// const data_rw = data_rt.data_rw;
					// const data_dusun = data_rw.data_dusun;
					// const data_desa = data_dusun.data_keldes;
					// const data_kecamatan = data_desa.data_kecamatan;
					// const data_kabkota = data_kecamatan.data_kabkota;
					let alamat = data.results.alamat;

					if (jns === 'cari_nik2') {
						this.setState({
							tanggal_lahir_ibu: data.results.tanggal_lahir,
							dt:{
								...this.state.dt,
								nama_ibu: data.results.nama,
								nik_ibu: id_cari,
								tanggal_lahir_ibu: new Date(data.results.tanggal_lahir).toLocaleDateString(
									'id-ID'),
								tempat_lahir_ibu: data.results.tempat_lahir,
								pekerjaan_ibu: data.results.data_pekerjaan.nama,
								alamat_ibu: alamat,
							},
							status:{
								...this.state.status,
								dataPenduduk3: 'block',
							}
						})
					} else {
						Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
							let pctt = 0;
							let catatan = '';
							if (dpddk.results.data) {
								dpddk.results.data.forEach((dtp) => {
									if (dtp.status === 0 && dtp.penalti === 1) {
										pctt = 1;
										catatan = dtp.catatan;
									}
								});
							}
							if (pctt === 0) {
								this.setState({
									nikStat: {value: data.results.id, label: data.results.id},
									tanggal_lahir_ayah: data.results.tanggal_lahir,
									dt:{
										...this.state.dt,
										nama_ayah: data.results.nama,
										nik_ayah: id_cari,
										tanggal_lahir_ayah: new Date(
											data.results.tanggal_lahir
										).toLocaleDateString('id-ID'),
										tempat_lahir_ayah: data.results.tempat_lahir,
										pekerjaan_ayah: data.results.data_pekerjaan.nama,
										alamat_ayah: alamat,
									},
									status:{
										...this.state.status,
										dataPenduduk: 'block',
									}
								})
								// this.state.status.btnForm = false;
								this.forceUpdate();
							} else {
								this.setState({
									nikStat: null,
									// input:{['cari_nik1']: null},
									frmNik: [],
									dt:{
										...this.state.dt,
										nik_ayah: '',
										nama_ayah: '',
										tanggal_lahir_ayah: '',
										tempat_lahir_ayah: '',
										pekerjaan_ayah: '',
										alamat_ayah: '',
									},
									status:{
										...this.state.status,
										dataPenduduk: 'none',
									}
								})
								// this.state.status.btnForm = true;
								this.forceUpdate();
								this.setState({
									show: true,
									basicType: 'danger',
									basicTitle: 'Peringatan Catatan Warga',
									pesanAlert:
										'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
										catatan
								});
							}
						});
					}

					this.forceUpdate();
				} else {
					this.forceUpdate();
					this.props.getAntrian('Surat Keterangan Kelahiran', 'cari nik');
				}
			});
		}
	};

	render() {
		// //console.log("Data Pekerjaan Ayah")
		// //console.log(this.state.formAyah)
		// //console.log(this.state.dt.pekerjaan_ayah)
		const shdk = [
			{ value: 'Suami', label: 'Suami' },
			{ value: 'Isteri', label: 'Isteri' },
			{ value: 'Orang Tua', label: 'Orang Tua' },
			{ value: 'Anak', label: 'Anak' },
			{ value: 'Kakak', label: 'Kakak' },
			{ value: 'Adik', label: 'Adik' },
			{ value: 'Mertua', label: 'Mertua' },
			{ value: 'Menantu', label: 'Menantu' },
			{ value: 'Cucu', label: 'Cucu' },
			{ value: 'Pembantu', label: 'Pembantu' },
			{ value: 'Famili Lain', label: 'Famili Lain' }
		];

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				{this.renderRedirect()}

				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display === '' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// //console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								// //console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">Surat Kelahiran</h5>
							</div>

							<div className="modal-body">
								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pelapor</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik_pelapor"
											disabled
											type="text"
											value={this.props.data.results.id}
											placeholder="NIK Pelapor"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Pelapor</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pelapor"
											disabled
											type="text"
											value={this.props.data.results.nama}
											placeholder="Nama Pelapor"
										/>
									</Col>
								</FormGroup> */}

								
								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>


								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Hubungan Pelapor</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk1');
											}}
											value={this.state.shdk1}
											id="shdk"
											options={shdk}
											placeholder="Hubungan Keluarga"
										/>
									</Col>
								</FormGroup>

								<hr />
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Anak</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_anak"
											type="text"
											placeholder="Nama Anak"
											required
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('nama_anak');
											// }}
											// value={this.state.input['nama_anak'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Jenis Kelamin Anak</Label>
									<Col sm="9">
										<select
											className="form-control"
											id="jk_anak"
											placeholder="jk_anak"
											defaultValue={this.state.dt.jk_anak}
											onChange={this.ubahJk}
										>
											<option value="">Pilih Jenis Kelamin</option>
											<option>Laki-Laki</option>
											<option>Perempuan</option>
										</select>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Lahir Anak
									</Label>
									<Col sm="9">
										<Datetime
											locale="id-ID"
											timeFormat="HH:mm"
											dateFormat="DD/MM/yyyy"
											id="tanggal_lahir_anak"
											input={true}
											inputProps={{ required: true, placeholder: 'Tanggal Lahir Anak' }}
											closeOnTab={true}
											initialValue={this.state.tanggal_lahir_anak}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_lahir_anak');
												// this.setState({ tanggal_lahirkk:})
												// //console.log(e.format('YYYY-MM-DD'))
											}}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Lahir Anak
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_lahir_anak"
											type="text"
											placeholder="Tempat Lahir Anak"
											required
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('tempat_lahir_anak');
											// }}
											// value={this.state.input['tempat_lahir_anak'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<hr />
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK Ayah
									</Label>

									{this.state.formAyah === 'none' ? (
										<>
											<div className="col-sm-7">
												<input
													name="nikStat"
													id="nik_ayah"
													defaultValue={this.state.dt.nik_ayah}
													value={this.state.dt.nik_ayah}
													onChange={(e) => this.handleChange(e, 'cari_nik1')}
													style={{ marginBottom: '10px' }}
													className="form-control"
													placeholder="Masukkan NIK Ayah"
													type="number"
													// minLength="16"

													// onFocus={(e) => {
													// 	this.setState({ display: '' });
													// 	//console.log(e.target);
													// 	this.setActiveInput('cari_nik1');
													// }}
													// value={this.state.input['cari_nik1'] || ''}
													// onChange={(e) => this.onChangeInput(e)}
												/>
											</div>
											<div className="col-sm-2" style={{ textAlign: 'center' }}>
												<button
													type="button"
													className="btn btn-warning"
													onClick={(e) => this.cariNIK(e, 'cari_nik1')}
												>
													Cari
												</button>
											</div>
										</>
									) : (
										// <Select
										// 	classNamePrefix="select"
										// 	onChange={(e) => {
										// 		this.getPenduduk(e, 'nik', 'pemohon');
										// 	}}
										// 	defaultValue={this.state.nikStat}
										// 	value={this.state.nikStat}
										// 	name="NIK"
										// 	options={this.state.frmNik}
										// 	placeholder="Pilih NIK"
										// 	onInputChange={(e) => this.handleInputChangeNIK(e, 'nik')}
										// 	isClearable
										// />
										<Col sm="9">
											<input
												className="form-control"
												id="nik_ayah"
												type={this.state.formAyah === 'none' ? 'hidden' : 'number'}
												placeholder="NIK ayah"
												defaultValue={this.state.dt.nik_ayah}
												value={this.state.dt.nik_ayah}
												required
												onChange={(e) => this.changeInputText(e, 'nik_ayah')}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('cari_nik1');
												// }}
												// value={this.state.input['cari_nik1'] || ''}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									)}

									<Col sm="3"> &nbsp; </Col>
									<Col sm="6" align="left">
										<small id="emailHelp" className="form-text text-muted">
											Data NIK ayah {this.state.formAyah === 'none' ? 'tidak' : ''} tersedia?{' '}
											<Link
												to={{
													pathname: this.props.myroute,
													state: this.props.data
												}}
												onClick={this.dataAyah}
											>
												{' '}
												Klik disini
											</Link>
										</small>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.status.dataPenduduk }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Ayah
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama_ayah"
												type="text"
												placeholder="Nama Ayah"
												// value={this.state.dt.nama_ayah}
												//   readOnly
												readOnly={this.state.formAyah === '' ? false : true}
												required
												onChange={(e) => this.changeInputText(e, 'nama_ayah')}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('nama_ayah');
												// }}
												value={
													this.state.formAyah === '' ? (
														// this.state.input['nama_ayah'] || ''
														this.state.dt.nama_ayah
													) : (
														this.state.dt.nama_ayah
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir Ayah
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tempat_lahir_ayah"
												type="text"
												placeholder="Tempat Lahir Ayah"
												// value={this.state.dt.tempat_lahir_ayah}
												onChange={(e) => this.changeInputText(e, 'tempat_lahir_ayah')}
												readOnly={this.state.formAyah === '' ? false : true}
												required
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('tempat_lahir_ayah');
												// }}
												value={
													this.state.formAyah === '' ? (
														// this.state.input['tempat_lahir_ayah'] || ''
														this.state.dt.tempat_lahir_ayah
													) : (
														this.state.dt.tempat_lahir_ayah
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
												//   readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir Ayah
										</Label>
										<Col sm="9">
											{this.state.formAyah === 'none' ? (
												<input
													className="form-control"
													id="tanggal_lahir_ayah"
													type="text"
													readOnly
													required
													placeholder="Tanggal Lahir"
													value={this.state.dt.tanggal_lahir_ayah}
												/>
											) : (
												<Datetime
													locale="id-ID"
													input={true}
													inputProps={{ required: true, placeholder: 'Tanggal Lahir Ayah' }}
													closeOnTab={true}
													timeFormat={false}
													initialValue={this.state.dt.tanggal_lahir_ayah}
													onChange={(e) => {
														this.gantiTgl(e, 'tanggal_lahir_ayah');
														// this.setState({ tanggal_lahirkk:})
														// //console.log(e.format('YYYY-MM-DD'))
													}}
												/>
											)}

											{/* <input
											className="form-control"
											id="tanggal_lahir_ayah"
											//   type="text"
											type={this.state.formAyah === '' ? 'date' : 'text'}
											placeholder="Tanggal Lahir"
											value={this.state.dt.tanggal_lahir_ayah}
											//   readOnly
											readOnly={this.state.formAyah === '' ? false : true}
											required
											onChange={(e) => this.changeInputText(e, 'tanggal_lahir_ayah')}
										/> */}
										</Col>
									</FormGroup>
								</div>
								<div style={{ display: this.state.formAyah }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan Ayah
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'pekerjaan_ayah');
												}}
												defaultValue={this.state.pkrStat}
												value={this.state.pkrStat}
												name="Pekerjaan Ayah"
												options={this.state.frmPkr}
												placeholder="Pilih Pekerjaan Ayah"
												isClearable
											/>
											{/* <input
												className="form-control"
												id="pekerjaan_ayah"
												type="text"
												placeholder="Pekerjaan Ayah"
												// defaultValue={this.state.dt.pekerjaan_ayah}
												// value={this.state.dt.pekerjaan_ayah}
												readOnly={this.state.formAyah === '' ? false : true}
												// onChange={(e) => this.changeInputText(e, 'pekerjaan_ayah')}
												required={this.state.formAyah === '' ? true : false}
												onFocus={(e) => {
													this.setState({ display: '' });
													//console.log(e.target);
													this.setActiveInput('pekerjaan_ayah');
												}}
												value={
													this.state.formAyah === '' ? (
														this.state.input['pekerjaan_ayah'] || ''
													) : (
														this.state.dt.pekerjaan_ayah
													)
												}
												onChange={(e) => this.onChangeInput(e)}
												//   readOnly
											/> */}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Alamat Ayah
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="alamat_ayah"
												type="text"
												placeholder="Alamat Ayah"
												// defaultValue={this.state.dt.alamat_ayah}
												// value={this.state.dt.alamat_ayah}
												readOnly={this.state.formAyah === '' ? false : true}
												onChange={(e) => this.changeInputText(e, 'alamat_ayah')}
												required={this.state.formAyah === '' ? true : false}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('alamat_ayah');
												// }}
												value={
													this.state.formAyah === '' ? (
														// this.state.input['alamat_ayah'] || ''
														this.state.dt.alamat_ayah
													) : (
														this.state.dt.alamat_ayah
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
												//   readOnly
											/>
										</Col>
									</FormGroup>
								</div>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK Ibu
									</Label>

									{this.state.formIbu === 'none' ? (
										<>
											<div className="col-sm-7">
												<input
													name="nik3Stat"
													id="nik_ibu"
													defaultValue={this.state.dt.nik_ibu}
													value={this.state.dt.nik_ibu}
													onChange={(e) => this.handleChange(e, 'cari_nik2')}
													style={{ marginBottom: '10px' }}
													className="form-control"
													placeholder="Masukkan NIK Ibu"
													type="number"
													// minLength="16"
													// onFocus={(e) => {
													// 	this.setState({ display: '' });
													// 	//console.log(e.target);
													// 	this.setActiveInput('cari_nik2');
													// }}
													// value={this.state.input['cari_nik2'] || ''}
													// onChange={(e) => this.onChangeInput(e)}
												/>
											</div>
											<div className="col-sm-2" style={{ textAlign: 'center' }}>
												<button
													type="button"
													className="btn btn-warning"
													onClick={(e) => this.cariNIK(e, 'cari_nik2')}
												>
													Cari
												</button>
											</div>
										</>
									) : (
										// <Select
										// 	classNamePrefix="select"
										// 	onChange={(e) => {
										// 		this.getPenduduk(e, 'nik3', 'ibu');
										// 	}}
										// 	defaultValue={this.state.nik3Stat}
										// 	value={this.state.nik3Stat}
										// 	name="NIK3"
										// 	options={this.state.frmNik3}
										// 	placeholder="Pilih NIK IBU"
										// 	onInputChange={(e) => this.handleInputChangeNIK(e, 'nik3')}
										// 	isClearable
										// />
										<Col sm="9">
											<input
												className="form-control"
												id="nik_ibu"
												type={this.state.formIbu === 'none' ? 'hidden' : 'number'}
												placeholder="Nik Ibu"
												defaultValue={this.state.dt.nik_ibu}
												value={this.state.dt.nik_ibu}
												onChange={(e) => this.changeInputText(e, 'nik_ibu')}
												required
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('cari_nik2');
												// }}
												// value={this.state.input['cari_nik2'] || ''}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									)}

									<Col sm="3"> &nbsp; </Col>
									<Col sm="6" align="left">
										<small id="emailHelp" className="form-text text-muted">
											{' '}
											Data NIK ibu {this.state.formIbu === 'none' ? 'tidak' : ''} tersedia?{' '}
											<Link
												to={{
													pathname: this.props.myroute,
													state: this.props.data
												}}
												onClick={this.dataIbu}
											>
												{' '}
												Klik disini
											</Link>
										</small>
									</Col>
								</FormGroup>
								<div style={{ display: this.state.status.dataPenduduk3 }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Ibu
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama_ibu"
												type="text"
												placeholder="Nama Ibu"
												//   value={this.state.dt.nama_ibu}
												// defaultValue={this.state.dt.nama_ibu}
												// value={this.state.dt.nama_ibu}
												onChange={(e) => this.changeInputText(e, 'nama_ibu')}
												readOnly={this.state.formIbu === '' ? false : true}
												required
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('nama_ibu');
												// }}
												value={
													this.state.formIbu === '' ? (
														// this.state.input['nama_ibu'] || ''
														this.state.dt.nama_ibu
													) : (
														this.state.dt.nama_ibu
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir Ibu
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tempat_lahir_ibu"
												type="text"
												placeholder="Tempat Lahir Ibu"
												// value={this.state.dt.tempat_lahir_ibu}
												//   readOnly
												// defaultValue={this.state.dt.tempat_lahir_ibu}
												// value={this.state.dt.tempat_lahir_ibu}
												onChange={(e) => this.changeInputText(e, 'tempat_lahir_ibu')}
												readOnly={this.state.formIbu === '' ? false : true}
												required
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('tempat_lahir_ibu');
												// }}
												value={
													this.state.formIbu === '' ? (
														// this.state.input['tempat_lahir_ibu'] || ''
														this.state.dt.tempat_lahir_ibu
													) : (
														this.state.dt.tempat_lahir_ibu
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir Ibu
										</Label>
										<Col sm="9">
											{this.state.formIbu === 'none' ? (
												<input
													className="form-control"
													id="tanggal_lahir_ibu"
													type="text"
													readOnly
													required
													placeholder="Tanggal Lahir"
													value={this.state.dt.tanggal_lahir_ibu}
												/>
											) : (
												<Datetime
													locale="id-ID"
													input={true}
													inputProps={{ required: true, placeholder: 'Tanggal Lahir Ibu' }}
													closeOnTab={true}
													timeFormat={false}
													initialValue={this.state.dt.tanggal_lahir_ibu}
													onChange={(e) => {
														this.gantiTgl(e, 'tanggal_lahir_ibu');
														// this.setState({ tanggal_lahirkk:})
														// //console.log(e.format('YYYY-MM-DD'))
													}}
												/>
											)}

											{/* <input
											className="form-control"
											id="tanggal_lahir_ibu"
											type={this.state.formIbu === '' ? 'date' : 'text'}
											placeholder="Tanggal Lahir"
											value={this.state.dt.tanggal_lahir_ibu}
											//   readOnly
											onChange={(e) => this.changeInputText(e, 'tanggal_lahir_ibu')}
											defaultValue={this.state.dt.tanggal_lahir_ibu}
											value={this.state.dt.tanggal_lahir_ibu}
											readOnly={this.state.formIbu === '' ? false : true}
											required
										/> */}
										</Col>
									</FormGroup>
								</div>
								<div style={{ display: this.state.formIbu }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan Ibu
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'pekerjaan_ibu');
												}}
												defaultValue={this.state.pkr2Stat}
												value={this.state.pkr2Stat}
												name="Pekerjaan Ibu"
												options={this.state.frm2Pkr}
												placeholder="Pilih Pekerjaan Ibu"
												isClearable
											/>
											{/* <input
												className="form-control"
												id="pekerjaan_ibu"
												type="text"
												placeholder="Pekerjaan Ibu"
												// onChange={(e) => this.changeInputText(e, 'pekerjaan_ibu')}
												// defaultValue={this.state.dt.pekerjaan_ibu}
												// value={this.state.dt.pekerjaan_ibu}
												readOnly={this.state.formIbu === '' ? false : true}
												required
												onFocus={(e) => {
													this.setState({ display: '' });
													//console.log(e.target);
													this.setActiveInput('pekerjaan_ibu');
												}}
												value={
													this.state.formIbu === '' ? (
														this.state.input['pekerjaan_ibu'] || ''
													) : (
														this.state.dt.pekerjaan_ibu
													)
												}
												onChange={(e) => this.onChangeInput(e)}
											/> */}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Alamat Ibu
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="alamat_ibu"
												type="text"
												placeholder="Alamat Ibu"
												// value={this.state.dt.alamat_ibu}
												//   readOnly
												onChange={(e) => this.changeInputText(e, 'alamat_ibu')}
												// defaultValue={this.state.dt.alamat_ibu}
												// value={this.state.dt.alamat_ibu}
												readOnly={this.state.formIbu === '' ? false : true}
												required
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('alamat_ibu');
												// }}
												value={
													this.state.formIbu === '' ? (
														// this.state.input['alamat_ibu'] || ''
														this.state.dt.alamat_ibu
													) : (
														this.state.dt.alamat_ibu
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
								</div>
							</div>

							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
								
							</div>
						</div>
					</Form>

					{/* {this.state.display === '' ? (
						<div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 4 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						''
					)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #99ff12, #40b523)'
						// background: 'linear-gradient(135deg, #339ce8, #4ec573)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/kelahiran.png')}
								height="65"
								alt="logo"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p className="mb-0" style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
								Kelahiran
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
// import CKEditors from 'react-ckeditor-component';
// import Datepicker from 'react-datepicker';

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import { Post, Get, Delete, ID_DESA, JENIS_DESA, JENIS_DUSUN } from '../function/Koneksi';
import { DataToken, } from '../function/Format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// import ReactDatePicker from 'react-datepicker';
// Import the library

import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
//json file
// var data = require('../assets/json/company');

const frmDef = {
	id: 0,
	idbaru: '',
	id_kk: '',
	nama: '',
	alamat: '',
	id_keldes: '',
	tempat_lahir: '',
	tanggal_lahir: new Date(),
	jk: '',
	shdk: '',
	agama: '',
	pendidikan_terakhir: '',
	pekerjaan: '',
	nama_ibu_kandung: '',
	status_penduduk: '',
	status_perkawinan: ''
};
const frmBerkas = {
	uuid: '',
	nik: '',
	jenis: '',
	link: '',
	status: 0
};
const id_keldes = ID_DESA;

class Penduduk extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.updateContent = this.updateContent.bind(this);
		this.onChange = this.onChange.bind(this);
		this.afterPaste = this.afterPaste.bind(this);
		this.state = {
			data: [],
			pagination: [],
			status: {
				formBerkas: false,
				formExport: false,
				form: false,
				btnForm: false,
				btnAksi: false,
				select: false
			},
			manualPage: '',
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			wilayah: 'inherit',
			dt: frmDef,
			databerkas: [],
			berkas: frmBerkas,
			judul: 'Tambah Penduduk',
			foto_berkas: '',
			foto_info: '',
			// =========== Select 2 ============
			content: '',
			// =================================
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKlds: [],
			kldsStat: null,
			frmShdk: [],
			shdkStat: null,
			frmAgm: [],
			agmStat: null,
			frmPtr: [],
			ptrStat: null,
			frmPkr: [],
			pkrStat: null,
			frmKk: [],
			kkStat: null,
			frmStp: [],
			stpStat: null,
			frmJk: [],
			jkStat: null,
			frmSpk: [],
			spkStat: null,
			frmJbk: [],
			jbkStat: null,
			frmSbk: [],
			sbkStat: null,
			frmSkh: [],
			skhStat: null,
			statusberkas: [],
			// =================================
			// =========== Alert ============
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// ==============================
			// ============ Tanggal ==============
			// startDate: new Date(),
			tanggal_lahir: new Date(),
			// tanggal_lahirkk: new Date(),
			// ===================================
			akun: DataToken(sessionStorage.getItem('access_token')),
			dataPenduduk: {
				nik: ''
			}
		};
	}

	// ================== Tanggal ===================
	gantiTgl = (e, kk) => {
		// e.preventDefault();
		if (kk === 'kk') {
			// //console.log(typeof e.format('YYYY-MM-DD'))
			if (typeof e._isValid !== 'undefined') {
				// this.state.tanggal_lahirkk = e.format('YYYY-MM-DD');
				this.setState({tanggal_lahir : new Date(e.format('YYYY-MM-DD'))})
				this.forceUpdate();
			}

			//  this.setState({tanggal_lahirkk:e.format('YYYY-MM-DD')})
			// //console.log(this.state.tanggal_lahirkk);
		} else {
			// this.setState({tanggal_lahir:e.format('YYYY-MM-DD')})
			if (typeof e._isValid !== 'undefined') {
				this.state.tanggal_lahir = e.format('YYYY-MM-DD');
				this.forceUpdate();
			}
		}
	};

	// ==============================================

	// ================== CKEditor ==================

	updateContent(newContent) {
		this.state.dt.alamat = newContent;
		this.forceUpdate();
	}

	onChange(evt) {
		var newContent = evt.editor.getData();
		this.state.dt.alamat = newContent;
		this.forceUpdate();
	}

	onBlur(evt) {
		//console.log('onBlur event called with event info: ', evt);
	}

	afterPaste(evt) {
		//console.log('afterPaste event called with event info: ', evt);
	}

	// ==============================================
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		// let where = [];
		// if (params.where) {
		// 	where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}
		Post('penduduk' + link, null, this.state.dataPenduduk, (dtkat) => {
			this.setState({ data: dtkat.data.results.data, pagination: dtkat.data.results });
			this.pagination(dtkat.data.results);
		});
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// //console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i === data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						href={() => false}
						key={i}
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: p })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}

			if (n > 0 && data.last_page > n) {
				setelah = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: n })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};
	// Cari Data Table
	cariData = (e) => {
		if (e.key === 'Enter') {
			let dataPenduduk = {
				nik: e.target.value
			};
			this.setState({ dataPenduduk });
			setTimeout(
				function() {
					// mywindow.close();
					this.fetch();
				}.bind(this),
				500
			);
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		let nikBaru = document.getElementById("idbaru").value;

		if (nikBaru.length !== 16) {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Penduduk",
        pesanAlert: "NIK tidak terdiri dari 16 angka"
      });
    } else if ( 
			((this.state.kldsStat && this.state.wilayah === 'inherit') || this.state.wilayah === 'none') &&
			this.state.kkStat &&
			this.state.shdkStat &&
			this.state.agmStat &&
			this.state.ptrStat &&
			this.state.pkrStat &&
			this.state.jkStat &&
			this.state.spkStat &&
			this.state.stpStat &&
			this.state.skhStat &&
			this.state.kkStat !== null
		) {
			this.state.status.btnForm = true;
			this.forceUpdate();
			let id = parseInt(document.getElementById('id').value);
			let addPenduduk = {
				id: document.getElementById('idbaru').value,
				id_kk: this.state.dt.id_kk,
				id_keldes: this.state.wilayah === "none" ? ID_DESA : this.state.dt.id_keldes,
				nama: document.getElementById('nama').value,
				alamat: document.getElementById('alamat').value,
				tempat_lahir: document.getElementById('tempat_lahir').value,
				// tanggal_lahir: this.state.tanggal_lahirkk,
				tanggal_lahir: this.state.tanggal_lahir,
				jk: this.state.jkStat.value,
				shdk: this.state.shdkStat.value,
				agama: this.state.agmStat.value,
				status_perkawinan: this.state.spkStat.value,
				status_kehidupan: this.state.skhStat.value,
				pendidikan_terakhir: this.state.ptrStat.value,
				pekerjaan: this.state.pkrStat.value,
				nama_ibu_kandung: document.getElementById('nama_ibu_kandung').value,
				nama_ayah_kandung: document.getElementById('nama_ayah_kandung').value,
				status_penduduk: this.state.stpStat.value
			};
			//console.log(addPenduduk)
			let psn = '';
			let resstat = 204;
			let metode = 'create';
			if (id === 0) {
				psn = 'Tambah';
				resstat = 201;
				id = null;
			} else {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
			}
			Post('penduduk/' + metode, id, addPenduduk, (res) => {
				this.state.status.btnForm = false;
				if (res.status === resstat) {
					this.state.status.form = false;
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Penduduk',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.forceUpdate();
					// this.componentDidMount();
					this.fetch({ page: parseInt(this.state.pagination.current_page) })
				} else if (res.status === 422) {
					if (typeof res.data.id !== "undefined") {
						this.setState({
							show: true,
							basicType: 'warning',
							basicTitle: 'Data Penduduk',
							pesanAlert: "NIK sudah digunakan, silahkan cek kembali NIK anda"
						});
					} else {
						let luar = '';
						if (this.state.wilayah === 'inherit') {
							luar = 'Kelurahan/' + JENIS_DESA + ', ';
						}
						this.setState({
							show: true,
							basicType: 'info',
							basicTitle: 'Data Penduduk',
							pesanAlert:
								'Data belum lengkap. Mohon Pastikan data ' +
								luar +
								'Agama, No. KK, Pendidikan Terakhir, Pekerjaan, Status Perkawinan, Status Penduduk dan Jenis Kelamin sudah dipilih'
						});
					}
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Penduduk',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
				}
				// this.forceUpdate();
				// this.componentDidMount();
			});
		} else {
			let luar = '';
			if (this.state.wilayah === 'inherit') {
				luar = 'Kelurahan/' + JENIS_DESA + ', ';
			}
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Data Penduduk',
				pesanAlert:
					'Data belum lengkap. Mohon Pastikan data ' +
					luar +
					'Nomor KK, SHDK, Agama, Pendidikan Terakhir, Pekerjaan, Status Perkawinan, Status Penduduk dan Jenis Kelamin sudah dipilih'
			});
		}
	};
	simpanBerkas = (e) => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();
		if (this.state.jbkStat.value) {
			let id = document.getElementById('id_berkas').value;
			let addBerkas = {
				nik: this.state.berkas.nik,
				jenis: this.state.jbkStat.value,
				link: this.state.foto_berkas,
				status: this.state.sbkStat.value
			};
			let psn = '';
			let resstat = 204;
			let metode = 'create';
			if (id === '') {
				psn = 'Tambah';
				resstat = 201;
				id = null;
			} else {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
			}
			Post('berkas-verifikasi/' + metode, id, addBerkas, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Berkas Penduduk',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Berkas Penduduk',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
				}
				this.kosongBerkas();
				this.bukaFormBerkas(this.state.berkas.nik);
			});
		} else {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Berkas Penduduk',
				pesanAlert: 'Data Jenis Berkas harus dipilih'
			});
		}
	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = 'Ubah Penduduk';
		// this.state.kkStat = {value:'a', label:'data.results.data_kk.id'};

		this.forceUpdate();
		Get('penduduk/find', id, (data) => {
			//console.log(data)
			let statusKehidupan = '';
			switch (data.results.status_kehidupan) {
				case 0:
					statusKehidupan = 'Meninggal';
					break;
				case 1:
					statusKehidupan = 'Hidup';
					break;
				case 2:
					statusKehidupan = 'Tidak Aktif';
					break;
			
				default:
					break;
			}
			// Jika data shdk !== null
			if (data.results.data_shdk) {
				this.setState({ shdkStat: { value: data.results.data_shdk.uuid, label: data.results.data_shdk.nama } })
			}
			this.setState({
				kkStat: { value: data.results.data_kk.id, label: data.results.data_kk.id },
				
				agmStat: { value: data.results.data_agama.uuid, label: data.results.data_agama.nama },
				ptrStat: {
					value: data.results.data_pendidikan_terakhir.uuid,
					label: data.results.data_pendidikan_terakhir.nama
				},
				pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
				jkStat: { value: data.results.jk, label: data.results.jk },
				stpStat: { value: data.results.status_penduduk, label: data.results.status_penduduk },
				dt: data.results,
				// startDate: new Date(data.results.tanggal_lahir),
				tanggal_lahir: new Date(data.results.tanggal_lahir),
				spkStat: { value: data.results.status_perkawinan, label: data.results.status_perkawinan },
				skhStat: {
					value: data.results.status_kehidupan,
					// label: data.results.status_kehidupan === 0 ? 'Meninggal' : 'Hidup'
					label: statusKehidupan
				}
			});
			// //console.log(this.state.kkStat)
			this.state.dt.idbaru = data.results.id;
			this.state.status.btnAksi = false;
			// this.state.kkStat = {value:data.results.data_kk.id, label:data.results.data_kk.id};
			// this.state.shdkStat = {value:data.results.data_shdk.uuid, label:data.results.data_shdk.nama};
			// this.state.agmStat = {value:data.results.data_agama.uuid, label:data.results.data_agama.nama};
			// this.state.ptrStat = {value:data.results.data_pendidikan_terakhir.uuid, label:data.results.data_pendidikan_terakhir.nama};
			// this.state.pkrStat = {value:data.results.data_pekerjaan.uuid, label:data.results.data_pekerjaan.nama};
			// this.state.jkStat = {value:data.results.jk, label:data.results.jk};
			// this.state.stpStat = {value:data.results.status_penduduk, label:data.results.status_penduduk};
			// this.state.dt = data.results;
			// this.state.startDate =  new Date(data.results.tanggal_lahir);
			if (data.results.id_keldes) {
				this.state.wilayah = 'inherit';
				this.ubahPilihan('keldes', data.results.data_keldes);
			} else {
				this.state.wilayah = 'none';
				this.bukaForm();
			}
		});
	};

	ubahBerkas = (data) => {
		this.state.berkas.uuid = data.uuid;
		this.forceUpdate();
		this.setState({
			foto_berkas: data.link,
			jbkStat: { value: data.jenis, label: data.jenis },
			sbkStat: { value: data.status, label: this.state.statusberkas[data.status] }
		});
	};
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};
	konfirmHapusBerkas = (id, nik) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus berkas ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusDataBerkas(id, nik)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('penduduk/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Penduduk',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Penduduk',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			// this.componentDidMount();
			this.fetch({ page: parseInt(this.state.pagination.current_page) })
		});
	};

	hapusDataBerkas = (id, nik) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('berkas-verifikasi/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Berkas Penduduk',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Berkas Penduduk',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.bukaFormBerkas(nik);
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	};

	kosongBerkas = () => {
		this.state.berkas.uuid = '';
		this.state.berkas.jenis = 0;
		this.state.jbkStat = [];
		this.state.sbkStat = [];
		this.state.berkas.status = 0;
		this.state.berkas.link = '';
		this.state.foto_berkas = '';
		this.state.status.formBerkas = true;
		this.forceUpdate();
	};

	bukaFormBerkas = (id) => {
		Get('berkas-verifikasi/nik', id, (data) => {
			this.state.status.btnForm = false;
			this.state.berkas.nik = id;
			this.state.databerkas = data.results;
			this.state.status.formBerkas = true;
			this.forceUpdate();
		});
	};

	tutupFormBerkas = () => {
		this.state.status.formBerkas = false;
		this.forceUpdate();
	};

	//expoprt
	bukaFormExport = (id) => {
		this.state.status.btnForm = false;
		this.state.status.formExport = true;
		this.forceUpdate();
	};
	tutupFormExport = () => {
		this.state.status.formExport = false;
		this.forceUpdate();
	};
	//expoprt

	tombolAksi = (cell, row) => {
		return (
			<div>
				{/* <Button size="xs" color="primary" className="btn-icon" onClick={() => this.bukaFormBerkas(row.id)}><i className="fa fa-files-o"></i></Button> &nbsp;&nbsp; */}
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => this.ubahData(row.id)}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>{' '}
				{/* &nbsp;&nbsp; */}
				{/* <Button
					size="xs"
					color="danger"
					className="btn-icon"
					onClick={() => this.konfirmHapus(row.id)}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-trash" />
				</Button> */}
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.forEach((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKlds: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKlds: frm });
						break;
					default:
						// no default
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};

	ubahPilihan = (table, data) => {
		// Get(table+'/find', id, (data) => {
		switch (table) {
			// case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
			case 'keldes':
				this.getChild(
					{
						value: data.data_kecamatan.data_kabkota.data_provinsi.id,
						label: data.data_kecamatan.data_kabkota.data_provinsi.nama
					},
					'provinsi',
					'prov',
					'kab-kota'
				);
				this.getChild(
					{ value: data.data_kecamatan.data_kabkota.id, label: data.data_kecamatan.data_kabkota.nama },
					'kab-kota',
					'kab',
					'kecamatan'
				);
				this.getChild(
					{ value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
					'kecamatan',
					'kec',
					'keldes'
				);
				// this.getChild({ value: data.id, label: data.nama }, 'keldes', 'kel', 'penduduk');
				this.setState({
					prvStat: {
						value: data.data_kecamatan.data_kabkota.data_provinsi.id,
						label: data.data_kecamatan.data_kabkota.data_provinsi.nama
					},
					kbktStat: {
						value: data.data_kecamatan.data_kabkota.id,
						label: data.data_kecamatan.data_kabkota.nama
					},
					kecStat: { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
					kldsStat: { value: data.id, label: data.nama }
				});
				this.bukaForm();
				break;
			default:
				// no default
				break;
		}
		// });
	};

	getDetail = (e, sel) => {
		this.changeSelectValue(e, sel);
		if (e) {
			switch (e.value) {
				case 'desa':
					Get('keldes/find', id_keldes, (dt) => {
						this.setState({ frmDetail: [ { value: dt.results.id, label: dt.results.nama } ] });
					});
					break;
				case 'dusun':
					Get('dusun/kel/' + id_keldes + '/list', null, (data) => {
						let frmDetail = [];
						data.results.forEach((dt) => {
							frmDetail.push({ value: dt.id, label: dt.nama });
						});
						this.setState({ frmDetail });
					});
					break;
				case 'rw':
					Get('rw/kel/' + id_keldes + '/list', null, (data) => {
						let frmDetail = [];
						data.results.forEach((dt) => {
							frmDetail.push({
								value: dt.id,
								label: 'RW ' + dt.nama + ' ' + JENIS_DUSUN + ' ' + dt.data_dusun.nama
							});
						});
						this.setState({ frmDetail });
					});
					break;
				case 'rt':
					Get('rt/kel/' + id_keldes + '/list', null, (data) => {
						let frmDetail = [];
						data.results.forEach((dt) => {
							frmDetail.push({
								value: dt.id,
								label: 'Rt ' + dt.nama + ' Rw ' + dt.data_rw.nama + ' Rt ' + dt.data_rw.data_dusun.nama
							});
						});
						this.setState({ frmDetail });
					});
					break;
				default:
					// no default
					break;
			}
		} else {
			this.setState({ tingkatStat: [], detailStat: [] });
		}
	};
	getOrangtua = (e, sel) => {
		this.changeSelectValue(e, sel);
		// if (e)
		// {
		//     if ()
		//         Get('kartu-keluarga/keluarga', e.value, (dt) => {

		//         });
		// }
	};

	cekNomorKK = () => {
		//console.log(this.state.kkStat)
		if (this.state.kkStat === null) {
			//console.log(this.state.kkStat)
			let frmKk = [];
			// Get('kartu-keluarga/list', null, (data) => {
			Get('kartu-keluarga', null, (data) => {
				data.results.data.forEach((dt) => {
						frmKk.push({value:dt.id, label:dt.id});
				});
				// this.setState({frmKk, kkStat : null});
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Data Penduduk',
					pesanAlert: 'Pilih Nomor KK yang tersedia',
					frmKk
				});
			});
		} else {
			// let kkStat = document.getElementById('nomorKKPenduduk').value;
			// this.setState({ kkStat })
			//console.log(this.state.kkStat)
		}
	}

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === 'nik') {
				let nikStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.forEach((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik, nikStat });
				});
			} else if (fld === 'kk') {
				// let kkStat = { value: e, label: e };
				let param = {
					id: e
				};
				Post('kartu-keluarga/select', null, param, (datakk) => {
					let frmKk = [];
					if (datakk.data.results.data.length > 0) {
						datakk.data.results.data.forEach((dt) => {
							frmKk.push({ value: dt.id, label: dt.id });
						});
						// this.setState({ frmKk, kkStat });
						this.setState({ frmKk });
					} else {
						// Get('kartu-keluarga/list', null, (data) => {
						Get('kartu-keluarga', null, (data) => {
							data.results.data.forEach((dt) => {
									frmKk.push({value:dt.id, label:dt.id});
							});

							// if (this.state.kkStat === null) {
								this.setState({
									show: true,
									basicType: 'info',
									basicTitle: 'Data Penduduk',
									pesanAlert: 'Nomor KK tidak tersedia, silahkan pilih Nomor KK yang tersedia',
									frmKk
								});
							// }
						});
					}
				});
			}
		} else {
			if (fld === "kk") {
				Get('kartu-keluarga', null, (data) => {
					let frmKk = [];
					data.results.data.forEach((dt) => {
							frmKk.push({value:dt.id, label:dt.id});
					});
					this.setState({ frmKk });
				});
			}
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					// this.setState({ prvStat: e, kbktStat: null, kecStat: null, kldsStat: null, frmKk: [] });
					this.setState({ prvStat: e, kbktStat: null, kecStat: null, kldsStat: null });
					break;
				case 'kab-kota':
					// this.setState({ kbktStat: e, kecStat: null, kldsStat: null, frmKk: [] });
					this.setState({ kbktStat: e, kecStat: null, kldsStat: null });
					break;
				case 'kecamatan':
					// this.setState({ kecStat: e, kldsStat: null, frmKk: [] });
					this.setState({ kecStat: e, kldsStat: null });
					break;
				case 'keldes':
					this.state.dt.id_keldes = e.value;
					this.setState({ kldsStat: e });
					// Get('kartu-keluarga/keldes/' + e.value + '/list', null, (data) => {
					//     let frmKk = [];
					//     data.results.forEach((dt) => {
					//         frmKk.push({ value: dt.id, label: dt.id });
					//     });
					//     this.setState({ frmKk });
					// });
					break;
				case 'shdk':
					this.state.dt.shdk = e.value;
					this.setState({ shdkStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.value;
					this.setState({ agmStat: e });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = e.value;
					this.setState({ ptrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.value;
					this.setState({ pkrStat: e });
					break;
				case 'kk':
					this.state.dt.id_kk = e.value;
					// this.state.kkStat = e;
					// this.forceUpdate();
					this.setState({ kkStat: e });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = e.value;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = e.value;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = e.value;
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'status-kehidupan':
					this.setState({ skhStat: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({
						prvStat: null,
						kbktStat: null,
						kecStat: null,
						kldsStat: null,
						frmKbkt: [],
						frmKec: [],
						frmKlds: []
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null, kecStat: null, kldsStat: null, frmKec: [], frmKlds: [] });
					break;
				case 'kecamatan':
					this.setState({ kecStat: null, kldsStat: null, frmKlds: [] });
					break;
				case 'keldes':
					this.state.dt.id_keldes = 0;
					this.setState({ kldsStat: null });
					break;
				case 'shdk':
					this.state.dt.shdk = 0;
					this.setState({ shdkStat: null });
					break;
				case 'agama':
					this.state.dt.agama = 0;
					this.setState({ agmStat: null });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = 0;
					this.setState({ ptrStat: null });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = 0;
					this.setState({ pkrStat: null });
					break;
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = 0;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = 0;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = '';
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'status-kehidupan':
					this.setState({ skhStat: e });
					break;
				default:
					// no default
					break;
			}
		}

		this.state.status.select = false;
		this.forceUpdate();
	};

	processGbr = (d) => {
		let type = d[0].type.split('/');
		if (
			type[1] === 'jpg' ||
			type[1] === 'jpeg' ||
			type[1] === 'png' ||
			type[1] === 'bmp' ||
			type[1] === 'tiff' ||
			type[1] === 'webp'
		) {
			Resizer.imageFileResizer(
				d[0],
				300,
				300,
				type[1],
				100,
				0,
				(uri) => {
					this.setState({ foto_berkas: uri, foto_info: '' });
				},
				'base64'
			);
		} else {
			this.setState({
				foto_berkas: '',
				foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
			});
		}
	};

	// export
	exportView = () => {
		if (this.state.akun.sub.data_role.nama_role !== 'Admin') {
			return (
				<Button
					size="sm"
					color="success"
					className="btn-square mr-4"
					onClick={() => {
						this.setState({
							judulExport: 'Export Penduduk',
							dt: frmDef,
							prvStat: [],
							kbktStat: [],
							kecStat: [],
							kldsStat: [],
							shdkStat: [],
							ptrStat: [],
							pkrStat: [],
							agmStat: [],
							kkStat: []
						});
						this.bukaFormExport();
					}}
				>
					Export data
				</Button>
			);
		} else {
			return null;
		}
	};
	componentDidMount() {
		this.fetch({ page: null, where: [] });
		Get('provinsi/list', null, (data) => {
			let frmPrv = [];
			data.results.forEach((dt) => {
				frmPrv.push({ value: dt.id, label: dt.nama });
			});
			this.setState({
				frmPrv,
				frmTingkat: [
					{ value: 'dusun', label: 'Dusun' },
					{ value: 'rw', label: 'RW' },
					{ value: 'rt', label: 'RT' }
				]
			});
		});
		// Get('kartu-keluarga/list', null, (data) => {
		Get('kartu-keluarga', null, (data) => {
			//console.log(data.results)
		    let frmKk = [];
		    data.results.data.forEach((dt) => {
		        frmKk.push({value:dt.id, label:dt.id});
		    });
		    this.setState({frmKk});
		});
		Get('shdk/list', null, (data) => {
			let frmShdk = [];
			data.results.forEach((dt) => {
				frmShdk.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmShdk });
		});
		Get('agama/list', null, (data) => {
			let frmAgm = [];
			data.results.forEach((dt) => {
				frmAgm.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmAgm });
		});
		Get('jenjang-pendidikan/list', null, (data) => {
			let frmPtr = [];
			data.results.forEach((dt) => {
				frmPtr.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmPtr });
		});
		Get('pekerjaan/list', null, (data) => {
			let frmPkr = [];
			data.results.forEach((dt) => {
				frmPkr.push({ value: dt.uuid, label: dt.nama });
			});
			this.setState({ frmPkr });
		});
		Get('select-enum/penduduk/status_penduduk', null, (data) => {
			let frmStp = [];
			data.forEach((dt) => {
				frmStp.push({ value: dt, label: dt });
			});
			this.setState({ frmStp });
		});
		Get('select-enum/penduduk/jk', null, (data) => {
			let frmJk = [];
			data.forEach((dt) => {
				frmJk.push({ value: dt, label: dt });
			});
			this.setState({ frmJk });
		});
		Get('select-enum/penduduk/status_perkawinan', null, (data) => {
			let frmSpk = [];
			data.forEach((dt) => {
				frmSpk.push({ value: dt, label: dt });
			});
			this.setState({ frmSpk });
		});
		Get('select-enum/berkas_verifikasi/jenis', null, (data) => {
			let frmJbk = [];
			data.forEach((dt) => {
				frmJbk.push({ value: dt, label: dt });
			});
			this.setState({ frmJbk });
		});
		this.setState({
			frmSbk: [ { value: 0, label: 'Belum Verifikasi' }, { value: 1, label: 'Sudah Verifikasi' } ],
			statusberkas: [ 'Belum Verifikasi', 'Sudah Verifikasi' ],
			frmSkh: [ { value: 1, label: 'Hidup' }, { value: 0, label: 'Meninggal' }, { value: 2, label: 'Tidak Aktif' } ]
		});
	}

	exportPenduduk = () => {
		//console.log(this.state.tingkatStat);
		//console.log(this.state.detailStat);

		if (this.state.tingkatStat === null || this.state.detailStat === null) {
			this.setState({
				show: true,
				basicType: 'danger',
				basicTitle: 'Export Data Penduduk',
				pesanAlert: 'Data tidak boleh kosong'
			});
		} else if (this.state.detailStat.length === 0) {
			this.setState({
				show: true,
				basicType: 'danger',
				basicTitle: 'Export Data Penduduk',
				pesanAlert: 'Data tidak boleh kosong'
			});
		} else {
			if (this.state.tingkatStat.value === 'rt') {
				Get('cetak-penduduk/rt/' + this.state.detailStat.value, null, (dtkat) => {
					setTimeout(function() {
						const mywindow = window.open('', 'Print', 'height=600,width=1000');
						mywindow.document.write(dtkat);
						mywindow.document.close();
						setTimeout(function() {
							mywindow.focus();
							mywindow.print();
						}, 1000);

						setTimeout(function() {
							mywindow.close();
						}, 1000);
					}, 100);
				});
			}
			if (this.state.tingkatStat.value === 'rw') {
				Get('cetak-penduduk/rw/' + this.state.detailStat.value, null, (dtkat) => {
					setTimeout(function() {
						const mywindow = window.open('', 'Print', 'height=600,width=1000');
						mywindow.document.write(dtkat);
						mywindow.document.close();
						setTimeout(function() {
							mywindow.focus();
							mywindow.print();
						}, 1000);

						setTimeout(function() {
							mywindow.close();
						}, 1000);
					}, 100);
				});
			}
			if (this.state.tingkatStat.value === 'dusun') {
				Get('cetak-penduduk/dusun/' + this.state.detailStat.value, null, (dtkat) => {
					setTimeout(function() {
						const mywindow = window.open('', 'Print', 'height=600,width=1000');
						mywindow.document.write(dtkat);
						mywindow.document.close();
						setTimeout(function() {
							mywindow.focus();
							mywindow.print();
						}, 1000);

						setTimeout(function() {
							mywindow.close();
						}, 1000);
					}, 100);
				});
			}
		}
	};
	render() {
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			id: 0,
			idbaru: '',
			id_kk: '',
			nama: '',
			alamat: '',
			id_keldes: '',
			tempat_lahir: '',
			tanggal_lahir: new Date(),
			jk: '',
			shdk: '',
			agama: '',
			pendidikan_terakhir: '',
			pekerjaan: '',
			nama_ibu_kandung: '',
			status_penduduk: '',
			status_perkawinan: ''
		};

		const databerkas = this.state.databerkas.map((dt, i) => {
			return (
				<tr key={i}>
					<td>{i + 1}</td>
					<td>
						<img alt="fotopenduduk" src={dt.link} style={{ maxWidth: '100px', maxHeight: '100px' }} />
					</td>
					<td>{dt.jenis}</td>
					<td>{dt.status ? 'Sudah Verifikasi' : 'Belum Verifikasi'}</td>
					<td>
						<Button size="xs" onClick={() => this.ubahBerkas(dt)} color="success" className="btn-icon">
							<i className="fa fa-pencil" />
						</Button>
						<Button
							size="xs"
							onClick={() => this.konfirmHapusBerkas(dt.uuid, dt.nik)}
							color="danger"
							className="btn-icon"
						>
							<i className="fa fa-trash" />
						</Button>
					</td>
				</tr>
			);
		});

		// const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'id',
				text: 'NIK',
				sort: false
			},
			{
				dataField: 'id_kk',
				text: 'No KK',
				sort: false
			},
			{
				dataField: 'nama_keldes',
				text: 'Kelurahan/' + JENIS_DESA,
				isDummyField: true,
				formatter: (cell, obj) => {
					return obj.data_keldes ? obj.data_keldes.nama : '-';
				},
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama Lengkap',
				sort: true
			},
			{
				dataField: 'alamat',
				text: 'Alamat',
				sort: true
			},
			// {
			//     dataField: 'tempat_lahir',
			//     text: 'Tempat Lahir',
			//     sort: true
			// },
			// {
			//     dataField: 'tanggal_lahir',
			//     text: 'Tanggal Lahir',
			//     sort: true
			// },
			// {
			//     dataField: 'status_perkawinan',
			//     text: 'Status Perkawinan',
			//     sort: true
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		// const defaultSorted = [
		// 	{
		// 		dataField: 'nama',
		// 		order: 'asc'
		// 	}
		// ];

		// const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
		// 	const handleClick = (e) => {
		// 		e.preventDefault();
		// 		this.fetch({ where: [], page: page });
		// 	};
		// 	var classname = '';
		// 	classname = 'btn btn-success';
		// 	return (
		// 		<li className="page-item pl-1" key={page}>
		// 			<a href="#" onClick={handleClick} className={classname}>
		// 				{page}
		// 			</a>
		// 		</li>
		// 	);
		// };

		// const options = {
		// 	alwaysShowAllBtns: true,
		// 	pageButtonRenderer,
		// 	// custom: true,
		// 	paginationTotalRenderer: () => (
		// 		<span className="react-bootstrap-table-pagination-total">
		// 			Showing {from} to {to} of {total} Results
		// 		</span>
		// 	),
		// 	paginationSize: size,
		// 	totalSize: total
		// };

		return (
			<div>
				{/* <Breadcrumb title="Data Penduduk" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											{/* <h5>Data Penduduk</h5> */}
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square mr-3"
												onClick={() => {
													this.setState({
														judul: 'Tambah Penduduk Luar Kota',
														dt: frmDef,
														prvStat: null,
														kbktStat: null,
														kecStat: null,
														kldsStat: null,
														shdkStat: null,
														ptrStat: null,
														pkrStat: null,
														agmStat: null,
														kkStat: null,
														spkStat: null,
														stpStat: null,
														wilayah: 'inherit'
													});
													this.bukaForm();
												}}
											>
												Tambah Data Luar Kota
											</Button>
											<Button
												size="sm"
												color="success"
												className="btn-square ml-3"
												onClick={() => {
													this.setState({
														judul: 'Tambah Penduduk Dalam Kota',
														dt: frmDef,
														prvStat: null,
														kbktStat: null,
														kecStat: null,
														// kldsStat: null,
														kldsStat: {value: ID_DESA, label: ID_DESA},
														shdkStat: null,
														ptrStat: null,
														pkrStat: null,
														agmStat: null,
														kkStat: null,
														spkStat: null,
														stpStat: null,
														wilayah: 'none'
													});
													this.bukaForm();
												}}
											>
												Tambah Data Dalam Kota
											</Button>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-lg-4">
											<input
												type="text"
												className="form-control"
												id="cariTable"
												placeholder="Cari Data berdasarkan nama pemohon, atau nomor surat"
												onKeyPress={this.cariData}
											/>
										</div>
									</div>
									<BootstrapTable keyField="id" data={data} columns={columns} />
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<p>
								Alamat pada data penduduk bisa jadi berbeda dengan alamat pada KK jika penduduk ybs
								sedang berdomisili di {this.state.wilayah !== 'none' ? 'luar' : 'dalam'} kota dalam
								rangka belajar atau bekerja untuk sementara waktu.
							</p>
							<input
								className="form-control"
								id="id"
								type="hidden"
								placeholder="Penduduk"
								defaultValue={this.state.dt.id}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									NIK
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="idbaru"
										type="text"
										placeholder="NIK"
										defaultValue={this.state.dt.idbaru}
										required
									/>
								</Col>
							</FormGroup>
							<div style={{ display: this.state.wilayah }}>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Provinsi
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.getChild(e, 'provinsi', 'prov', 'kab-kota');
											}}
											defaultValue={this.state.prvStat}
											value={this.state.prvStat}
											name="provinsi"
											options={this.state.frmPrv}
											placeholder="Pilih Provinsi"
											isClearable
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kabupaten / Kota
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
											}}
											defaultValue={this.state.kbktStat}
											value={this.state.kbktStat}
											name="kabkota"
											options={this.state.frmKbkt}
											placeholder="Pilih Kabupaten/Kota"
											isClearable
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kecamatan
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.getChild(e, 'kecamatan', 'kec', 'keldes');
											}}
											defaultValue={this.state.kecStat}
											value={this.state.kecStat}
											name="kecamatan"
											options={this.state.frmKec}
											placeholder="Pilih Kecamatan"
											isClearable
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kelurahan / {JENIS_DESA}
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'keldes');
											}}
											defaultValue={this.state.kldsStat}
											value={this.state.kldsStat}
											name="keldes"
											options={this.state.frmKlds}
											placeholder={'Pilih Kelurahan/' + JENIS_DESA}
											isClearable
										/>
									</Col>
								</FormGroup>
							</div>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									No KK
								</Label>
								<Col sm="9">
									<Select
										id="noKKPenduduk"
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'kk');
										}}
										// onMenuClose={() => {
										// 	this.cekNomorKK()
										// 	// setTimeout(this.cekNomorKK(), 2000);
										// }}
										defaultValue={this.state.kkStat}
										value={this.state.kkStat}
										name="No KK"
										options={this.state.frmKk}
										placeholder="Pilih KK"
										onInputChange={(e) => this.handleInputChangeNIK(e, 'kk')}
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									SHDK
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'shdk');
										}}
										defaultValue={this.state.shdkStat}
										value={this.state.shdkStat}
										name="SHDK"
										options={this.state.frmShdk}
										placeholder="Pilih SHDK"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Agama
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'agama');
										}}
										defaultValue={this.state.agmStat}
										value={this.state.agmStat}
										name="Agama"
										options={this.state.frmAgm}
										placeholder="Pilih Agama"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pendidikan Terakhir
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'pendidikan-terakhir');
										}}
										defaultValue={this.state.ptrStat}
										value={this.state.ptrStat}
										name="Pendidikan"
										options={this.state.frmPtr}
										placeholder="Pilih Pendidikan Terakhir"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pekerjaan
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'pekerjaan');
										}}
										defaultValue={this.state.pkrStat}
										value={this.state.pkrStat}
										name="Pekerjaan"
										options={this.state.frmPkr}
										placeholder="Pilih Pekerjaan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Lengkap
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Lengkap"
										defaultValue={this.state.dt.nama}
										required
									/> 
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat
								</Label>
								<Col sm="9">
									{/* <CKEditors
                                        activeClass="p10"
                                        content={this.state.dt.alamat}
                                        events={{
                                            "blur": this.onBlur,
                                            "afterPaste": this.afterPaste,
                                            "change": this.onChange
                                        }}
                                    /> */}
									<textarea
										className="form-control"
										id="alamat"
										placeholder="Alamat"
										defaultValue={this.state.dt.alamat}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Jenis Kelamin
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'jk');
										}}
										defaultValue={this.state.jkStat}
										value={this.state.jkStat}
										name="Jenis Kelamin"
										options={this.state.frmJk}
										placeholder="Pilih Jenis Kelamin"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										defaultValue={this.state.dt.tempat_lahir}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<Datetime
										locale="id-ID"
										input={true}
										timeFormat={false}
										closeOnTab={true}
										closeOnSelect={true}
                    dateFormat="DD/MM/yyyy"
										initialValue={new Date(this.state.tanggal_lahir)}
										onChange={(e) => {
											this.gantiTgl(e, 'kk');
											// this.setState({ tanggal_lahirkk:})
											// //console.log(e.format('YYYY-MM-DD'))
										}}
										inputProps={{ required: true }}
									/>
									{/* <input className="form-control" id="tanggal_lahir" type="date" placeholder="Tempat Lahir" defaultValue={this.state.dt.tanggal_lahir} required /> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Status Perkawinan
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'status-perkawinan');
										}}
										defaultValue={this.state.spkStat}
										value={this.state.spkStat}
										name="Status Perkawinan"
										options={this.state.frmSpk}
										placeholder="Pilih Status Perkawinan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Status Penduduk
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'status-penduduk');
										}}
										defaultValue={this.state.stpStat}
										value={this.state.stpStat}
										name="Status Penduduk"
										options={this.state.frmStp}
										placeholder="Pilih Status Penduduk"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Status
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'status-kehidupan');
										}}
										defaultValue={this.state.skhStat}
										value={this.state.skhStat}
										name="Status Penduduk"
										options={this.state.frmSkh}
										placeholder="Pilih Status Penduduk"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Ibu Kandung
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_ibu_kandung"
										type="text"
										placeholder="Nama Ibu Kandung"
										defaultValue={this.state.dt.nama_ibu_kandung}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Ayah Kandung
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_ayah_kandung"
										type="text"
										placeholder="Nama Ayah Kandung"
										defaultValue={this.state.dt.nama_ayah_kandung}
										required
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
				<Modal
					open={this.state.status.formBerkas}
					onClose={this.tutupFormBerkas}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<Form className="theme-form" onSubmit={this.simpanBerkas}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judulBerkas}</h5>
						</div>
						<div className="modal-body">
							<p>
								Berkas yang sudah disimpan akan berfungsi sebagai persyaratan saat pengajuan permohonan
								layanan. Sehingga warga tidak perlu mengupload berkas setiap kali mengajukan permohonan
								layanan.
							</p>
							<input
								className="form-control"
								id="id_berkas"
								type="hidden"
								placeholder="Penduduk"
								value={this.state.berkas.uuid}
							/>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Jenis Berkas
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'jenis-berkas');
										}}
										defaultValue={this.state.jbkStat}
										value={this.state.jbkStat}
										name="Jenis Berkas"
										options={this.state.frmJbk}
										placeholder="Pilih Jenis Berkas"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Status Berkas
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'status-berkas');
										}}
										defaultValue={this.state.sbkStat}
										value={this.state.sbkStat}
										name="Status Berkas"
										options={this.state.frmSbk}
										placeholder="Pilih Status Berkas"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Upload Foto/Scan
								</Label>
								<Col sm="9">
									<Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)} maxFiles={1}>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													{...getRootProps()}
													style={{
														minHeight: '100px',
														paddingTop: '40px',
														padding: '5px 5px 5px 5px',
														border: '1px solid #d2d2d2'
													}}
												>
													<input {...getInputProps()} />
													<p>
														{this.state.foto_berkas ? (
															<img
																alt="fotoberkas"
																src={this.state.foto_berkas}
																style={{ minHeight: '50px', minWidth: '50px' }}
															/>
														) : (
															"Drag 'n' drop some files here, or click to select files"
														)}
														{this.state.foto_berkas ? this.state.foto_info : ''}
													</p>
												</div>
											</section>
										)}
									</Dropzone>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<div className="col-lg-12 text-right">
									<button
										type="button"
										className="btn btn-primary mr-3"
										disabled={this.state.status.btnForm}
										onClick={this.kosongBerkas}
									>
										Kosongkan
									</button>
									<button
										type="submit"
										className="btn btn-success ml-3"
										disabled={this.state.status.btnForm}
									>
										Simpan
									</button>
								</div>
							</FormGroup>
							<FormGroup className="row">
								<Col sm="12">
									<table className="table table-bordered table-stripes table-hovered">
										<thead>
											<tr>
												<th>No</th>
												<th>Gambar Berkas</th>
												<th>Jenis Berkas</th>
												<th>Status</th>
												<th>Aksi</th>
											</tr>
										</thead>
										<tbody>{databerkas}</tbody>
									</table>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupFormBerkas}
							>
								Tutup
							</button>
						</div>
					</Form>
				</Modal>

				<Modal
					open={this.state.status.formExport}
					onClose={this.tutupFormExport}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpanBerkas}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judulExport}</h5>
						</div>
						<div className="modal-body">
							<p>Export data penduduk</p>
							<input
								className="form-control"
								id="id_berkas"
								type="hidden"
								placeholder="Penduduk"
								value={this.state.berkas.uuid}
							/>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tingkat
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getDetail(e, 'tingkat');
										}}
										defaultValue={this.state.tingkatStat}
										value={this.state.tingkatStat}
										name="Tingkat"
										options={this.state.frmTingkat}
										placeholder="Pilih Tingkat"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Detail
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'detail');
										}}
										defaultValue={this.state.detailStat}
										value={this.state.detailStat}
										name="Detail"
										options={this.state.frmDetail}
										placeholder="Pilih Detail"
										isClearable
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupFormExport}
							>
								Tutup
							</button>
							<button
								type="button"
								className="btn btn-success"
								disabled={this.state.status.btnForm}
								onClick={this.exportPenduduk}
							>
								Export
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Penduduk;

import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
// import axios from 'axios';

// import Select from 'react-select';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, } from '../function/Koneksi';
import { DataToken } from '../function/Format';

//slider
import Slider from 'react-slick';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');

const frmDef = {
	uuid: '',
	link_gambar: ''
};

// function imageFormatter(cell, row) {
// 	// return "<img src='"+cell+"'/>" ;
// 	return (
// 		<div>
// 			<img src={cell} alt="" style={{ width: '250px' }} />
// 		</div>
// 	);
// }

class Banner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_loading: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				formLoading: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDef,
			judul: 'Tambah Banner Desa',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			konten: false,
			content: '',
			token: DataToken(localStorage.getItem('access_token')),

			// BANNER
			foto_desa: '',
			link_gambar: '',
			link_gambar_show: null,
			get_link_gambar: null,
			data_cek: '',

			// LOADING CAMPAIGN
			foto_desaLoading: '',
			link_gambar_loading: '',
			link_gambar_show_loading: null,
			link_web: null,
			get_link_gambar_loading: null,
			data_cek_loading: '',

			active_tab_icon: '1'
		};
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ========================================================================

	/// upload file Banner

	processGbr = (d) => {
		if (d.length === 1) {
			let type = d[0].type.split('/');
			if (
				type[1] === 'jpg' ||
				type[1] === 'jpeg' ||
				type[1] === 'png' ||
				type[1] === 'bmp' ||
				type[1] === 'tiff' ||
				type[1] === 'webp'
			) {
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				Post('upload-file/banner', null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
							this.setState({
								foto_desa: uri,
								foto_info: '',
								link_gambar: data.data.results
							});
						},
						'base64'
					);
				});
			} else {
				this.setState({
					foto_desa: '',
					foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
				}); 
			}
		} else {
			this.setState({
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
			});
		}
	};

	/// upload file Loading

	processGbrLoading = (d) => {
		if (d.length === 1) {
			let type = d[0].type.split('/');
			if (
				type[1] === 'jpg' ||
				type[1] === 'jpeg' ||
				type[1] === 'png' ||
				type[1] === 'bmp' ||
				type[1] === 'tiff' ||
				type[1] === 'webp'
			) {
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				Post('upload-file/banner', null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
							this.setState({
								foto_desa_loading: uri,
								foto_info_loading: '',
								link_gambar_loading: data.data.results
							});
						},
						'base64'
					);
				});
			} else {
				this.setState({
					foto_desa_loading: '',
					foto_info_loading: <div className="text-danger font-size-10">Type file tidak valid</div>
				});
			}
		} else {
			this.setState({
				foto_desa_loading: '',
				foto_info_loading: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
			});
		}
	};

	// ================== simpan data banner==================
	simpan = (e) => {
		e.preventDefault();

		let link_loading = null;

		if (this.state.data_cek_loading === 'Kosong') {
			link_loading = JSON.stringify([]);
			// link_loading = '[]';
		} else {
			link_loading = JSON.stringify(this.state.link_gambar_show_loading);
		}

		let link_lama = JSON.parse(this.state.get_link_gambar);
		let link_baru = this.state.link_gambar;
		let newGetLinkALL = [];

		if (this.state.link_gambar === '') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Banner Desa',
				pesanAlert: 'Silahkan lengkapi data!'
			});
		} else {
			this.state.status.btnForm = true;
			this.forceUpdate();
			let linknya = '';
			let psn = '';
			let resstat = '204';
			let metode = '';
			let uuidnya = '';
			if (link_lama === null) {
				// linknya = this.state.link_gambar;
				// newGetLinkALL = [ link_baru ];
				// JSON.stringify(newGetLinkALL);

				let linkStr = JSON.stringify([ link_baru ]);

				linknya = linkStr;
				metode = 'create';
				psn = 'Tambah';
				resstat = 201;
				uuidnya = null;
			} else {
				// linknya = '' + this.state.link_gambar + ',' + this.state.link_gambar_show + '';
				newGetLinkALL = [ ...link_lama, link_baru ];

				let linkStr = JSON.stringify(newGetLinkALL);

				linknya = linkStr;
				metode = 'update';
				psn = 'Ubah';
				resstat = 200;
				uuidnya = this.state.data.uuid;
			}

			let addData = {
				uuid: uuidnya,
				link: linknya,
				link_loading: link_loading,
				link_web: this.state.link_web,
			};

			Post('banner/' + metode, addData.uuid, addData, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Banner Desa',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.componentDidMount();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Banner Desa',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					this.componentDidMount();
				}
			});
		}
	};

	// ================== simpan data loading ==================
	simpanLoading = (e) => {
		e.preventDefault();

		let link_banner = null;

		if (this.state.data_cek === 'Kosong') {
			link_banner = JSON.stringify([]);
			// link_loading = '[]';
		} else {
			link_banner = JSON.stringify(this.state.link_gambar_show);
		}

		let link_lama = JSON.parse(this.state.get_link_gambar_loading);
		let link_baru = this.state.link_gambar_loading;
		let newGetLinkALL = [];

		if (this.state.link_gambar_loading === '') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Banner Desa',
				pesanAlert: 'Silahkan lengkapi data!'
			});
		} else {
			this.state.status.btnForm = true;
			this.forceUpdate();
			let linknya = '';
			let psn = '';
			let resstat = '204';
			let metode = '';
			let uuidnya = '';
			if (this.state.data.uuid === null) {
				// linknya = this.state.link_gambar;
				let linkStr = JSON.stringify([ link_baru ]);

				linknya = linkStr;
				metode = 'create';
				psn = 'Tambah';
				resstat = 201;
				uuidnya = null;
			} else {
				// linknya = '' + this.state.link_gambar + ',' + this.state.link_gambar_show + '';
				newGetLinkALL = [ ...link_lama, link_baru ];

				let linkStr = JSON.stringify(newGetLinkALL);

				linknya = linkStr;
				metode = 'update';
				psn = 'Ubah';
				resstat = 200;
				uuidnya = this.state.data.uuid;
			}

			let addData = {
				uuid: uuidnya,
				link: link_banner,
				link_loading: linknya,
				link_web: this.state.link_web,
			};

			Post('banner/' + metode, addData.uuid, addData, (res) => {
				this.state.status.btnForm = false;
				this.state.status.form = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Loading Campaign',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.state.status.formLoading = false;
					this.componentDidMount();
					this.forceUpdate();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Loading Campaign',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					this.state.status.formLoading = false;
					this.componentDidMount();
					this.forceUpdate();
				}
			});
		}
	};
	// ========================================================================

	// ============================== Ubah Data ===============================
	ubahData = (id) => {
		let link_gambar_ubah = this.state.link_gambar

		this.state.status.btnAksi = true;
		this.state.judul = 'Ubah Banner Desa';
		this.state.dt.uuid = this.state.data.uuid;
		this.state.link_gambar = link_gambar_ubah;
		this.forceUpdate();

		this.bukaForm();
	};
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let linknya = '';
		let psn = '';
		let resstat = '204';
		let metode = '';
		let uuidnya = '';

		let link_loading = null;

		if (this.state.data_cek_loading === 'Kosong') {
			link_loading = JSON.stringify([]);
			// link_loading = '[]';
		} else {
			link_loading = JSON.stringify(this.state.link_gambar_show_loading);
		}

		let newArray = [ ...this.state.link_gambar_show, id ];
		if (this.state.link_gambar_show.includes(id)) {
			newArray = newArray.filter((day) => day !== id);
		}

		let linkStr = JSON.stringify(newArray);

		linknya = linkStr;
		metode = 'update';
		psn = 'Hapus';
		resstat = 200;
		uuidnya = this.state.data.uuid;

		let addData = {
			uuid: uuidnya,
			link: linknya,
			link_loading: link_loading,
			link_web: this.state.link_web,
		};

		Post('banner/' + metode, addData.uuid, addData, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Banner Desa',
					pesanAlert: 'Berhasil ' + psn + ' Gambar'
				});
				this.componentDidMount();
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Banner Desa',
					pesanAlert: 'Gagal ' + psn + ' Gambar'
				});
				this.componentDidMount();
			}
		});

		this.forceUpdate();
	};

	konfirmHapusLoading = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusDataLoading(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusDataLoading = (id) => {
		this.hideAlert();
		let linknya = '';
		let psn = '';
		let resstat = '204';
		let metode = '';
		let uuidnya = '';

		let link_banner = null;

		if (this.state.data_cek === 'Kosong') {
			link_banner = JSON.stringify([]);
		} else {
			link_banner = JSON.stringify(this.state.link_gambar_show);
		}

		let newArray = [ ...this.state.link_gambar_show_loading, id ];
		if (this.state.link_gambar_show_loading.includes(id)) {
			newArray = newArray.filter((day) => day !== id);
		}

		let linkStr = JSON.stringify(newArray);

		linknya = linkStr;
		metode = 'update';
		psn = 'Hapus';
		resstat = 200;
		uuidnya = this.state.data.uuid;

		let addData = {
			uuid: uuidnya,
			link: link_banner,
			link_loading: linknya,
			link_web: this.state.link_web,
		};

		Post('banner/' + metode, addData.uuid, addData, (res) => {
			this.state.status.btnForm = false;
			this.state.status.form = false;
			this.forceUpdate();
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Loading Campaign',
					pesanAlert: 'Berhasil ' + psn + ' Gambar'
				});
				this.componentDidMount();
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Loading Campaign',
					pesanAlert: 'Gagal ' + psn + ' Gambar'
				});
				this.componentDidMount();
			}
		});
		this.forceUpdate();
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.status.formLoading = false;

		this.state.status.form = false;
		this.forceUpdate();
	};

	componentDidMount() {
		if (typeof this.props.location.tabProps !== 'undefined') {
			this.state.active_tab_icon = this.props.location.tabProps;
		}
		if (typeof this.props.location.state !== 'undefined') {
			this.state.active_tab_icon = this.props.location.state.tab;
		}

		Get('banner', null, (dtkat) => {
			if (dtkat.results === null || dtkat.results === 'undefined') {
				this.setState({
					data_cek: 'Kosong'
				});
			} else {
				let statData = '';
				let ambil = JSON.parse(dtkat.results.link);
				// let dataSplit = ambil.split(',');

				if (dtkat.results.link === null || dtkat.results.link === '[]') {
					statData = 'Kosong';
				} else {
					statData = 'Tidak Kosong';
				}
				this.setState({
					data: dtkat.results,
					link_gambar_show: ambil,
					get_link_gambar: dtkat.results.link,
					data_cek: statData
				});
			}
		});

		Get('banner', null, (dtkat) => {
			if (dtkat.results === null || dtkat.results === 'undefined') {
				this.setState({
					data_cek_loading: 'Kosong'
				});
			} else {
				let statData = '';
				let ambil = JSON.parse(dtkat.results.link_loading);
				// let dataSplit = ambil.split(',');

				if (dtkat.results.link_loading === null || dtkat.results.link_loading === '[]') {
					statData = 'Kosong';
				} else {
					statData = 'Tidak Kosong';
				}
				this.setState({
					data_loading: dtkat.results,
					link_gambar_show_loading: ambil,
					get_link_gambar_loading: dtkat.results.link_loading,
					data_cek_loading: statData
				});
			}
		});

		Get('banner', null, (dtkat) => {
			if (dtkat.results) {
				this.setState({
					link_web: dtkat.results.link_web
				});
			}
		});
	}

	render() {
		let gambarList = [];
		let listGambarnya = this.state.link_gambar_show;

		if (this.state.link_gambar_show) {
			gambarList = listGambarnya.map((dt) => {
				return (
					<div>
						<div className="p-2" align="center">
							{/* <img className="p-2" style={sliderStyle} src={dt} alt="" /> */}
							<img className="p-2" style={{ width: '500px' }} src={dt} alt="" />
						</div>
						<div align="center">
							{/* <Button
						size="md"
						color="success"
						className="btn-icon"
						onClick={() => this.ubahData(this.state.data.uuid)}
					>
						<i className="fa fa-pencil" />
						&nbsp; Ubah
					</Button>{' '}
					&nbsp;&nbsp; */}
							<Button size="md" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(dt)}>
								<i className="fa fa-trash" />
								&nbsp; Hapus
							</Button>
						</div>
					</div>
				);
			});
		}

		let gambarListLoading = [];
		let listGambarnyaLoading = this.state.link_gambar_show_loading;

		if (this.state.link_gambar_show_loading) {
			gambarListLoading = listGambarnyaLoading.map((dt) => {
				return (
					<div>
						<div className="p-2" align="center">
							<img className="p-2" style={{ width: '200px' }} src={dt} alt="" />
						</div>
						<div align="center">
							<Button
								size="md"
								color="danger"
								className="btn-icon"
								onClick={() => this.konfirmHapusLoading(dt)}
							>
								<i className="fa fa-trash" />
								&nbsp; Hapus
							</Button>
						</div>
					</div>
				);
			});
		}

		// var size = Math.ceil(
		// 	this.state.pagination.total / this.state.pagination.per_page
		// );
		// // var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		// var data = this.state.data;
		const frmDef = {
			uuid: '',
			link_gambar: ''
		};

		// const sliderStyle = {
		// 	margin: '0px auto',
		// 	width: '30%',
		// 	// maxWidth: '1280px',
		// 	maxWidth: '15px',
		// 	// height: '357px',
		// 	borderRadius: '21px',
		// 	objectFit: 'cover',
		// 	objectPosition: '50% 50%'
		// };

		var Merge_settings = {
			dots: true,
			infinite: true,
			speed: 100,
			slidesToShow: 1,
			slidesToScroll: 1,
			margin: 0,
			padding: 0
			// centerMode: true,
			// variableWidth: true,
			// adaptiveHeight:true,
		};


		return (
			<div>
				{/* <Breadcrumb title="Data Banner Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header" />

								<div className="card-body datatable-react">
									<Nav tabs className="tabs-color">
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '1'
												})}
												onClick={() => {
													this.toggle_icon('1');
												}}
											>
												<i className="icofont icofont-ui-home" />
												Banner Aplikasi Mobile
											</NavLink>
										</NavItem>
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '2'
												})}
												onClick={() => {
													this.toggle_icon('2');
												}}
											>
												<i className="icofont icofont-man-in-glasses" />
												Loading Campaign
											</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="1">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<div className="row">
															<div className="col-sm-6">
																{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
															</div>
															<div className="col-sm-6 text-right">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Banner Desa',
																			dt: frmDef,
																			link_gambar: ''
																		});
																		this.bukaForm();
																	}}
																>
																	Tambah Data
																</Button>
															</div>
														</div>

														<div className="row">
															{/* <Col md={1} /> */}
															<Col md={12}>
																{this.state.data_cek === 'Kosong' ? (
																	<div>
																		<h3 align="center">Data Banner Belum Ada</h3>
																		<br />
																		<br />
																	</div>
																) : (
																	<Slider {...Merge_settings}>{gambarList}</Slider>
																)}
															</Col>
														</div>
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="2">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<div className="row">
															<div className="col-sm-6" />
															<div className="col-sm-6 text-right">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Loading Campaign',
																			dt: frmDef,
																			link_gambar_loading: ''
																		});
																		this.state.status.formLoading = true;
																		this.forceUpdate();
																	}}
																>
																	Tambah Data
																</Button>
															</div>
														</div>

														<div className="row">
															<Col md={12}>
																{this.state.data_cek_loading === 'Kosong' ? (
																	<div>
																		<h3 align="center">
																			Data Loading Campaign Belum Ada
																		</h3>
																		<br />
																		<br />
																	</div>
																) : (
																	<Slider {...Merge_settings}>
																		{gambarListLoading}
																	</Slider>
																)}
															</Col>
														</div>
													</Col>
												</Row>
											</div>
										</TabPane>
									</TabContent>
								</div>

								{/* <div className="card-body datatable-react">
									{this.state.data_cek === 'Kosong' ? (
										<div>
											<h3 align="center">Data Banner Belum Ada</h3>
											<br />
											<br />
										</div>
									) : (
										<div>
											<img src={this.state.link_gambar} alt="" style={{ width: '100%' }} />
											<br />
											<div>
												&nbsp;<br />
											</div>
											<div align="center">
												<Button
													size="md"
													color="success"
													className="btn-icon"
													onClick={() => this.ubahData(this.state.data.uuid)}
												>
													<i className="fa fa-pencil" />
													&nbsp; Ubah
												</Button>{' '}
												&nbsp;&nbsp;
												<Button
													size="md"
													color="danger"
													className="btn-icon"
													onClick={() => this.konfirmHapus(this.state.data.uuid)}
												>
													<i className="fa fa-trash" />
													&nbsp; Hapus
												</Button>
											</div>
										</div>
									)}
								</div>
							 */}
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Foto Banner
								</Label>
								<Col sm="9">
									<Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)} maxFiles={12}>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													{...getRootProps()}
													style={{
														minHeight: '100px',
														paddingTop: '40px',
														padding: '5px 5px 5px 5px',
														border: '1px solid #d2d2d2'
													}}
												>
													<input {...getInputProps()} />
													<div
														style={{
															justifyContent: 'center',
															alignItems: 'center'
														}}
													>
														<p className="text-center">
															{this.state.link_gambar ? (
																<img
																	src={this.state.link_gambar}
																	alt=""
																	style={{
																		// minHeight: '50px',
																		// minWidth: '50px'
																		width: '470px'
																	}}
																/>
															) : (
																<Label
																	className="col-form-label text-center"
																	htmlFor="inputEmail3"
																>
																	Drag 'n' drop some files here, or click to select
																	files
																</Label>
															)}
															{this.state.foto_info ? this.state.foto_info : ''}
														</p>
													</div>
												</div>
											</section>
										)}
									</Dropzone>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>

				<Modal
					open={this.state.status.formLoading}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpanLoading}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Foto Banner
								</Label>
								<Col sm="9">
									<Dropzone
										onDrop={(acceptedFiles) => this.processGbrLoading(acceptedFiles)}
										maxFiles={12}
									>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													{...getRootProps()}
													style={{
														minHeight: '100px',
														paddingTop: '40px',
														padding: '5px 5px 5px 5px',
														border: '1px solid #d2d2d2'
													}}
												>
													<input {...getInputProps()} />
													<div
														style={{
															justifyContent: 'center',
															alignItems: 'center'
														}}
													>
														<p className="text-center">
															{this.state.link_gambar_loading ? (
																<img
																	src={this.state.link_gambar_loading}
																	alt=""
																	style={{
																		// minHeight: '50px',
																		// minWidth: '50px'
																		width: '200px'
																	}}
																/>
															) : (
																<Label
																	className="col-form-label text-center"
																	htmlFor="inputEmail3"
																>
																	Drag 'n' drop some files here, or click to select
																	files
																</Label>
															)}
															{this.state.foto_info_loading ? (
																this.state.foto_info_loading
															) : (
																''
															)}
														</p>
													</div>
												</div>
											</section>
										)}
									</Dropzone>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Banner;
import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
import {
	Button,
	FormGroup,
	Label,
  Col,
} from "reactstrap";
// import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

// koneksi
import API_URL, { Get } from "../function/Koneksi";
import { Tanggal, } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import { Nav, NavItem, TabContent, TabPane } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
// import classnames from 'classnames';
import Datetime from 'react-datetime';

var moment = require('moment');

const dateFormat = require('dateformat');

class LaporanPotensi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			judul: "",
			alert: null,
			show: false,
			basicTitle: "",
			basicType: "default",
      pesanAlert: "",
      // Datatable pagination
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      pagination: [],
      // 
      jenisLaporan: null,
      listFilter: [],
      // maxFilter: 1,
      dataLaporan: [],
			columnsTable: [],
			// 
			urlActive: '',
			extensionUrlActive: '',
			// 
      optionJenisSumberAir: [],
      optionStatusSumberAir: [],
			optionJenisKepemilikanRumah: [],
			optionJenisKepemilikanLahan: [],
			optionStatusKepemilikanLahan: [],
			optionJenisKomoditas: [],
			optionKomoditas: [],
			optionJenisAset: [],
			optionAset: [],
			optionAsetPilihan: [],
			optionJenisAsetPilihan: [],
			optionJenisGalian: [],
			optionAgama: [],
			optionPekerjaan: [],
			optionUsia: [],
			optionPendidikan: [],
			optionStatusPerkawinan: [],
			optionEtnis: [],
			// Loading
			loading: true
		};

	}

	componentDidMount() {
		this.getFilterOptions()
  }

  fetch = (params = {}) => {
		let link = '';
		// let where = [];
		// if (params.where) {
		// 	where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}
		if (params.url) {
			Get(params.url + link, null, (dtkat) => {

				this.setState({
					dataLaporan: [],
				})
				// this.state.pagination_detail = dtkat.results;
				this.forceUpdate();

				this.setState({ 
					dataLaporan: dtkat.results.data, 
					pagination: dtkat.results, 
					// extensionUrlActive: params.url
				 });
				this.pagination(dtkat.results, params.url);
			});
		}
	};
  
  pagination = (data, url) => {
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';

		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i === data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						href={() => false}
						key={i}
						onClick={() => this.fetch({ page: i, url: url })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: p, url: url })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: n, url: url })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: 1, url: url })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: data.last_page, url: url })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	getFilterOptions = () => {
		// Get list jenis sumber air minum
    Get('master-sumber-air-minum/list', null, (data) => {
			let optionJenisSumberAir = [];
			data.results.forEach((e, index) => {
				optionJenisSumberAir.push({
					value: e.uuid,
					label: e.nama
				});
			})
      this.setState({ optionJenisSumberAir });
			//console.log(optionJenisSumberAir);

			// Status Sumber Air
			Get('select-enum/sumber_air_minum/status', null, (data) => {
				let optionStatusSumberAir = [];
				data.forEach((e) => {
					optionStatusSumberAir.push({
						value: e,
						label: e
					});
				})
				this.setState({ optionStatusSumberAir });
				//console.log(optionStatusSumberAir);
			});
		});
		// Get list jenis kepemilikan rumah
		Get('master-kepemilikan-rumah/list', null, (data) => {
			let optionJenisKepemilikanRumah = [];
			data.results.forEach((e, index) => {
				optionJenisKepemilikanRumah.push({
					value: e.uuid,
					label: e.nama_status
				});
			})
			this.setState({ optionJenisKepemilikanRumah });
			//console.log(optionJenisKepemilikanRumah);
		});
		// Get list jenis kepemilikan lahan
		Get('master-kepemilikan-lahan/list', null, (data) => {
			let optionJenisKepemilikanLahan = [];
			data.results.forEach((e) => {
				optionJenisKepemilikanLahan.push({
					value: e.uuid,
					label: e.nama
				});
			});
			this.setState({ optionJenisKepemilikanLahan });
			//console.log(optionJenisKepemilikanLahan);
			
			// Get list status Kepemilikan lahan
			Get('select-enum/kepemilikan_lahan/status', null, (data2) => {
				let optionStatusKepemilikanLahan = [];
				data2.forEach((e) => {
					optionStatusKepemilikanLahan.push({
						value: e,
						label: e
					});
				});
	
				this.setState({ optionStatusKepemilikanLahan });
				//console.log(optionStatusKepemilikanLahan);

			});
		});
		// Get list jenis komoditas
		Get('master-jenis-komoditas/list', null, (data) => {
			let optionJenisKomoditas = [];
			data.results.forEach((e) => {
				optionJenisKomoditas.push({
					value: e.uuid,
					label: e.nama
				});
			});
			this.setState({ optionJenisKomoditas });
			//console.log(optionJenisKomoditas);

			// Get list komoditas
			Get('master-komoditas/list', null, (data) => {
				let optionKomoditas = [];
				data.results.forEach((e) => {
					optionKomoditas.push({
						value: e.uuid,
						label: e.nama
					});
				});
				this.setState({ optionKomoditas });
				//console.log(optionKomoditas);
			});
		});
		// Get list aset dan jenis aset masyarakat
		Get('kategori-aset/list', null, (data) => {
			let optionAset = [];
			data.results.forEach((e) => {
				optionAset.push({
					value: e.nama,
					label: e.nama
				});
			});
			this.setState({ optionAset });
			//console.log(optionAset);

			// Get jenis aset
			Get('kategori-aset/list/jenis', null, (data) => {
				let optionJenisAset = [];
				data.results.forEach((e) => {
					optionJenisAset.push({
						// value: e.uuid,
						value: e.jenis,
						label: e.jenis
					});
				});
				this.setState({ optionJenisAset });
				//console.log(optionJenisAset);
			});

		});
		// Get list aset dan jenis aset masyarakat
		Get('kategori-aset-pilihan/list', null, (data) => {
			let optionAsetPilihan = [];
			data.results.forEach((e) => {
				optionAsetPilihan.push({
					value: e.nama,
					label: e.nama
				});
			});
			this.setState({ optionAsetPilihan });
			//console.log(optionAsetPilihan);

			// Get jenis aset
			Get('kategori-aset-pilihan/list/jenis', null, (data) => {
				let optionJenisAsetPilihan = [];
				data.results.forEach((e) => {
					optionJenisAsetPilihan.push({
						value: e.jenis,
						label: e.jenis
					});
				});
				this.setState({ optionJenisAsetPilihan });
				//console.log(optionJenisAsetPilihan);
	
			});

		});
		// Get list jenis kepemilikan lahan
		Get('master-produksi-galian/list', null, (data) => {
			let optionJenisGalian = [];
			data.results.forEach((e) => {
				optionJenisGalian.push({
					value: e.uuid,
					label: e.nama
				});
			});
			this.setState({ optionJenisGalian });
			//console.log(optionJenisGalian);
		});
		// Get list jenis lembaga ekonomi
		Get('master-lembaga-ekonomi/list', null, (data) => {
			let optionJenisLembagaEkonomi = [];
			data.results.forEach((e) => {
				optionJenisLembagaEkonomi.push({
					value: e.uuid,
					label: e.nama
				});
			});
			this.setState({ optionJenisLembagaEkonomi });
			//console.log(optionJenisLembagaEkonomi);
		});
		// Get list agama
		Get('agama/list', null, (data) => {
			let optionAgama = [];
			data.results.forEach((e) => {
				optionAgama.push({
					value: e.uuid,
					label: e.nama
				});
			});
			this.setState({ optionAgama });
			//console.log(optionAgama);
		});
		// Get list pekerjaan
		Get('pekerjaan/list', null, (data) => {
			let optionPekerjaan = [];
			data.results.forEach((e) => {
				optionPekerjaan.push({
					value: e.uuid,
					label: e.nama
				});
			});
			this.setState({ optionPekerjaan });
			//console.log(optionPekerjaan);
		});
		// Get list rentang umur
		Get('potensi-desa/rentang-umur-masyarakat', null, (data) => {
			let optionUsia = [];
			Object.keys(data.results).forEach((attribute) => {
				let labelUsia = attribute.replace('_', ' ')
				optionUsia.push({
					value: attribute,
					label: labelUsia.charAt(0).toUpperCase() + labelUsia.slice(1)
				});
			})
			this.setState({ optionUsia });
			//console.log(optionUsia);

		});
		// Get list pekerjaan
		Get('jenjang-pendidikan/list', null, (data) => {
			let optionPendidikan = [];
			data.results.forEach((e) => {
				optionPendidikan.push({
					value: e.uuid,
					label: e.nama
				});
			});
			this.setState({ optionPendidikan });
			//console.log(optionPendidikan);
		});
		// Get list status perkawinan
		Get('select-enum/penduduk/status_perkawinan', null, (data) => {
			let optionStatusPerkawinan = [];
			data.forEach((e) => {
				optionStatusPerkawinan.push({
					value: e,
					label: e
				});
			});
			this.setState({ optionStatusPerkawinan });
			//console.log(optionStatusPerkawinan);
		});
		// Get list etnis
		Get('potensi-desa/data-etnis', null, (data) => {
			//console.log(data);
			let optionEtnis = [];

			data.results.forEach((e) => {
				optionEtnis.push({
					value: e.data_etnis.uuid,
					label: e.data_etnis.nama
				});
			});

			this.setState({ optionEtnis });
			//console.log(optionEtnis);

		});
	}

  updateJenisLaporan(jenis_laporan = null) {
    // Jika jenis laporan kosong atau tidak ada
    if (jenis_laporan === null) {
			this.setState({ listFilter: [], urlActive: '', extensionUrlActive: '' })
			// this.getFilterOptions()
    } else {
			let filter = [];
			let columnsTable = [];
			let urlActive = "potensi-desa/data-masyarakat?";

      switch (jenis_laporan.toLowerCase()) {
        case 'sumber air minum':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_sumber",
							title: "Jenis Sumber Air Minum",
							option: this.state.optionJenisSumberAir,
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "status",
							title: "Status Air Minum",
							option: this.state.optionStatusSumberAir,
						},
					]

          // Set columns datatable
          columnsTable = [
            {
              dataField: 'data_penduduk.nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_sumber.nama',
              text: 'Sumber Air Minum',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'status',
              text: 'Status Air Minum',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/sumber-air-minum-masyarakat?" 

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
					
				case "kepemilikan rumah":
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_status",
							title: "Jenis Kepemilikan Rumah",
							option: this.state.optionJenisKepemilikanRumah,
						}
					]
					
					// Set columns datatable
          columnsTable = [
            {
              dataField: 'data_penduduk.nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_sumber_kepemilikan.nama_status',
              text: 'Jenis Kepemilikan Rumah',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" },
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/kepemilikan-rumah?"

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
        
				case "kepemilikan lahan":
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_kepemilikan",
							title: "Jenis Lahan",
							option: this.state.optionJenisKepemilikanLahan,
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "status",
							title: "Status",
							option: this.state.optionStatusKepemilikanLahan,
						}
					]
					
					// Set columns datatable
          columnsTable = [
            {
              dataField: 'data_penduduk.nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_sumber_kepemilikan.nama',
              text: 'Jenis Lahan',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'status',
              text: 'Keterangan',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/kepemilikan-lahan?"

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
        
				case "data produksi":
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_jenis_komoditas",
							title: "Jenis Komoditas",
							option: this.state.optionJenisKomoditas,
							// change merupakan list field yang masing2 optionnya diubah berdasarkan value dari value field saat ini
							change: "uuid_komoditas"
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_komoditas",
							title: "Komoditas",
							option: this.state.optionKomoditas,
						}
					]
					
					// Set columns datatable
          columnsTable = [
            {
              dataField: 'data_penduduk.nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_komoditas.data_jenis.nama',
              text: 'Jenis Komoditas',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_komoditas.nama',
              text: 'Nama Komoditas',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/komoditas-masyarakat?"

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
        
				case "data aset":
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "jenis_aset",
							title: "Jenis Aset",
							option: this.state.optionJenisAset,
							// change merupakan list field yang masing2 optionnya diubah berdasarkan value dari value field saat ini
							change: "nama_aset"
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "nama_aset",
							title: "Aset",
							option: this.state.optionAset,
						}
					]
					
					// Set columns datatable
          columnsTable = [
            {
              dataField: 'data_penduduk.nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_kategori.jenis',
              text: 'Jenis Aset',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_kategori.nama',
              text: 'Nama Aset',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/aset-masyarakat?"

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
        
				case "data aset lainnya":
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							// field: "jenis_aset_pilihan",
							field: "jenis_aset_pilihan",
							title: "Jenis Aset",
							option: this.state.optionJenisAsetPilihan,
							// change merupakan list field yang masing2 optionnya diubah berdasarkan value dari value field saat ini
							change: "nama_aset_pilihan"
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "nama_aset_pilihan",
							title: "Aset",
							option: this.state.optionAsetPilihan,
						},
					]
					
					// Set columns datatable
          columnsTable = [
            {
              dataField: 'data_penduduk.nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_kategori.jenis',
              text: 'Jenis Aset',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_kategori.nama',
              text: 'Nama Aset',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/aset-pilihan-masyarakat?"

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
				
				case 'produksi galian':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_produksi_galian",
							title: "Jenis Galian",
							option: this.state.optionJenisGalian,
						},
					]

					// Set columns datatable
					columnsTable = [
						{
							dataField: 'data_penduduk.nama',
							text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
						},
						{
							dataField: 'master_produksi_galian.nama',
							text: 'Jenis Galian',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
						},
						{
							dataField: 'created_at',
							text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
						}
					];

					urlActive = "potensi-desa/pagination/galian?" 

					this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;

				case 'lembaga ekonomi':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_lembaga",
							title: "Jenis Lembaga Ekonomi",
							option: this.state.optionJenisLembagaEkonomi,
						},
					]

          // Set columns datatable
          columnsTable = [
            {
              dataField: 'data_penduduk.nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_lembaga.nama',
              text: 'Lembaga Ekonomi',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/lembaga-ekonomi-masyarakat?" 

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
				
				case 'agama':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_agama",
							title: "Jenis Agama",
							option: this.state.optionAgama,
						},
					]

          // Set columns datatable
          columnsTable = [
            {
              dataField: 'nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_agama.nama',
              text: 'Agama',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/data-masyarakat?" 

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;

				case 'pekerjaan':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_pekerjaan",
							title: "Jenis Pekerjaan",
							option: this.state.optionPekerjaan,
						},
					]

          // Set columns datatable
          columnsTable = [
            {
              dataField: 'nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_pekerjaan.nama',
              text: 'Pekerjaan',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/data-masyarakat?" 

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
				
				case 'usia':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "rentang_umur",
							title: "Rentang Usia",
							option: this.state.optionUsia,
						},
					]

          // Set columns datatable
          columnsTable = [
            {
              dataField: 'nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'tanggal_lahir',
              text: 'Usia',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (cell, obj) => {
								if (obj !== null) {
									const now = new Date()
									const tglLahir = new Date(obj.tanggal_lahir)
	
									let tgl1 = moment(now)
									let tgl2 = moment(tglLahir)
	
									const yearDiff = tgl1.diff(tgl2, 'years')
	
									return yearDiff
								} else {
									return '-'
								}
							}
            },
            {
              dataField: 'data_penduduk',
              text: 'Rentang Usia',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (cell, obj) => {
								if (obj !== null) {
									const now = new Date()
									const tglLahir = new Date(obj.tanggal_lahir)
	
									let tgl1 = moment(now)
									let tgl2 = moment(tglLahir)
	
									const yearDiff = tgl1.diff(tgl2, 'years')
	
									let rentangUsia = ''
	
									if (yearDiff > 0 && yearDiff <= 5) {
										rentangUsia = "Balita"
									} else if (yearDiff > 5 && yearDiff <= 11) {
										rentangUsia = "Kanak Kanak"
									} else if (yearDiff > 11 && yearDiff <= 16) {
										rentangUsia = "Remaja Awal"
									} else if (yearDiff > 16 && yearDiff <= 25) {
										rentangUsia = "Remaja Akhir"
									} else if (yearDiff > 25 && yearDiff <= 35) {
										rentangUsia = "Dewasa Awal"
									} else if (yearDiff > 35 && yearDiff <= 45) {
										rentangUsia = "Dewasa Akhir"
									} else if (yearDiff > 45 && yearDiff <= 55) {
										rentangUsia = "Lansia Awal"
									} else if (yearDiff > 55 && yearDiff <= 65) {
										rentangUsia = "Lansia Akhir"
									} else if (yearDiff > 65) {
										rentangUsia = "Manula"
									}
	
									return rentangUsia
								} else {
									return '-'
								}
							}
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								console.log('Data OBJ Tanggal')
								console.log(obj)
								let tgl = "";
								if(obj){
									let tgl_split = obj.split(" ");
									tgl = tgl_split[0]
								}
								return Tanggal(tgl)
							}
            }
          ];

					urlActive = "potensi-desa/pagination/data-masyarakat?" 

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
				
				case 'pendidikan terakhir':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_pendidikan_terakhir",
							title: "Pendidikan Terakhir",
							option: this.state.optionPendidikan,
						},
					]

          // Set columns datatable
          columnsTable = [
            {
              dataField: 'nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'data_pendidikan_terakhir.nama',
              text: 'Pendidikan Terakhir',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/data-masyarakat?" 

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;
				
				case 'status perkawinan':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "status_perkawinan",
							title: "Status Perkawinan",
							option: this.state.optionStatusPerkawinan,
						},
					]

          // Set columns datatable
          columnsTable = [
            {
              dataField: 'nama',
              text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'status_perkawinan',
              text: 'Status Perkawinan',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
            },
            {
              dataField: 'created_at',
              text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
            }
          ];

					urlActive = "potensi-desa/pagination/data-masyarakat?" 

          this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;

				case 'etnis/suku':
					filter = [
						{ 
							jenis: jenis_laporan,
							value: [null, null],
							label: '',
							type: "daterange",
							field: "rentang_tanggal",
							title: "Rentang Waktu",
						},
						{ 
							jenis: jenis_laporan,
							value: null,
							label: '',
							type: "select",
							field: "uuid_etnis",
							title: "Jenis Etnis/Suku",
							option: this.state.optionEtnis,
						},
					]

					// Set columns datatable
					columnsTable = [
						{
							dataField: 'data_penduduk.nama',
							text: 'Nama Penduduk',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
						},
						{
							dataField: 'data_etnis.nama',
							text: 'Etnis/Suku',
							sort: false,
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
						},
						{
							dataField: 'created_at',
							text: 'Diinputkan pada tanggal',
							headerAlign: "center",
							align: "center",
							headerStyle: { border: "1.5px solid black", backgroundColor: "lightskyblue" }, 
							style: { border: "1.5px solid black" }, 
							formatter: (obj) => {
								let tgl = obj.split(" ");
								return Tanggal(tgl[0])
							}
						}
					];

					this.setState({ 
						listFilter: filter, 
						columnsTable, 
						urlActive
					})
					break;

        default :
          this.setState({ listFilter: [], urlActive: '', extensionUrlActive: '' })
          break;
      }

			// Get data
			this.getDatatable(jenis_laporan, urlActive)

    }
  }

  getDatatable(jenis_laporan, url = null) {
		let newUrl = this.state.urlActive;
		
		if (url !== null) {
			newUrl = url;
		}

		// get data from api
    this.fetch({ page: null, where: [], tab: '1', url: newUrl });
	}
	
	changeLaporan = (e) => {
		this.setState({ jenisLaporan: e, dataLaporan: [], columnsTable: [] })
		
		if (e) {
			this.updateJenisLaporan(e.value)
			// this.getDatatable(e.value)
		} else {
			this.updateJenisLaporan()
		}
	}

	changeFilterOptions = (fieldChanged, fieldBy, fieldByValue, currentListFilter) => {
		// Jika options field yang diubah adalah uuid_komoditas (master komoditas)
		if (fieldChanged === "uuid_komoditas") {
			// Get list komoditas
			Get('master-komoditas/list?' + fieldBy + '=' + (fieldByValue ? fieldByValue : '' ), null, (data) => {
				let optionKomoditas = [];
	
				data.results.forEach((e) => {
					optionKomoditas.push({
						value: e.uuid,
						label: e.nama
					});
				});
	
				// this.setState({ optionKomoditas });

				currentListFilter.forEach((e, i) => {
					if (e.field === fieldChanged) {
						currentListFilter[i].value = null
						currentListFilter[i].label = ''
						currentListFilter[i].option = optionKomoditas
					}
				})

				this.setState({ listFilter: currentListFilter })

				// update datatable berdasarkan nilai filter value yang baru (null / tidak)
				this.updateDatatableByFilter(currentListFilter)
			});
		}
		// Jika fieldChanged adalah "nama_aset" (Nama Aset)
		else if (fieldChanged === "nama_aset") {
			Get('kategori-aset/list/nama-jenis?' + fieldBy + '=' + (fieldByValue ? fieldByValue : '' ), null, (data) => {
				let optionAset = [];
	
				data.results.forEach((e) => {
					optionAset.push({
						value: e.nama,
						label: e.nama
					});
				});

				currentListFilter.forEach((e, i) => {
					if (e.field === fieldChanged) {
						currentListFilter[i].value = null
						currentListFilter[i].label = ''
						currentListFilter[i].option = optionAset
					}
				})

				this.setState({ listFilter: currentListFilter })

				// update datatable berdasarkan nilai filter value yang baru (null / tidak)
				this.updateDatatableByFilter(currentListFilter)
			});
		}
		// Jika fieldChanged adalah "nama_aset_pilihan" (Data Aset Pilihan) 
		else if (fieldChanged === "nama_aset_pilihan") {
			Get('kategori-aset-pilihan/list/nama-jenis?' + fieldBy + '=' + (fieldByValue ? fieldByValue : '' ), null, (data) => {
				let optionAsetPilihan = [];
	
				data.results.forEach((e) => {
					optionAsetPilihan.push({
						value: e.nama,
						label: e.nama
					});
				});
	
				// this.setState({ optionAsetPilihan });

				currentListFilter.forEach((e, i) => {
					if (e.field === fieldChanged) {
						currentListFilter[i].value = null
						currentListFilter[i].label = ''
						currentListFilter[i].option = optionAsetPilihan
					}
				})

				this.setState({ listFilter: currentListFilter })

				// update datatable berdasarkan nilai filter value yang baru (null / tidak)
				this.updateDatatableByFilter(currentListFilter)
			});
		}
	}

	updateFilterValue = (filterValue, index, select) => {
		// Ambil semua data list filter
		let listFilter = [...this.state.listFilter]

		// Mengambil data object saat ini
		let currentFilterValue = filterValue
		currentFilterValue.value = select ? select.value : null
		currentFilterValue.label = select ? select.label : ''

		// Mengupdate data listFilter
		listFilter[index] = currentFilterValue

		// Cek jika select value merupakan depdrop
		if (typeof listFilter[index].change !== "undefined") {
			let listChangeableFields = listFilter[index].change.split('|')

			// Cek seluruh field yang berpengaruh dan harus di set ulang (update)
			listChangeableFields.forEach((field) => {
				// Set list option baru untuk filter (field)
				this.changeFilterOptions(field, listFilter[index].field, listFilter[index].value, listFilter);				
			})
		} 
		// Jika select value tidak berhubungan dengan filter lain
		else {
			// Langsung set filter berdasarkan value select yang dipilih (null / tidak)
			this.setState({ listFilter })
			// update datatable berdasarkan nilai filter value yang baru (null / tidak)
			this.updateDatatableByFilter(listFilter)
		}
		
	}

	updateDatatableByFilter = (filter) => {
		let extension = []
		let isUpdate = true
		
		filter.forEach((e, i) => {
			if (e.type === "select") {
				if (e.value) {
					extension.push(e.field + '=' + e.value)
				}
				isUpdate &= true
			}
			else if (e.type === "daterange") {
				// let val = ''
				
				if (e.value.length > 0) {
					let tanggal_mulai = dateFormat(new Date(), 'yyyy-mm-dd')
					let tanggal_selesai = dateFormat(new Date(), 'yyyy-mm-dd')
					
					if (e.value[0] !== null) {
						tanggal_mulai = dateFormat(e.value[0], 'yyyy-mm-dd')
						if (e.value[1] !== null) {
							tanggal_selesai = dateFormat(e.value[1], 'yyyy-mm-dd')
							extension.push(e.field + '=' + tanggal_mulai + " s/d " + tanggal_selesai)
							isUpdate &= true
						} else {
							isUpdate &= false
						}
					}
				} 
				// else {
				// 	extension.push(e.field + '=' + val)
				// }
			}
		})

		let extensionUrlActive = extension.join('&');
		let url = this.state.urlActive + extensionUrlActive

		if (isUpdate) {
			this.setState({ extensionUrlActive })
			// Update datatable
			this.getDatatable(this.state.jenisLaporan.value, url)
		}
	}

	render() {

		let filter = this.state.listFilter.map((e, index) => {                       
			// Jika filter bertipe select 
			if (e.type === "select") {
				return (
					<div className="row" style={{ marginBottom: "10px" }} key={index} >
						<div className="col-sm-9">
							<Select
								key={index}
								classNamePrefix="select"
								onChange={(val) => {
									this.updateFilterValue(e, index, val)
								}}
								value={e.value !== null ? e : null}
								name="role"
								options={e.option}
								placeholder={"Pilih " + e.title}
								isClearable
							/>
						</div>
					</div>
				)
			} 
			// Jika filter bertipe daterange
			else if (e.type === 'daterange') {
				return (
					<div className="row" style={{ marginBottom: "10px" }}>
						<div className="col-sm-4">
							<Datetime
								input={true}
								timeFormat={false}
								dateFormat="DD/MM/yyyy"
								closeOnSelect={true}
								initialValue={e.value[0]}
								value={e.value[0]}
								onChange={(val) => {
									// Ambil semua data list filter
									let listFilter = [...this.state.listFilter]
									let currentFilterValue = e

									if (typeof val._isValid !== 'undefined') {
										currentFilterValue.value[0] = new Date(val)
									} else {
										currentFilterValue.value[0] = null
									}
									
									listFilter[index] = currentFilterValue

									// Langsung set filter berdasarkan value select yang dipilih (null / tidak)
									this.setState({ listFilter })
									// update datatable berdasarkan nilai filter value yang baru (null / tidak)
									this.updateDatatableByFilter(listFilter)
								}}
								inputProps={{
									placeholder: 'Tanggal mulai'
								}}
							/>
						</div>
						<div className="col-sm-1" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<span style={{ margin: "10px" }}> s/d </span>
						</div>
						<div className="col-sm-4">
							<Datetime
								input={true}
								timeFormat={false}
								dateFormat="DD/MM/yyyy"
								closeOnSelect={true}
								initialValue={e.value[1]}
								value={e.value[1]}
								onChange={(val) => {
									// Ambil semua data list filter
									let listFilter = [...this.state.listFilter]
									let currentFilterValue = e
									
									if (typeof val._isValid !== 'undefined') {
										currentFilterValue.value[1] = new Date(val)
									} else {
										currentFilterValue.value[1] = null
									}

									listFilter[index] = currentFilterValue

									// Langsung set filter berdasarkan value select yang dipilih (null / tidak)
									this.setState({ listFilter })
									// update datatable berdasarkan nilai filter value yang baru (null / tidak)
									this.updateDatatableByFilter(listFilter)
								}}
								inputProps={{
									placeholder: 'Tanggal selesai'
								}}
							/>
						</div>
						<div className="col-sm-2 btn-mt1">
							<Button
								className="btn btn-danger btn-success btn-md"
								onClick={(val) => {
									// Ambil semua data list filter
									let listFilter = [...this.state.listFilter]
									let currentFilterValue = e

									currentFilterValue.value = [null, null]
									listFilter[index] = currentFilterValue
									
									this.setState({ listFilter: [] })

									// Langsung set filter berdasarkan value select yang dipilih (null / tidak)
									setTimeout(function () {
										this.setState({ listFilter })
									}.bind(this), 1)
									
									// update datatable berdasarkan nilai filter value yang baru (null / tidak)
									this.updateDatatableByFilter(listFilter)
								}}
							>
								Reset tanggal
							</Button>
						</div>
					</div>
						
				)
			}
		})

		return (
			<div>
				{/* <Breadcrumb title="Data Banner Desa" parent="Admin" /> */}
				{/* <SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					// onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert> */}

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header" style={{ padding: "15px" }}>
									<div className="row">
										<div className="col-sm-6">
											<h3 style={{ marginBottom: "0px" }}>Laporan Potensi Masyarakat</h3>
										</div>
									</div>
								</div>

								<div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
											{this.state.optionJenisSumberAir.length > 0
												&& this.state.optionStatusSumberAir.length > 0
												&& this.state.optionJenisKepemilikanRumah.length > 0
												&& this.state.optionJenisKepemilikanLahan.length > 0
												&& this.state.optionStatusKepemilikanLahan.length > 0
												&& this.state.optionJenisKomoditas.length > 0
												&& this.state.optionKomoditas.length > 0
												&& this.state.optionJenisAset.length > 0
												&& this.state.optionAset.length > 0
												&& this.state.optionAsetPilihan.length > 0
												&& this.state.optionJenisAsetPilihan.length > 0
												&& this.state.optionJenisGalian.length > 0
												&& this.state.optionAgama.length > 0
												&& this.state.optionPekerjaan.length > 0
												&& this.state.optionUsia.length > 0
												&& this.state.optionPendidikan.length > 0
												&& this.state.optionStatusPerkawinan.length > 0
												&& this.state.optionEtnis.length > 0
											? (
												<FormGroup className="row">
													<Label className="col-sm-2 col-form-label" htmlFor="inputEmail3">
														Jenis Laporan
													</Label>
													<Col sm="6">
														<div className="row">
															<div className="col-sm-9">
																<Select
																	classNamePrefix="select"
																	value={this.state.jenisLaporan}
																	name="jenis_laporan"
																	options={[
																		{value: "Sumber Air Minum", label: "Sumber Air Minum"},
																		{value: "Kepemilikan Rumah", label: "Kepemilikan Rumah"},
																		{value: "Kepemilikan Lahan", label: "Kepemilikan Lahan"},
																		{value: "Data Produksi", label: "Data Produksi"},
																		{value: "Data Aset", label: "Data Aset"},
																		{value: "Data Aset Lainnya", label: "Data Aset Lainnya"},
																		{value: "Produksi Galian", label: "Produksi Galian"},
																		{value: "Lembaga Ekonomi", label: "Lembaga Ekonomi"},
																		{value: "Agama", label: "Agama"},
																		{value: "Pekerjaan", label: "Pekerjaan"},
																		{value: "Usia", label: "Usia"},
																		{value: "Pendidikan Terakhir", label: "Pendidikan Terakhir"},
																		{value: "Status Perkawinan", label: "Status Perkawinan"},
																		{value: "Etnis/Suku", label: "Etnis/Suku"},
																	]}
																	onChange={(e) => this.changeLaporan(e)}
																	placeholder="Pilih Jenis Laporan"
																	isClearable
																	required
																/>
															</div>
														</div>
													</Col>
												</FormGroup>
											) : (
												<div align="center">
													<img
														src={require('../assets/images/loading-bos.gif')}
														style={{
															// width: '100%',
															borderRadius: '10px',
															width: '131px'
														}}
														alt="loading"
													/>
												</div>
											)}
											{this.state.listFilter.length === 0 ? '' : (
												<FormGroup className="row">
													<Label className="col-sm-2 col-form-label" htmlFor="inputEmail3">
														Filter
													</Label>
													<div className="col-sm-6" style={{ paddingTop: "5px" }}>
														{filter}
													</div>
												</FormGroup>
											)}
											{this.state.jenisLaporan ? (
												<FormGroup className="row">
													<div className="col-sm-2"></div>
													<div className="col-sm-6">
														<a
															href={API_URL + this.state.urlActive.replace("pagination", "cetak") + this.state.extensionUrlActive}
															className="btn btn-info btn-success btn-md"
															style={{ color: "white" }}
														>
															Cetak
														</a>
													</div>
												</FormGroup>
											) : (
												''
											)}
                    </div>
                  </div>
                  {/* Datatable */}
                  <div className="row">
                    <div className="col-sm-12">
                      {this.state.columnsTable.length > 0 ? (
                        <>
													{/* <br/> */}
													<h5 style={{ marginBottom: "10px" }}>Data {this.state.jenisLaporan.value}</h5>
													<div style={{ overflowX: "auto" }}>
														<BootstrapTable
															keyField="uuid"
															data={this.state.dataLaporan}
															columns={this.state.columnsTable}
															hover
															bordered
														/>
													</div>
                          <div className="pull-right text-white" style={{ marginTop: "10px" }}>
                            {this.state.awal}
                            {this.state.sebelum}
                            {this.state.hal.map((dt) => {
                              return dt;
                            })}
                            {this.state.setelah}
                            {this.state.akhir}
                          </div>
                        </>
                      ) : ''}
                    </div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			
				
			</div>
		);
	}
}

export default LaporanPotensi;
